import auth from '@/controllers/Auth';

import { Organization, FormattableUserFragment } from '@/generated/graphql';
import { gql } from '@apollo/client';

export const FORMATTABLE_USER_FRAGMENT = gql`
  fragment FormattableUser on User {
    firstName
    lastName
    email
    isSelfCare
    isActiveTeamMember
    jobRoles {
      organization {
        id
      }
      jobRole
    }
  }
`;

/**
 * Format a user's name for display.
 *
 * When possible, the user's first and last name will be used. If either is missing, the user's email
 * will be used. If the user is missing entirely, a fallback text will be used.
 *
 * For the special snowflake case of the system user, the text "System" will be returned.
 *
 * @param userActingOrganization The organization that the original actor was acting on behalf of when creating the resource
 *
 */
export function formatUserName(
  user: Maybe<FormattableUserFragment>,
  userActingOrganization?: PartialOrganization,
  fallbackText = 'Deleted User',
): string {
  const loggedInUserActingOrganizationId = auth.me('actingOrganization.id');

  const isSameOrg = userActingOrganization?.id === loggedInUserActingOrganizationId;

  if (!user) {
    return fallbackText;
  }

  if (user.email === 'api-service-account@feebris.com') {
    return 'Feebris System';
  }

  /**
   * If an acting organization is provided, we will attempt to find the job role for the user in that organization
   * otherwise we will use the job role for the current acting organization of the logged in user
   */
  const jobRole = user.jobRoles.find(
    (jobRole) =>
      jobRole.organization.id === (userActingOrganization?.id ?? loggedInUserActingOrganizationId),
  )?.jobRole;

  // Users from different organisations than our own should not have their names displayed, instead
  // we will render something like "user of Example Hospital Trust". Except for self-care users,
  // because they really just "the patient", we should display their name to anyone in any org.
  if (!user.isSelfCare) {
    if (userActingOrganization && userActingOrganization.id != loggedInUserActingOrganizationId) {
      // TODO: FEP-3258, this would be an ideal place to use the org's short name
      return `User from ${userActingOrganization.name}${jobRole ? ` (${jobRole})` : ''}`;
    }
  }

  const userNameParts =
    user?.firstName && user?.lastName ? [user.firstName, user.lastName] : [user.email];

  if (jobRole) {
    userNameParts.push(`(${jobRole})`);
  }

  // Show deactivated when the user is no longer in the org, but has not been hard deleted
  if (isSameOrg && user.isActiveTeamMember === false) {
    userNameParts.push('[deactivated]');
  }

  return userNameParts.join(' ');
}

export type PartialOrganization = Maybe<Pick<Organization, 'id' | 'name'>>;
