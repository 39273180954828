import React, { useCallback, useMemo } from 'react';
import { scaleLinear, scaleTime } from '@visx/scale';
import { useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { bisector } from '@visx/vendor/d3-array';
import makeStyles from '@mui/styles/makeStyles';
import { differenceInSeconds, format, addMilliseconds, differenceInMilliseconds } from 'date-fns';
import { PatientEcgTraceFragment } from '@/generated/graphql';
import { EcgTraceTooltip } from './EcgTraceTooltip';
import { EcgChart } from './EcgChart';

interface EcgTraceProps {
  startTime: Date;
  data: PatientEcgTraceFragment[];
  xRange: number; // milliseconds of ECG data
  yRange: number; // mV range of ECG data
  xTickResolution: number; // tick resolution in ms
}

export interface EcgDataPoint {
  x: Date;
  y: number;
}

const ECG_SAMPLING_FREQ = 128; // number of ECG values per second
const GRID_RATIO = 5; // ratio from major to minor grid lines
const PIXELS_PER_X_TICK = 50;

export const EcgTrace = ({ startTime, data, xRange, yRange, xTickResolution }: EcgTraceProps) => {
  const classes = useStyles();

  const xNumberOfTicks = useMemo(
    () => Math.ceil(xRange / xTickResolution),
    [xRange, xTickResolution],
  );

  const graphSize = useMemo(
    () => ({ height: 500, width: xNumberOfTicks * PIXELS_PER_X_TICK }),
    [xNumberOfTicks],
  );

  const margin = useMemo(() => ({ top: 30, right: 30, bottom: 90, left: 50 }), []);

  /*
   * ECG data stores 128 samples per second from the initial recording time.
   * This function spreads the data by extrapolation within each second and
   * trims data points that are outside the current time window.
   */
  const mapEcgDataToSegments = useCallback((): EcgDataPoint[][] => {
    if (data.length === 0) {
      return [];
    }

    let prevRecordTime = new Date(data[0].recordTime);

    const segments: EcgDataPoint[][] = [];
    let segment: EcgDataPoint[] = [];

    // Group samples in the same segment if they are not further than a second away
    data.forEach(({ recordTime, vitals: { ecg, magnification } }) => {
      if (differenceInSeconds(new Date(recordTime), prevRecordTime) > 1) {
        segments.push(segment);
        segment = [];
      }

      ecg.forEach((d, index) => {
        segment.push({
          x: addMilliseconds(new Date(recordTime), index * (1000 / ECG_SAMPLING_FREQ)),
          y: d / magnification,
        });
      });

      prevRecordTime = new Date(recordTime);
    });

    // If there is a single segment, ensure that it is added to the array
    if (!segments.length) {
      segments.push(segment);
    }

    // Filter out extrapolated data points that are outside the specified time window
    const trimmedSegments = segments.map((segment) =>
      segment.filter((d) => d.x >= startTime && d.x <= addMilliseconds(startTime, xRange)),
    );

    // Fixed initial extrapolated point to match exactly the beginning of the chart
    // (it will be typically deviated by a few milliseconds after extrapolation)
    if (
      differenceInMilliseconds(trimmedSegments[0][0].x, startTime) <
      xTickResolution / GRID_RATIO
    ) {
      trimmedSegments[0][0].x = startTime;
    }

    return trimmedSegments;
  }, [data, startTime, xRange, xTickResolution]);

  // Map ECG data to chart segments
  const segments = useMemo(() => mapEcgDataToSegments(), [mapEcgDataToSegments]);
  const flattenedSegments = useMemo(() => segments.flat(), [segments]);

  // Define axis ranges
  const xAxis = useMemo(
    () => ({ min: startTime, max: addMilliseconds(startTime, xRange) }),
    [startTime, xRange],
  );
  const yAxis = useMemo(() => ({ min: -yRange, max: yRange }), [yRange]);

  // Define axis scales
  const xScale = useMemo(
    () =>
      scaleTime({
        domain: [xAxis.min, xAxis.max],
        range: [margin.left, graphSize.width - margin.right],
      }),
    [xAxis, graphSize, margin],
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [yAxis.max, yAxis.min],
        range: [margin.top, graphSize.height - margin.bottom],
      }),
    [yAxis, graphSize, margin],
  );

  const {
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    showTooltip,
    hideTooltip,
  } = useTooltip({
    tooltipOpen: false,
    tooltipData: '',
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  const handlePointerMove = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      const coords = localPoint(event) || { x: 0, y: 0 };
      const x = xScale.invert(coords.x);

      // Find closest point in ECG data
      const index = bisector((d: { x: Date }) => d.x).left(flattenedSegments, x, 0);

      const { x: closestX, y: closestY } =
        index === flattenedSegments.length
          ? flattenedSegments[index - 1]
          : flattenedSegments[index];

      // If there is no data or the point is out of range, hide the tooltip
      if (
        Math.abs(closestY) > yRange ||
        Math.abs(differenceInMilliseconds(closestX, x)) > xTickResolution / GRID_RATIO
      ) {
        hideTooltip();
        return;
      }

      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: yScale(closestY),
        tooltipData: `${format(closestX, 'HH:mm:ss.S')} , ${closestY.toFixed(2)}mV`,
      });
    },
    [showTooltip, xScale, yScale, flattenedSegments],
  );

  return (
    <div
      className={classes.mainContainer}
      onPointerMove={(event) => (data.length ? handlePointerMove(event) : undefined)}
      onBlur={hideTooltip}
      onMouseOut={hideTooltip}>
      <EcgChart
        yRange={yRange}
        xTickResolution={xTickResolution}
        graphSize={graphSize}
        margin={margin}
        gridRatio={GRID_RATIO}
        xAxis={xAxis}
        yAxis={yAxis}
        xScale={xScale}
        yScale={yScale}
        segments={segments}
      />
      <EcgTraceTooltip
        tooltipOpen={tooltipOpen}
        tooltipLeft={tooltipLeft}
        tooltipTop={tooltipTop}
        tooltipData={tooltipData}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  mainContainer: {
    position: 'relative',
    display: 'flex',
    flex: 1,
  },
}));
