'use strict';

/**
 * Given thresholds and measurements will return a NEWS2 score breakdown complete with totalScore
 * and riskLevel
 *
 * Usage:
 *
 *   ```
 *   const scores = NEWSCalculator(
 *     DEFAULT_NEWS_THRESHOLDS,
 *     {
 *       respiratoryRate: 15,
 *       spO2: 98,
 *       bloodPressure: 140,
 *       pulse: 65,
 *       temperature: 36.8,
 *       consciousness: 'A',
 *     }
 *   );
 *
 *   // scores will now be:
 *   // {
 *   //   RRScore: 0,
 *   //   SpO2Score: 0,
 *   //   BPScore: 0,
 *   //   HRScore: 0,
 *   //   tempScore: 0,
 *   //   consciousnessScore: 0,
 *   //   riskLevel: 1,
 *   //   totalScore: 0
 *   // }
 *   ```
 */
function NEWSCalculator(thresholds, measurements, modifiers) {
  const scores = {
    RRScore: scoreByBands(
      thresholds.respiratoryRate,
      measurements.respiratoryRate,
      'respiratoryRate',
    ),
    SpO2Score: scoreByBands(thresholds.spO2, measurements.spO2, 'spO2'),
    BPScore: scoreByBands(thresholds.bloodPressure, measurements.bloodPressure, 'bloodPressure'),
    HRScore: scoreByBands(thresholds.pulse, measurements.pulse, 'pulse'),
    tempScore: scoreByBands(thresholds.temperature, measurements.temperature, 'temperature'),
    consciousnessScore: computeConsciousnessNEWS(measurements.consciousness),
    onOxygenScore: computeOnOxygenScore(measurements, modifiers?.onOxygen),
  };

  const { riskLevel, totalScore } = computeRiskLevelAndTotalScore(Object.values(scores));

  scores.riskLevel = riskLevel;
  scores.totalScore = totalScore;
  return !Object.values(scores).every((v) => v === null) ? scores : null;
}

function scoreByBands(bands, value, measurementName) {
  if (value === null || value === undefined) {
    return null;
  }

  for (const band of bands) {
    const inLow = band.low ? value >= band.low : true;
    const inHigh = band.high ? value <= band.high : true;

    if (inLow && inHigh) {
      return band.score;
    }
  }

  throw Error(
    `Unable to score ${measurementName} value ${value} as it does not fit within bands ${bands}`,
  );
}

function computeConsciousnessNEWS(value) {
  // TODO: Perhaps use a constant here, this is duplicated in the Checkup.consciousness ENUM
  switch (value) {
    case 'C': // confusion
    case 'V': // voice
    case 'P': // pain
    case 'U': // unresponsive
      return 3;
    case 'A': // alert
      return 0;
  }

  return null;
}

function computeOnOxygenScore(measurements, value) {
  // We only calculate the on oxygen score if we have at least one other measurement
  if (
    Object.values(measurements).every((m) => m === null || m === undefined) ||
    value === null ||
    value === undefined
  ) {
    return null;
  }

  return value ? 2 : 0;
}

function computeRiskLevelAndTotalScore(scores) {
  const validScores = scores.filter((score) => score !== null);
  const totalScore = validScores.length > 0 ? validScores.reduce((a, b) => a + b, 0) : null;

  let riskLevel;
  if (totalScore === null) {
    riskLevel = null;
  } else if (totalScore <= 0) {
    riskLevel = 1;
  } else if (totalScore >= 1 && totalScore <= 4) {
    riskLevel = scores.includes(3) ? 3 : 2;
  } else if (totalScore >= 5 && totalScore <= 6) {
    riskLevel = 4;
  } else if (totalScore >= 7) {
    riskLevel = 5;
  }

  return { riskLevel, totalScore };
}

/**
 * DO NOT CHANGE, these are values reflecting the NHS NEWS2 guide
 */
const DEFAULT_NEWS_THRESHOLDS = {
  respiratoryRate: [
    { low: 25, score: 3 }, // "high" is unbounded
    { high: 25, low: 21, score: 2 },
    { high: 21, low: 12, score: 0 },
    { high: 12, low: 9, score: 1 },
    { high: 9, score: 3 }, // "low" is unbounded
  ],
  spO2: [
    { low: 96, score: 0 }, // "high" is unbounded
    { high: 96, low: 94, score: 1 },
    { high: 94, low: 92, score: 2 },
    { high: 92, score: 3 }, // "low" is unbounded
  ],
  bloodPressure: [
    { low: 220, score: 3 }, // "high" is unbounded
    { high: 220, low: 111, score: 0 },
    { high: 111, low: 101, score: 1 },
    { high: 101, low: 91, score: 2 },
    { high: 91, score: 3 }, // "low" is unbounded
  ],
  pulse: [
    { low: 131, score: 3 }, // "high" is unbounded
    { high: 131, low: 111, score: 2 },
    { high: 111, low: 91, score: 1 },
    { high: 91, low: 51, score: 0 },
    { high: 51, low: 41, score: 1 },
    { high: 41, score: 3 }, // "low" is unbounded
  ],
  temperature: [
    { low: 39.1, score: 2 }, // "high" is unbounded
    { high: 39.1, low: 38.1, score: 1 },
    { high: 38.1, low: 36.1, score: 0 },
    { high: 36.1, low: 35.1, score: 1 },
    { high: 35.1, score: 3 }, // "low" is unbounded
  ],
};

module.exports = {
  NEWSCalculator,
  computeRiskLevelAndTotalScore,
  DEFAULT_NEWS_THRESHOLDS,
};
