import React, { useCallback, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';
import { useFormik } from 'formik';
import _ from 'lodash';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ApolloError, gql } from '@apollo/client';
import {
  InputAddress,
  NationalIdentifierType,
  PatientDetailsFragment,
  UpdatePatientInput,
  UpdatePatientViewFragment,
  useNhsNumberEditableQuery,
  useUpdatePatientMutation,
} from '@/generated/graphql';
import { getMutationErrors } from '@/AuthorizedApolloClientProvider';
import { useModal } from 'mui-modal-provider';
import { toast } from 'sonner';
import { isDefined } from '@/helpers/isDefined';
import { useMeActingOrganizationFeature } from '@/hooks/useAuth';
import {
  formatNationalIdentifier,
  nationalIdentifierTypeToLabel,
} from '@/components/NationalIdentifierDisplay';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  formError: {
    color: theme.palette.error.main,
  },
  editPatientButton: {
    marginLeft: theme.spacing(26),
  },
  cancelPatientButton: {
    marginLeft: theme.spacing(4),
  },
  textField: {
    display: 'block',
    marginBottom: theme.spacing(0.5),
  },
  actions: {
    marginBottom: theme.spacing(2),
  },
}));

interface PatientFormModalProps extends PatientFormProps {
  open: boolean;
  onClose: () => void;
}

export default function PatientFormModal({ open, onClose, ...rest }: PatientFormModalProps) {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      {/* 
      just in case the patient is null 
      (because the modal mounted without the ui selecting the patient) 
      */}
      {rest.patient ? <PatientForm onClose={onClose} {...rest} /> : null}
    </Dialog>
  );
}

export const GET_NHS_NUMBER_EDITABLE = gql`
  query NhsNumberEditable($patientId: ID!) {
    patient(id: $patientId) {
      nhsNumberResponseDetails {
        editable
      }
    }
  }
`;

interface PatientFormProps {
  patient: UpdatePatientViewFragment;
  onComplete: () => void;
  onClose: () => void;
  setFlashMessage: (message: string) => void;
}

export const UPDATE_PATIENT_FRAGMENT = gql`
  fragment UpdatePatientView on Patient {
    id
    firstName
    lastName
    birthDate
    nationalIdentifier {
      type
      value
    }
    telephone
    gender
    address {
      address
      postcode
    }
    preExistingConditions
    selfCare {
      email
    }
  }
`;

export const UPDATE_PATIENT = gql`
  mutation UpdatePatient($patient: UpdatePatientInput!) {
    patient: updatePatient(patient: $patient) {
      ...PatientDetails
    }
  }
`;

function PatientForm({ patient, onComplete, onClose, setFlashMessage }: PatientFormProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [updatePatientMutation] = useUpdatePatientMutation();

  const { data: nhsNumberEditableResponse, loading: loadingIsEditable } = useNhsNumberEditableQuery(
    {
      variables: { patientId: patient.id },
    },
  );

  const nationalIdentifierScheme = useMeActingOrganizationFeature('nationalIdentifierScheme', null);
  const hasNationalIdentifierScheme = nationalIdentifierScheme !== null;

  const nhsNumberEditable =
    nationalIdentifierScheme === NationalIdentifierType.NhsNumber &&
    nhsNumberEditableResponse?.patient?.nhsNumberResponseDetails?.editable
      ? true
      : false;

  // Default to disabled if the organization uses NHS numbers, this is because we encourage the use of the PDS
  const [nhsNumberDisabled, setNhsNumberDisabled] = useState(
    nationalIdentifierScheme === NationalIdentifierType.NhsNumber,
  );
  const isSelfCarePatient = isDefined(patient?.selfCare?.email);

  const { values, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit } = useFormik(
    {
      initialValues: {
        patient: toUpdatePatientInput(patient),
      },
      onSubmit: async (data, { setSubmitting, setErrors }) => {
        try {
          setSubmitting(true);

          await updatePatientMutation({
            variables: {
              patient: {
                ...data.patient,
                nationalIdentifierValue: stripNationalIdentifierSpaces(
                  data.patient.nationalIdentifierValue,
                ),
              },
            },
          });

          onComplete();
          onClose();
          setFlashMessage('Patient Updated');
        } catch (error) {
          const invalidArgsError =
            error instanceof ApolloError && _.get(getMutationErrors(error), 'argErrors');
          if (invalidArgsError) {
            setErrors(invalidArgsError);
          } else {
            toast.error("An error occurred when saving the patient's details");
          }
        } finally {
          setSubmitting(false);
        }
      },
    },
  );

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle id="form-dialog-title">Edit Patient</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} container className={classes.container} spacing={1.5}>
            {hasNationalIdentifierScheme && (
              <Grid item xs={12} marginTop={2}>
                <TextField
                  fullWidth
                  name="patient.nationalIdentifierValue"
                  variant="outlined"
                  value={values.patient.nationalIdentifierValue ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={nationalIdentifierTypeToLabel(nationalIdentifierScheme)}
                  className={classes.textField}
                  error={_.has(errors, 'patient.nationalIdentifierValue')}
                  helperText={_.get(
                    errors,
                    'patient.nationalIdentifierValue',
                    loadingIsEditable ||
                      nationalIdentifierScheme !== NationalIdentifierType.NhsNumber
                      ? ''
                      : nhsNumberEditable
                      ? 'Optional, manually set the NHS number. If left blank the NHS number will be automatically fetched from the NHS Personal Demographics Service.'
                      : 'This NHS number was set by the Personal Demographics Service (PDS). If the NHS number is incorrect please contact Feebris support.',
                  )}
                  disabled={nhsNumberDisabled}
                  InputProps={{
                    endAdornment: loadingIsEditable ? (
                      <CircularProgress size={16} />
                    ) : nhsNumberDisabled && nhsNumberEditable ? (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setNhsNumberDisabled(false)} size="large">
                          <EditIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                name="patient.firstName"
                value={values.patient.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                label="First Name"
                className={classes.textField}
                error={_.has(errors, 'patient.firstName')}
                helperText={_.get(errors, 'patient.firstName')}
                inputProps={{ tabIndex: 1 }}
                autoFocus
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Last Name"
                name="patient.lastName"
                value={values.patient.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                className={classes.textField}
                error={_.has(errors, 'patient.lastName')}
                helperText={_.get(errors, 'patient.lastName')}
                inputProps={{ tabIndex: 2 }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                name="patient.birthDate"
                label="Date of Birth"
                type="date"
                className={classes.textField}
                value={values.patient.birthDate}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                error={_.has(errors, 'patient.birthDate')}
                helperText={_.get(errors, 'patient.birthDate')}
                inputProps={{ tabIndex: 3, format: 'dd/mm/yyyy' }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                variant="outlined"
                label="Sex"
                name="patient.gender"
                value={values.patient.gender || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                className={classes.textField}
                error={_.has(errors, 'patient.gender')}
                helperText={_.get(errors, 'patient.gender')}>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Telephone"
                name="patient.telephone"
                onChange={handleChange}
                onBlur={handleBlur}
                className={classes.textField}
                value={_.get(values, 'patient.telephone')}
                error={_.has(errors, 'patient.telephone')}
                helperText={_.get(errors, 'patient.telephone', t('Phone Number Hint'))}
                inputProps={{ tabIndex: 5 }}
                required={isSelfCarePatient}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                variant="outlined"
                label="Address"
                name="patient.address.address"
                onChange={handleChange}
                onBlur={handleBlur}
                className={classes.textField}
                value={_.get(values, 'patient.address.address')}
                error={_.has(errors, 'patient.address.address')}
                helperText={_.get(errors, 'patient.address.address')}
                inputProps={{ tabIndex: 6 }}
                required={isSelfCarePatient}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="Postcode"
                name="patient.address.postcode"
                onChange={handleChange}
                onBlur={handleBlur}
                className={classes.textField}
                value={_.get(values, 'patient.address.postcode')}
                error={_.has(errors, 'patient.address.postcode')}
                helperText={_.get(errors, 'patient.address.postcode')}
                inputProps={{ tabIndex: 7 }}
                required={isSelfCarePatient}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Pre-Existing Conditions"
                name="patient.preExistingConditions"
                onChange={handleChange}
                onBlur={handleBlur}
                multiline
                rows={4}
                className={classes.textField}
                value={_.get(values, 'patient.preExistingConditions')}
                error={_.has(errors, 'patient.preExistingConditions')}
                helperText={_.get(errors, 'patient.preExistingConditions')}
                inputProps={{ tabIndex: 8 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className={classes.cancelPatientButton} onClick={onClose} tabIndex={9}>
          Cancel
        </Button>
        <Button
          className={clsx(classes.editPatientButton, 'e2e__patientsubmit')}
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSubmitting || !dirty}
          tabIndex={10}>
          Update Patient
        </Button>
      </DialogActions>
    </form>
  );
}

function toUpdatePatientInput(patient: UpdatePatientViewFragment) {
  return {
    ..._.pick(patient, [
      'id',
      'firstName',
      'lastName',
      'birthDate',
      'gender',
      'preExistingConditions',
      'telephone',
    ]),
    nationalIdentifierValue: formatNationalIdentifier(patient.nationalIdentifier),
    address: toInputAddress(patient?.address as InputAddress | null),
  } satisfies UpdatePatientInput;
}

/**
 * This is necessary to ensure the patient address is either a complete object, of two strings, or
 * null.
 *
 * Formik has a habit of collapsing empty form fields into `undefined` which causes them to
 * be removed by JSON serialisation when the graphql query is submitted.
 */
function toInputAddress(address: InputAddress | null) {
  return address?.address || address?.postcode
    ? {
        address: address?.address ?? '',
        postcode: address?.postcode ?? '',
      }
    : undefined;
}

function stripNationalIdentifierSpaces(nationalIdentifierValue: Maybe<string>) {
  if (!nationalIdentifierValue) return null;
  return nationalIdentifierValue.replace(/\s/g, '');
}

export const useEditPatientModal = ({
  setFlashMessage,
  onComplete,
}: {
  setFlashMessage?: (message: string) => void;
  onComplete?: () => void;
} = {}) => {
  const { showModal } = useModal();

  const showEditPatientModal = useCallback(
    (patient: PatientDetailsFragment) => {
      const modal = showModal(
        PatientFormModal,
        {
          patient,
          onClose: () => {
            modal.hide();
          },
          onComplete: () => {
            modal.hide();
            onComplete?.();
          },
          setFlashMessage: (message: string) => {
            setFlashMessage?.(message);
          },
        },
        {
          destroyOnClose: true,
        },
      );

      return modal;
    },
    [onComplete, setFlashMessage, showModal],
  );

  return { showEditPatientModal };
};
