import React from 'react';

import {
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { Link } from 'react-router-dom';
import { gql } from '@apollo/client';

import {
  Patient,
  VirtualWardPatientItemFragment,
  VirtualWardPatientCheckupFragment,
  VirtualWardPatientContinuousMonitoringWithSessionFragment,
} from '@/generated/graphql';
import { VitalsValues } from './VitalsValues';
import { LatestCheckupInfo } from './LatestCheckupInfo';
import { LatestCheckupValues } from './LatestCheckupValues';
import { PatientStatusIndicator } from './StatusIndicator';
import { AlertChip } from '@/pages/VirtualWard/components/AlertChip';
import { useVirtualWardContext } from '../VirtualWardContext';
import clsx from 'clsx';
import { isDefined } from '@/helpers/isDefined';
import { SoftSignsChips } from '@/components/SoftSignsChips';
import { SimilarNamesWarning } from '@/components/SimilarNamesWarning';
import { ActivityMonitoringSessionStatus } from './ActivityMonitoringSessionStatus';
import { PatientWatchListButton } from '@/components/PatientWatchListButton';
import NationalIdentifierDisplay from '@/components/NationalIdentifierDisplay';
import { useMeActingOrganizationFeature } from '@/hooks/useAuth';
import { PatientAcuityDisplay } from '@/components/PatientAcuity';

export const CHECKUP_VALUES_FRAGMENT = gql`
  fragment VirtualWardPatientCheckup on Checkup {
    id
    type
    checkupType {
      name
    }
    endedAt
    notes {
      text
    }
    questionnaire
    softSigns {
      key
      isEmergency
    }
    ewsScores {
      BPScore
      consciousnessScore
      HRScore
      RRScore
      SpO2Score
      tempScore
      onOxygenScore
      totalScore
      riskLevel
    }
    bloodPressureData
    pulseRate {
      value
      source
      isManual
    }
    pulseOxiData
    respiratoryRate {
      value
      source
    }
    temperature
    consciousness
    selectedAction
    glucose
    weight
  }
`;

export const CONTINUOUS_MONITORING_VALUES_FRAGMENT = gql`
  fragment VirtualWardPatientContinuousMonitoring on ContinuousMonitoring {
    bucketEndAt
    spo2 {
      min
      max
      median
      value
    }
    respiratoryRate {
      min
      max
      median
      value
    }
    heartRate {
      min
      max
      median
      value
    }
    pulseRate {
      min
      max
      median
      value
    }
    temperature {
      min
      max
      median
      value
    }
    battery {
      ecg
      spo2
      temperature
    }
    thresholdScores {
      BPScore
      consciousnessScore
      HRScore
      RRScore
      SpO2Score
      tempScore
      onOxygenScore
      totalScore
      riskLevel
    }
  }
`;

export const ACTIVITY_MONITORING_SESSION_FRAGMENT = gql`
  fragment VirtualWardPatientActivityMonitoringSession on PacsanaSession {
    id
    createdAt
    endedAt
    pacsanaUserName
    status {
      zeroesAcrossMetricsForPreviousDay
      noMetricsForPreviousDay
      batteryStatus
      latestMetricsDate
      latestEventDate
    }
  }
`;

export const CONTINUOUS_MONITORING_WITH_SESSION_FRAGMENT = gql`
  fragment VirtualWardPatientContinuousMonitoringWithSession on ContinuousMonitoringWithSession {
    continuousMonitoring {
      ...VirtualWardPatientContinuousMonitoring
    }
    continuousMonitoringSession {
      vivalinkSubjectId
      createdAt
      endedAt
    }
  }
`;

interface PatientListItemProps {
  item: VirtualWardPatientItemFragment;
  isExpanded: boolean;
  onExpandToggle: (patientId: string) => void;
}

export function PatientListItem({ item, isExpanded, onExpandToggle }: PatientListItemProps) {
  const { refresh } = useVirtualWardContext();
  const hasPatientAcuityScoreFeature = useMeActingOrganizationFeature('patientAcuityScore', false);

  const classes = useStyles();

  const hasAlerts = item.alerts.length > 0;
  const hasData = item.latestCheckup || item.latestContinuousMonitoring;

  const isContinuousMonitoring = chooseContinuous(
    item.latestCheckup,
    item.latestContinuousMonitoring,
  );

  return (
    <Stack flex={1} role="listitem" aria-label={`${item.firstName} ${item.lastName}`}>
      <div className={classes.alertsContainer}>
        {item.alerts.map((alert) => (
          <AlertChip key={alert.id} alert={alert} patient={item as Patient} refresh={refresh} />
        ))}
      </div>
      <Paper elevation={2} className={clsx(classes.root, hasAlerts && classes.rootWithAlerts)}>
        <Box
          minHeight="80px"
          flexGrow={1}
          display="flex"
          alignItems="center"
          className={classes.headline}
          onClick={() => {
            // Prevent expanding the item when selecting text
            const selection = window.getSelection();
            if (!selection || selection.toString().length === 0) {
              onExpandToggle(item.id);
            }
          }}>
          <PatientStatusIndicator {...item} />
          <Stack alignItems="center" direction="row" flexWrap={{ xs: 'wrap', md: 'nowrap' }}>
            <Stack
              justifyContent="center"
              marginTop={2}
              marginBottom={{ xs: 1, sm: 2 }}
              paddingLeft={0.5}
              width={185}
              flexGrow={0}
              flexShrink={0}>
              <div className={classes.name}>
                <SimilarNamesWarning patient={item}>
                  {item.firstName} {item.lastName}
                </SimilarNamesWarning>
              </div>
              <NationalIdentifierDisplay
                patientId={item.id}
                nationalIdentifier={item.nationalIdentifier}
                showDetailsIcon="hover"
              />
            </Stack>
            {hasPatientAcuityScoreFeature && (
              <PatientAcuityDisplay
                acuityScore={item.latestAcuityScore}
                variant="small"
                sx={{
                  marginY: 1.5,
                  marginRight: 1,
                }}
              />
            )}
            {hasData && (
              <LatestCheckupInfo
                checkup={item.latestCheckup}
                continuousMonitoringWithSession={item.latestContinuousMonitoring}
                isContinuousMonitoring={isContinuousMonitoring}
                activityMonitoringSession={item.activityMonitoringSession}
              />
            )}
            {hasData && (
              <VitalsValues
                checkup={item.latestCheckup}
                continuousMonitoringWithSession={item.latestContinuousMonitoring}
                isContinuousMonitoring={isContinuousMonitoring}
              />
            )}
            {!hasData && (
              <Box display="flex" marginY={2}>
                <AssignmentLateIcon color="inherit" className={classes.noCheckupsIcon} />
                <Box display="flex" alignItems="center" gap={6}>
                  <Typography className={classes.noCheckupsMessage}>No visible checkups</Typography>
                  {isDefined(item.activityMonitoringSession) &&
                    !isDefined(item.activityMonitoringSession.endedAt) && (
                      <ActivityMonitoringSessionStatus
                        activityMonitoringSession={item.activityMonitoringSession}
                      />
                    )}
                </Box>
              </Box>
            )}
          </Stack>
          <Box marginRight={1} display="flex" flexGrow={1} justifyContent="flex-end">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onExpandToggle(item.id);
              }}
              aria-label={isExpanded ? 'Collapse patient detail' : 'Expand patient detail'}
              size="large">
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        </Box>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <Box padding={2} paddingTop={1} onClick={(e) => e.stopPropagation()}>
            <Box>
              <Box marginTop={1}>
                {isContinuousMonitoring && item.latestCheckup ? (
                  <LatestCheckupValues checkup={item.latestCheckup} />
                ) : (
                  <SoftSignsChips softSigns={item.latestCheckup?.softSigns ?? []} />
                )}
              </Box>
              {item.latestCheckup?.notes?.length ? (
                <Box marginTop={2}>
                  <Typography className={classes.label}>Last check-up notes</Typography>
                  <Typography className={classes.lastCheckupNotes}>
                    {item.latestCheckup.notes.length > 1 ? (
                      <ul>
                        {item.latestCheckup.notes.map((note) => (
                          <li key={note.text}>{note.text}</li>
                        ))}
                      </ul>
                    ) : (
                      item.latestCheckup.notes[0].text
                    )}
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <Stack
              marginTop={2}
              direction="row"
              gap={2}
              justifyContent="space-between"
              flexWrap={{ xs: 'wrap', sm: 'nowrap' }}>
              <Stack direction="row" gap={1} flexWrap="wrap">
                {item.wardAdmission ? (
                  <Chip
                    component={Link}
                    to={`wards/${item.wardAdmission.ward.id}`}
                    target="_blank"
                    className={classes.chip}
                    variant="outlined"
                    key={item.wardAdmission.ward.id}
                    label={
                      <>
                        <span className={classes.chipLabel}>Ward</span>{' '}
                        {item.wardAdmission.ward.name}
                      </>
                    }
                  />
                ) : (
                  <Typography className={classes.value}>No current admission</Typography>
                )}
                {item.wardAdmission?.carePathway && (
                  <Chip
                    className={classes.chip}
                    variant="outlined"
                    key={item.wardAdmission.carePathway.id}
                    label={
                      <>
                        <span className={classes.chipLabel}>Care Pathway</span>{' '}
                        {item.wardAdmission.carePathway.name}
                      </>
                    }
                  />
                )}
                {item.wardAdmission &&
                  item.wardAdmission?.patientFlags
                    .filter((flag) => flag.active)
                    .map((flag) => (
                      <Tooltip key={flag.description} title={flag.description}>
                        <Chip
                          key={flag.type}
                          label={
                            <>
                              <span className={classes.chipLabel}>{flag.title}</span>
                            </>
                          }
                        />
                      </Tooltip>
                    ))}
              </Stack>
              <Stack gap={1} direction="row" alignItems="flex-end" justifyContent="flex-end">
                <PatientWatchListButton patient={item} refresh={refresh} />
                {item.latestCheckup && (
                  <Button
                    component={Link}
                    role="link"
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                      e.stopPropagation()
                    }
                    to={`patient/${item.id}/checkup/${item.latestCheckup.id}`}>
                    View Latest Checkup
                  </Button>
                )}
                <Button
                  component={Link}
                  role="link"
                  size="small"
                  color="primary"
                  variant="outlined"
                  to={`/patient/${item.id}`}
                  data-intercom-target="view-patient-record-button">
                  View Patient Record
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Collapse>
      </Paper>
    </Stack>
  );
}

// This function needs to be aligned with the virtualWardPatients query in the backend,
// otherwise the sorting/filtering will be misaligned with the front-end
function chooseContinuous(
  checkup: VirtualWardPatientCheckupFragment | null | undefined,
  continuousMonitoringWithSession:
    | VirtualWardPatientContinuousMonitoringWithSessionFragment
    | null
    | undefined,
): boolean {
  const isActiveMonitoringSession =
    isDefined(continuousMonitoringWithSession) &&
    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    !continuousMonitoringWithSession!.continuousMonitoringSession.endedAt;

  // If the session has no aggregates, use the start of the session as reference
  const continuousAt = parseTimestamp(
    continuousMonitoringWithSession?.continuousMonitoring?.bucketEndAt ||
      continuousMonitoringWithSession?.continuousMonitoringSession.createdAt ||
      null,
  );

  const checkupAt = parseTimestamp(checkup?.endedAt || null);

  // If the session is active, choose continuous. If it is closed,
  // compare the latest aggregate/session and latest check-up timestamps
  return isActiveMonitoringSession || continuousAt > checkupAt;
}

function parseTimestamp(timestamp: string | null) {
  return timestamp !== null ? new Date(timestamp).valueOf() : new Date(0).valueOf();
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius * 3,
    minHeight: '80px',
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1.25),
  },
  rootWithAlerts: {
    marginTop: theme.spacing(0),
  },
  headline: {
    cursor: 'pointer',
  },
  alertsContainer: {
    display: 'flex',
    flexWrap: 'wrap-reverse',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    bottom: '-8px',
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    zIndex: 1,
  },
  name: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 500,
  },
  label: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 500,
  },
  value: {
    fontSize: theme.typography.body2.fontSize,
  },
  chip: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  emergencyChip: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
  noCheckupsIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[400],
  },
  noCheckupsMessage: {
    marginLeft: theme.spacing(2),
    color: theme.palette.grey[700],
  },
  lastCheckupNotes: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(1, 1.5),
    '& ul': {
      margin: 0,
      paddingLeft: theme.spacing(2),
    },
  },
  chipLabel: {
    fontWeight: 500,
  },
}));
