import { isDefined } from '@/helpers/isDefined';
import { toast } from 'sonner';

interface useCopyToClipboardProps {
  raiseToast?: boolean;
}

export function useCopyToClipboard({ raiseToast = true }: useCopyToClipboardProps = {}) {
  const canCopy = isDefined(navigator?.clipboard?.writeText);

  const copyToClipboard = (text: string, resourceName?: string) => {
    if (!navigator.clipboard) {
      console.error('Clipboard API not available');
      raiseToast && toast.error('Clipboard API not available');
      return;
    }

    try {
      navigator.clipboard.writeText(text);
      raiseToast &&
        toast.info(resourceName ? `${resourceName} copied to clipboard` : 'Copied to clipboard');
    } catch (err) {
      console.error('Could not copy text: ', err);
      raiseToast && toast.error('Failed to copy to clipboard');
    }
  };

  return { copyToClipboard, canCopy };
}
