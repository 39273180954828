import React from 'react';
import { PatientFlagOverviewFragment, PatientFlagType } from '@/generated/graphql';
import { Box, Chip, Divider, Stack, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface PatientFlagItemProps {
  patientFlag: PatientFlagOverviewFragment;
  isLast: boolean;
  onChange: (flagType: PatientFlagType, value: boolean) => void;
  isFlagPendingChange?: (patientFlag: PatientFlagOverviewFragment) => boolean;
  variant?: 'normal' | 'compact';
}

export function PatientFlagItem({
  patientFlag,
  isLast,
  onChange,
  isFlagPendingChange,
  variant = 'normal',
}: PatientFlagItemProps) {
  const classes = useStyles();

  const isVariantNormal = variant === 'normal';

  return (
    <Box
      aria-label={patientFlag.type}
      key={patientFlag.type}
      sx={
        isVariantNormal
          ? {
              borderRadius: 2,
              '&:hover': {
                backgroundColor: 'grey.100',
              },
            }
          : {}
      }>
      <Stack
        paddingX={isVariantNormal ? 2 : 0}
        paddingTop={isVariantNormal ? 2 : 0}
        paddingBottom={isLast ? 2 : 0}
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Stack>
          <Stack direction="row" alignItems="flex-start" gap={1}>
            <Typography
              id={`flag-${patientFlag.type}-label`}
              className={isVariantNormal ? classes.normalTitle : classes.compactTitle}>
              {patientFlag.title}
            </Typography>
            {isFlagPendingChange && isFlagPendingChange(patientFlag) && (
              <Chip label="pending" color="warning" size="small" />
            )}
          </Stack>
          <Typography
            variant="body2"
            color="grey.700"
            className={isVariantNormal ? classes.normalDescription : classes.compactDescription}>
            {patientFlag.description}
          </Typography>
        </Stack>
        <div>
          <Switch
            checked={patientFlag.active}
            onChange={(e) => onChange(patientFlag.type, e.target.checked)}
            inputProps={{ 'aria-labelledby': `flag-${patientFlag.type}-label` }}
          />
        </div>
      </Stack>
      {!isLast && <Divider sx={{ marginTop: 2, marginX: 2 }} />}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  normalTitle: {
    color: theme.palette.primary.dark,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 500,
  },
  compactTitle: {
    color: theme.palette.primary.dark,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
  },
  normalDescription: {
    fontSize: theme.typography.pxToRem(14),
  },
  compactDescription: {
    fontSize: theme.typography.pxToRem(12),
  },
}));
