import React from 'react';
import { ConfidenceType } from '@/components/VitalsPopover';
import { Box } from '@mui/material';

interface ConfidenceDotProps {
  confidence?: ConfidenceType | undefined;
  isManualOverride?: boolean;
  hasEwsScore?: boolean;
}

export const VitalReadingStatusIndicator: React.FC<ConfidenceDotProps> = ({
  confidence,
  isManualOverride = false,
  hasEwsScore = false,
}) => {
  const getDotColor = (confidence: ConfidenceType | undefined, isManualOverride: boolean) => {
    if (isManualOverride) return 'grey.500';

    if (!confidence) return 'grey.500';

    switch (confidence) {
      case 'HIGH':
        return 'success.main';
      case 'FAILED':
        return 'error.main';
      case 'LOW_USER':
      case 'LOW_CLINICAL':
        return 'warning.main';
      default:
        return 'grey.500';
    }
  };

  const dotStyle = {
    width: '0.45rem',
    height: '0.45rem',
    position: 'absolute',
    left: '11px',
    top: '50%',
    transform: 'translateY(-50%)',
    borderRadius: '50%',
    backgroundColor: getDotColor(confidence, isManualOverride),
    outline: hasEwsScore ? '1px solid rgba(255, 255, 255, 0.7)' : null,
  };

  return <Box sx={dotStyle} />;
};
