import React, { useMemo, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { IconButtonProps, ListItemIcon, ListSubheader, Tooltip } from '@mui/material';
import { gql } from '@apollo/client';
import {
  PatientDetailsFragment,
  useGetContinuousMonitoringSessionQuery,
  useGetActivityMonitoringSessionQuery,
  useGetTransferWardAndCarePathwayOptionsQuery,
} from '@/generated/graphql';
import {
  Delete,
  Edit,
  VideoCall,
  WifiTethering,
  LockOpen,
  ContentCopy,
  DirectionsWalk,
  SwapHoriz,
  MoreVert as MoreVertIcon,
  AltRoute as AltRouteIcon,
  Timeline as TimelineIcon,
} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { useVideoCallModal } from '@/components/VideoCallModal';
import { useStartMonitoringSessionModal } from '@/pages/PatientDetail/components/StartMonitoringSessionModal';
import { useEndMonitoringSessionModal } from '@/pages/PatientDetail/components/EndMonitoringSessionModal';
import { useStartActivityMonitoringSessionModal } from '@/pages/PatientDetail/components/StartActivityMonitoringSessionModal';
import { useEndActivityMonitoringSessionModal } from '@/pages/PatientDetail/components/EndActivityMonitoringSessionModal';
import { useEditPatientModal } from '@/pages/ManagePatients/components/PatientFormModal';
import { useDeletePatientModal } from '@/pages/ManagePatients/components/DeletePatientModal';
import { useTransferWardModal } from '@/pages/ManagePatients/components/TransferWardModal';
import { useHistory } from 'react-router-dom';
import { useSetPasswordModal } from '@/pages/ManagePatients/components/SetPasswordModal';
import { isDefined } from '@/helpers/isDefined';
import { useMeActingOrganizationFeature, useMePermissions } from '@/hooks/useAuth';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import auth from '@/controllers/Auth';
import { useTransferCarePathwayModal } from '@/pages/ManagePatients/components/TransferCarePathwayModal';
import { useSetPatientAcuityScoreModal } from './PatientAcuity/SetPatientAcuityModal';

export const GET_ACTIVITY_MONITORING_SESSION = gql`
  fragment PacsanaSessionItem on PacsanaSession {
    id
    organization {
      id
    }
  }

  query GetActivityMonitoringSession($patientId: ID!) {
    pacsanaSession(patientId: $patientId) {
      ...PacsanaSessionItem
    }
  }
`;

export const QUERY_WARDS_AND_CARE_PATHWAYS = gql`
  query GetTransferWardAndCarePathwayOptions {
    wards {
      id
      name
    }
    carePathways {
      id
      name
    }
  }
`;

interface PatientHeaderActionsProps {
  patient: PatientDetailsFragment;
  refresh: () => void;
}

function usePatientHeaderActions({
  patient,
  closePopover,
  refresh,
}: {
  patient: PatientDetailsFragment;
  closePopover: () => void;
  refresh: () => void;
}) {
  const history = useHistory();

  const { id: patientId, telephone, selfCare } = patient;

  const orgHasContinuousMonitoring = useMeActingOrganizationFeature('continuousMonitoring');
  const orgHasActivityMonitoring = useMeActingOrganizationFeature('activityMonitoring');
  const orgHasVideoCallFeature = useMeActingOrganizationFeature('videoCall');
  const orgHasPatientAcuityScoreFeature = useMeActingOrganizationFeature('patientAcuityScore');
  const permissions = useMePermissions();

  const {
    data: { continuousMonitoringSession } = {},
    loading: continuousMonitoringIsLoading,
    refetch: refreshContinuousMonitoring,
  } = useGetContinuousMonitoringSessionQuery({
    variables: { patientId: patient.id },
    skip: !orgHasContinuousMonitoring,
    // For now, just dump errors to the console as there's nothing for the user to do
    onError: console.error,
  });

  const { data: { wards, carePathways } = {}, loading: transferOptionsIsLoading } =
    useGetTransferWardAndCarePathwayOptionsQuery({
      onError: console.error,
    });

  const hasExistingContinuousMonitoring = isDefined(continuousMonitoringSession);

  const {
    data: { pacsanaSession: activityMonitoringSession } = {},
    loading: activityMonitoringIsLoading,
    refetch: refreshActivityMonitoring,
  } = useGetActivityMonitoringSessionQuery({
    variables: { patientId: patient.id },
    skip: !orgHasActivityMonitoring,
    // For now, just dump errors to the console as there's nothing for the user to do
    onError: console.error,
  });

  const hasExistingActivityMonitoring = isDefined(activityMonitoringSession);

  const { showVideoCallModal } = useVideoCallModal();
  const { showStartMonitoringSessionModal } = useStartMonitoringSessionModal({
    onAdded: () => {
      refreshContinuousMonitoring();
      refresh();
    },
  });
  const { showEndMonitoringSessionModal } = useEndMonitoringSessionModal({
    onAdded: () => {
      refreshContinuousMonitoring();
      refresh();
    },
  });
  const { showStartActivityMonitoringSessionModal } = useStartActivityMonitoringSessionModal({
    onAdded: () => {
      refreshActivityMonitoring();
      refresh();
    },
  });
  const { showEndActivityMonitoringSessionModal } = useEndActivityMonitoringSessionModal({
    onAdded: () => {
      refreshActivityMonitoring();
      refresh();
    },
  });
  const { showEditPatientModal } = useEditPatientModal({
    onComplete: () => {
      // ideally we would just use this response to update the UI, but for now just refresh
      refresh();
    },
  });
  const { showDeletePatientModal } = useDeletePatientModal({
    onDelete: () => {
      history.push('/patients');
    },
  });
  const { showTransferWardModal } = useTransferWardModal({
    onComplete: () => {
      // ideally we would just use this response to update the UI, but for now just refresh
      refresh();
    },
  });
  const { showTransferCarePathwayModal } = useTransferCarePathwayModal({
    onComplete: () => {
      // ideally we would just use this response to update the UI, but for now just refresh
      refresh();
    },
  });
  const { showSetPasswordModal } = useSetPasswordModal();

  const { showSetPatientAcuityScoreModal } = useSetPatientAcuityScoreModal({
    onAcuityScoreSet() {
      refresh();
    },
  });

  const { copyToClipboard, canCopy } = useCopyToClipboard();

  const patientHasContactDetails =
    isDefined(telephone) || Boolean(patient.selfCare?.canReceivePushNotifications);

  const startVideoCall =
    permissions['view_patients'] && orgHasVideoCallFeature
      ? ({
          icon: <VideoCall />,
          text: 'Start Video Call',
          state: patientHasContactDetails ? 'ready' : 'disabled',
          tooltip: patientHasContactDetails
            ? undefined
            : 'No contact details or device registered for this patient',
          style: 'normal',
          onClick: () => {
            showVideoCallModal({
              patientId: patientId,
              patientTelephone: telephone,
              canReceivePushNotifications: patient.selfCare?.canReceivePushNotifications ?? false,
            });
          },
        } satisfies PatientActionsListItemProps)
      : null;
  const startContinuousMonitoring =
    permissions['edit_patients'] && orgHasContinuousMonitoring && !hasExistingContinuousMonitoring
      ? ({
          icon: <WifiTethering />,
          text: 'Start Continuous Monitoring',
          state: continuousMonitoringIsLoading ? 'loading' : 'ready',
          style: 'normal',
          onClick: () => showStartMonitoringSessionModal(patientId),
        } satisfies PatientActionsListItemProps)
      : null;
  const actingOrgOwnsContinuousMonitoringSession =
    auth.me('actingOrganization.id') === continuousMonitoringSession?.organization.id;

  const endContinuousMonitoring =
    orgHasContinuousMonitoring && hasExistingContinuousMonitoring
      ? ({
          icon: <WifiTethering />,
          text: 'End Continuous Monitoring',
          state: continuousMonitoringIsLoading
            ? 'loading'
            : actingOrgOwnsContinuousMonitoringSession
            ? 'ready'
            : 'disabled',
          style: 'normal',
          tooltip: actingOrgOwnsContinuousMonitoringSession
            ? undefined
            : "The patient's current session was started by another organisation. Only the organisation which started this session can end it.",
          onClick: () => showEndMonitoringSessionModal(patientId),
        } satisfies PatientActionsListItemProps)
      : null;

  const actingOrgOwnsActivityMonitoringSession =
    auth.me('actingOrganization.id') === activityMonitoringSession?.organization.id;

  const startActivityMonitoring =
    permissions['edit_patients'] && orgHasActivityMonitoring && !hasExistingActivityMonitoring
      ? ({
          icon: <DirectionsWalk />,
          text: 'Start Activity Monitoring',
          state: activityMonitoringIsLoading ? 'loading' : 'ready',
          style: 'normal',
          onClick: () => showStartActivityMonitoringSessionModal(patientId),
        } satisfies PatientActionsListItemProps)
      : null;
  const endActivityMonitoring =
    permissions['edit_patients'] && orgHasActivityMonitoring && hasExistingActivityMonitoring
      ? ({
          icon: <DirectionsWalk />,
          text: 'End Activity Monitoring',
          state: activityMonitoringIsLoading
            ? 'loading'
            : actingOrgOwnsActivityMonitoringSession
            ? 'ready'
            : 'disabled',
          style: 'normal',
          tooltip: actingOrgOwnsActivityMonitoringSession
            ? undefined
            : "The patient's current session was started by another organisation. Only the organisation which started this session can end it.",
          onClick: () => showEndActivityMonitoringSessionModal(patientId),
        } satisfies PatientActionsListItemProps)
      : null;
  const resetPassword =
    selfCare && permissions['create_patients']
      ? ({
          icon: <LockOpen />,
          text: 'Reset Password',
          state: selfCare.canResetPassword ? 'ready' : 'disabled',
          tooltip: selfCare.canResetPassword
            ? undefined
            : 'Cannot reset password because the email address used by this patient is associated with a Feebris login in another organisation',
          style: 'normal',
          onClick: () => {
            showSetPasswordModal({
              user: selfCare,
            });
          },
        } satisfies PatientActionsListItemProps)
      : null;
  const editPatient = permissions['edit_patients']
    ? ({
        icon: <Edit />,
        text: 'Edit Patient',
        state: 'ready',
        style: 'normal',
        onClick: () => showEditPatientModal(patient),
      } satisfies PatientActionsListItemProps)
    : null;
  const deletePatient = permissions['delete_patients']
    ? ({
        icon: <Delete />,
        text: 'Remove Patient',
        tooltip: 'Removes a Patient from the Feebris system',
        state: 'ready',
        style: 'danger',
        onClick: () => {
          showDeletePatientModal(patient);
        },
      } satisfies PatientActionsListItemProps)
    : null;

  const copyPatientId =
    permissions['view_patients'] && canCopy
      ? ({
          icon: <ContentCopy />,
          text: 'Copy Feebris Patient ID',
          state: 'ready',
          style: 'normal',
          onClick: () => {
            copyToClipboard(patientId);
          },
        } satisfies PatientActionsListItemProps)
      : null;

  const isAdmitted = Boolean(patient.wardAdmission);
  const canTransferPatientToAnotherWard = wards && wards.length > 1;

  const transferWard =
    permissions['view_patients'] && canCopy && isAdmitted
      ? ({
          icon: <SwapHoriz />,
          text: 'Transfer Ward',
          state: transferOptionsIsLoading
            ? 'loading'
            : canTransferPatientToAnotherWard
            ? 'ready'
            : 'disabled',
          style: 'normal',
          tooltip: canTransferPatientToAnotherWard
            ? undefined
            : 'No other wards available to transfer to',
          onClick: () => {
            // wards & wardAdmission should always be defined here, just being defensive and satisying TS
            if (wards && patient.wardAdmission) {
              showTransferWardModal(
                { ...patient, currentWardId: patient.wardAdmission.ward.id },
                wards,
              );
            }
          },
        } satisfies PatientActionsListItemProps)
      : null;

  const canTransferPatientToAnotherCarePathway =
    carePathways && carePathways.length > 1 && patient.wardAdmission?.carePathway;

  const transferCarePathway =
    permissions['view_patients'] && canCopy && isAdmitted
      ? ({
          icon: <AltRouteIcon />,
          text: 'Change Care Pathway',
          state: transferOptionsIsLoading
            ? 'loading'
            : canTransferPatientToAnotherCarePathway
            ? 'ready'
            : 'disabled',
          style: 'normal',
          tooltip: canTransferPatientToAnotherCarePathway
            ? undefined
            : 'No other care pathways available to transfer to',
          onClick: () => {
            // carePathways & wardAdmission should always be defined here, just being defensive and satisying TS
            if (carePathways && patient.wardAdmission?.carePathway) {
              showTransferCarePathwayModal(
                { ...patient, currentCarePathwayId: patient.wardAdmission.carePathway.id },
                carePathways,
              );
            }
          },
        } satisfies PatientActionsListItemProps)
      : null;

  const setPatientAcuityScore =
    orgHasPatientAcuityScoreFeature && permissions['edit_patients']
      ? ({
          icon: <TimelineIcon />,
          text: 'Set Acuity Score',
          state: 'ready',
          style: 'normal',
          onClick: () => {
            showSetPatientAcuityScoreModal({ patient });
          },
        } satisfies PatientActionsListItemProps)
      : null;

  const possibleActions = [
    setPatientAcuityScore,
    startVideoCall,
    startContinuousMonitoring,
    endContinuousMonitoring,
    startActivityMonitoring,
    endActivityMonitoring,
    transferWard,
    transferCarePathway,
    resetPassword,
    editPatient,
    deletePatient,
    copyPatientId,
  ];

  return possibleActions.filter(isDefined).map((action) => beforeOnClick(closePopover, action));
}

function beforeOnClick<
  T extends {
    onClick: () => void;
  },
>(beforeAction: () => void, { onClick, ...rest }: T) {
  return {
    ...rest,
    onClick: () => {
      beforeAction();
      onClick();
    },
  };
}

function usePopover() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return useMemo(() => {
    const onClickOpen: IconButtonProps['onClick'] = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const close = () => {
      setAnchorEl(null);
    };

    const isOpen = Boolean(anchorEl);

    return { anchorEl, setRef: setAnchorEl, onClickOpen, close, isOpen };
  }, [anchorEl, setAnchorEl]);
}

export function PatientHeaderActionsDropdown({ patient, refresh }: PatientHeaderActionsProps) {
  const popover = usePopover();
  const actions = usePatientHeaderActions({
    patient: patient,
    closePopover: popover.close,
    refresh,
  });

  if (actions.length === 0) {
    return null;
  }

  return (
    <div>
      <Tooltip title="Patient Actions" enterDelay={350} enterNextDelay={350}>
        <IconButton onClick={popover.onClickOpen} aria-label="Patient actions" size="large">
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={popover.isOpen ? 'simple-popover' : undefined}
        open={popover.isOpen}
        anchorEl={popover.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={popover.close}>
        <List subheader={<ListSubheader color="primary">Patient Actions</ListSubheader>}>
          {actions.map((props, i) => (
            <PatientActionsListItem key={`action-${i}`} {...props} />
          ))}
        </List>
      </Popover>
    </div>
  );
}

interface PatientActionsListItemProps {
  icon: React.ReactNode;
  text: string;
  tooltip?: string;
  state: 'loading' | 'ready' | 'disabled';
  style: 'normal' | 'danger';
  onClick: () => void;
}

function PatientActionsListItem({
  icon,
  onClick,
  style,
  text,
  tooltip,
  state,
}: PatientActionsListItemProps) {
  const classes = useListItemStyles();
  const isDanger = style === 'danger';
  const isLoading = state === 'loading';
  const isDisabled = state === 'disabled';

  const content = (
    <span>
      <ListItem
        button
        onClick={state === 'ready' ? onClick : undefined}
        className={isLoading || isDisabled ? classes.disabled : undefined}
        aria-label={text}>
        <ListItemIcon className={isDanger ? classes.dangerIcon : undefined}>{icon}</ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={{
            color: isDanger ? 'error' : 'initial',
          }}
        />
      </ListItem>
    </span>
  );

  return tooltip ? (
    <Tooltip title={tooltip} enterDelay={350} enterNextDelay={350}>
      {content}
    </Tooltip>
  ) : (
    content
  );
}

const useListItemStyles = makeStyles((theme) => ({
  dangerIcon: {
    color: theme.palette.error.main,
  },
  disabled: {
    opacity: 0.2,
    pointerEvents: 'none',
  },
}));
