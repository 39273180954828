import React, { useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { Action, Column, MTableAction } from '@material-table/core';
import { Box, Button, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import { IntegrationDisplayFragmentInternal } from '@/generated/graphql-internal';
import MaterialTableWithIcons from '@/components/MaterialTableWithIcons';
import { useCreateIntegrationModal } from './CreateIntegrationModal';
import {
  formatIntegrationTriggerType,
  formatIntegrationType,
} from '@/components/EhrIntegrations/format';
import { useDeleteIntegrationModal } from './DeleteIntegrationModal';

export const PATHWAY_INTEGRATIONS_FRAGMENT = gql`
  fragment IntegrationDisplay on EhrIntegration {
    id
    name
    integrationType
    triggerType
    createdAt
    updatedAt
    authorizedAutomaticApprover {
      id
      email
    }
    triggerConfig {
      ...FormattableIntegrationTriggerConfig
    }
  }

  fragment FormattableIntegrationTriggerConfig on EhrIntegrationTriggerConfig {
    __typename
    ... on EhrIntegrationPeriodicTriggerConfig {
      rrule
    }
  }
`;

interface PathwayIntegrationsProps {
  pathwayId: string;
  organizationId: string;
  loading: boolean;
  integrations: IntegrationDisplayFragmentInternal[];
  onChange: () => void;
}

export function PathwayIntegrations({
  pathwayId,
  organizationId,
  loading,
  integrations,
  onChange,
}: PathwayIntegrationsProps) {
  const { showModal: showCreateModal } = useCreateIntegrationModal({
    onCreated: onChange,
  });

  const actions: Action<IntegrationDisplayFragmentInternal>[] = [
    {
      icon: 'ADD_HACK',
      tooltip: 'Add Integration',
      isFreeAction: true,
      onClick: () => showCreateModal({ carePathwayId: pathwayId, organizationId }),
    },
  ];

  const columns = useMemo(
    () =>
      [
        { field: 'id', hidden: true },
        {
          field: 'name',
          title: 'Name',
          render: (data) => data.name,
        },
        {
          field: 'integrationType',
          title: 'Type',
          render: (data) => formatIntegrationType(data.integrationType),
        },
        {
          field: 'triggerType',
          title: 'Trigger',
          render: (data) => formatIntegrationTriggerType(data.triggerType, data.triggerConfig),
        },
        { field: 'createdAt', title: 'Created', type: 'datetime' },
        { field: 'updatedAt', title: 'Updated', type: 'datetime' },
        {
          field: 'authorizedAutomaticApprover',
          title: 'Automatic Approver',
          render: (data) => data.authorizedAutomaticApprover?.email,
        },
        {
          field: 'id',
          width: '48px',
          align: 'right',
          render: (data) => <IntegrationMenu integration={data} onChange={onChange} />,
        },
      ] as Column<IntegrationDisplayFragmentInternal>[],
    [onChange],
  );

  return (
    <div>
      <MaterialTableWithIcons
        title={null}
        columns={columns}
        data={integrations}
        isLoading={loading}
        actions={actions}
        components={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Action: (props: any) => {
            if (props.action.icon === 'ADD_HACK') {
              return (
                <Box marginLeft={2}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={props.action.onClick}>
                    {props.action.tooltip}
                  </Button>
                </Box>
              );
            }
            return (
              <MTableAction
                {...props}
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  event.stopPropagation();
                  props.onClick();
                }}
              />
            );
          },
        }}
      />
    </div>
  );
}

interface IntegrationMenuProps {
  integration: IntegrationDisplayFragmentInternal;
  onChange: () => void;
}

function IntegrationMenu({ integration, onChange }: IntegrationMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { showModal: showDeleteModal } = useDeleteIntegrationModal({
    onDeleted: onChange,
  });

  const menuItems = useMemo(
    () => [
      {
        disabled: false,
        label: 'Delete',
        onClick: () => showDeleteModal(integration),
      },
    ],
    [integration, showDeleteModal],
  );

  if (menuItems.length === 0) {
    return null;
  }

  return (
    <>
      <IconButton aria-label="Open Menu" onClick={(e) => setAnchorEl(e.currentTarget)} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        {menuItems.map((item) => (
          <MenuItem
            key={item.label}
            disabled={item.disabled}
            onClick={() => {
              item.onClick();
              setAnchorEl(null);
            }}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
