/// <reference types="vite-plugin-svgr/client" />

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import './styles/mixins/fonts.scss';
import './styles/root.scss';
import './styles/styles.scss';
import './styles/buttons.scss';
import './styles/forms.scss';
import './styles/tables.scss';
import FirebaseApp from './FirebaseApp';
import { enGB } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import { initialise as posthogInitialise } from '@/helpers/posthog';

// TODO: Switch to the users locale
setDefaultOptions({ locale: enGB });

Sentry.init({
  // We do not want errors when running locally going into Sentry
  // this will also include e2e tests running in GitHub actions
  dsn:
    window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
      ? undefined
      : 'https://bb59f22509fb4e2d8403ca8bae90e530@o405828.ingest.sentry.io/5272861',
  environment: process.env.REACT_APP_FEEBRIS_ENVIRONMENT,
  release: process.env.REACT_APP_VERSION, // TODO: Finer grained release management
  ignoreErrors: [
    'No token found',
    // we don't want connectivity blips to land in Sentry
    'auth/network-request-failed',
    'Network request failed',
    'Failed to fetch',
  ],
});

FirebaseApp.getInstance();

posthogInitialise();

class MainApp extends React.Component {
  render() {
    return (
      <React.Fragment>
        <App />
      </React.Fragment>
    );
  }
}

ReactDOM.render(<MainApp />, document.getElementById('root'));
