import React from 'react';

import { gql } from '@apollo/client';
import { NavLink, Redirect, Route, Switch, useParams } from 'react-router-dom';
import { Skeleton, Stack } from '@mui/material';
import { Box, Button, Chip, Tooltip, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { CarePathwayThresholds } from './CarePathwayThresholds';
import { CheckupTypes } from './CheckupTypes';
import {
  OrganizationInternal,
  useCarePathwayAdminQueryInternal,
} from '@/generated/graphql-internal';
import AdminAlertRules from './AdminAlertRules';
import { CarePathwayStatusChip } from '@/components/CarePathway/CarePathwayStatusChip';
import { PathwayIntegrations } from './Integrations/AdminPathwayIntegrations';

export const QUERY_PATHWAY = gql`
  fragment AdminCheckupTypeItem on CheckupType {
    id
    name
    heading
    subheading
    description
    schedule {
      tolerance {
        early
        late
      }
      rrule
    }
    checkupTiles {
      symptomsQuestionnaire {
        rule
        questionnaireSections
      }
      consciousness {
        rule
      }
      pulseOximeter {
        rule
        manualEntry
        usePlethAI
      }
      respiratoryRate {
        rule
      }
      bloodPressureCuff {
        rule
        manualEntry
      }
      temperature {
        rule
      }
      stethoscope {
        rule
        forceOnAbnormalSpO2
        forceOnRespiratorySoftSigns
        forceOnAbnormalRespiratoryRate
      }
      picture {
        rule
      }
      weight {
        rule
      }
      glucose {
        rule
      }
    }
    createdAt
    updatedAt
  }

  query CarePathwayAdmin($id: ID!) {
    carePathway(id: $id) {
      name
      publishedAt
      deletedAt
      ewsThresholds {
        thresholds
      }
      checkupTypes {
        ...AdminCheckupTypeItem
      }
      organization {
        id
      }
      integrations {
        ...IntegrationDisplay
      }
    }
  }
`;

export interface AdminCarePathwayProps {
  organizationFeatures: OrganizationInternal['features'];
}

export function AdminCarePathway({ organizationFeatures }: AdminCarePathwayProps) {
  const classes = useStyles();

  const { organizationId, carePathwayId } = useParams<{
    organizationId: string;
    carePathwayId: string;
  }>();

  const { data, loading, refetch, error } = useCarePathwayAdminQueryInternal({
    variables: { id: carePathwayId },
  });

  return (
    <Box margin={2}>
      <Box display="flex" gap={4}>
        {loading ? (
          <Skeleton width={160} height="28px" />
        ) : (
          <Stack direction="row" gap={1}>
            <Typography variant="h6">{data?.carePathway?.name}</Typography>
            <CarePathwayStatusChip
              publishedAt={data?.carePathway?.publishedAt}
              deletedAt={data?.carePathway?.deletedAt}
            />
            {data?.carePathway?.organization === null && (
              <Tooltip title="This is a shared care pathway and so may be used within multiple organizations">
                <Chip
                  className={classes.sharedIndicator}
                  label="Shared"
                  color="secondary"
                  size="small"
                />
              </Tooltip>
            )}
          </Stack>
        )}
      </Box>
      {error ? (
        <div className={classes.errorDisplay}>
          <Typography variant="h5">Something went wrong</Typography>
          <Button variant="outlined" color="primary" onClick={() => refetch()}>
            Try again
          </Button>
          <code>{JSON.stringify(error)}</code>
        </div>
      ) : (
        <>
          <Box marginTop={2} role="tablist">
            <Button
              component={NavLink}
              to={`/admin/${organizationId}/carepathways/${carePathwayId}/checkuptypes`}
              role="tab"
              id="admin-carepathway-checkuptypes-tab"
              aria-controls="admin-carepathway-checkuptypes-tabpanel"
              variant="contained"
              color="primary"
              disableElevation
              className={classes.tabButton}>
              Check-up Types
            </Button>
            <Button
              component={NavLink}
              to={`/admin/${organizationId}/carepathways/${carePathwayId}/thresholds`}
              role="tab"
              id="admin-carepathway-thresolds-tab"
              aria-controls="admin-carepathway-thresolds-tabpanel"
              variant="contained"
              color="primary"
              disableElevation
              className={classes.tabButton}>
              Thresholds
            </Button>
            <Button
              component={NavLink}
              to={`/admin/${organizationId}/carepathways/${carePathwayId}/alerts`}
              role="tab"
              id="admin-carepathway-alerts-tab"
              aria-controls="admin-carepathway-alerts-tabpanel"
              variant="contained"
              color="primary"
              disableElevation
              className={classes.tabButton}>
              Alerts
            </Button>
            <Button
              component={NavLink}
              to={`/admin/${organizationId}/carepathways/${carePathwayId}/integrations`}
              role="tab"
              id="admin-carepathway-integrations-tab"
              aria-controls="admin-carepathway-integrations-tabpanel"
              variant="contained"
              color="primary"
              disableElevation
              className={classes.tabButton}>
              Integrations
            </Button>
          </Box>
          <Box marginTop={2}>
            <Switch>
              <Route path="/admin/:organizationId/carepathways/:carePathwayId/thresholds">
                <div
                  role="tabpanel"
                  id="admin-carepathway-thresolds-tabpanel"
                  aria-labelledby="admin-carepathway-thresolds-tab">
                  <CarePathwayThresholds
                    carePathwayId={carePathwayId}
                    thresholds={data?.carePathway?.ewsThresholds?.thresholds}
                    onThresholdsSet={() => refetch()}
                  />
                </div>
              </Route>
              <Route path="/admin/:organizationId/carepathways/:carePathwayId/checkuptypes">
                <div
                  role="tabpanel"
                  id="admin-carepathway-checkuptypes-tabpanel"
                  aria-labelledby="admin-carepathway-checkuptypes-tab">
                  <CheckupTypes
                    loading={loading}
                    carePathwayId={carePathwayId}
                    pathwayPublishedAt={data?.carePathway?.publishedAt}
                    checkupTypes={data?.carePathway?.checkupTypes}
                    onCheckupTypeAdded={() => refetch()}
                    onCheckupTypeUpdated={() => refetch()}
                    onCheckupTypeDeleted={() => refetch()}
                  />
                </div>
              </Route>
              <Route path="/admin/:organizationId/carepathways/:carePathwayId/alerts">
                <div
                  role="tabpanel"
                  id="admin-carepathway-alerts-tabpanel"
                  aria-labelledby="admin-carepathway-alerts-tab">
                  <AdminAlertRules
                    carePathwayId={carePathwayId}
                    carePathwayName={data?.carePathway?.name}
                    organizationId={organizationId}
                    organizationFeatures={organizationFeatures}
                  />
                </div>
              </Route>
              <Route path="/admin/:organizationId/carepathways/:carePathwayId/integrations">
                <div
                  role="tabpanel"
                  id="admin-carepathway-integrations-tabpanel"
                  aria-labelledby="admin-carepathway-integrations-tab">
                  <PathwayIntegrations
                    loading={loading}
                    pathwayId={carePathwayId}
                    organizationId={organizationId}
                    onChange={() => refetch()}
                    integrations={data?.carePathway?.integrations ?? []}
                  />
                </div>
              </Route>
              <Redirect to="/admin/:organizationId/carepathways/:carePathwayId/checkuptypes" />
            </Switch>
          </Box>
        </>
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  tabButton: {
    fontSize: theme.typography.body1.fontSize,
    marginRight: theme.spacing(2),
    padding: theme.spacing(0.5, 2.5),
    borderRadius: theme.shape.borderRadius * 8,
    background: 'transparent',
    color: theme.palette.primary.dark,
    textTransform: 'none',
    transition: theme.transitions.create(['background', 'color']),
    '&:hover': {
      background: theme.palette.grey.A100,
    },
    '&.active': {
      color: theme.palette.background.paper,
      background: theme.palette.primary.main,
    },
  },
  errorDisplay: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& > code': {
      maxWidth: '60vw',
    },
  },
  sharedIndicator: {
    color: theme.palette.common.white,
  },
}));
