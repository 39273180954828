/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import _, { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Alert,
  Button,
  Chip,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  FormControl,
  FormControlLabel,
  MenuItem,
  TextField,
  Switch,
} from '@mui/material';
import { toast } from 'sonner';
import { gql } from '@apollo/client';

import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import WarningIcon from '@mui/icons-material/Warning';
import { MTableEditField, MTableToolbar, MTableAction, Column } from '@material-table/core';
import MaterialTableWithIcons from '@/components/MaterialTableWithIcons';
import Loading from '@/components/Loading';

import api from '@/controllers/Api';
import { PageTitle } from '@/components/PageTitle';
import {
  AdminOrganizationsItemFragmentInternal,
  useAdminOrganizationsQueryInternal,
} from '@/generated/graphql-internal';
import { ErrorDisplay } from '@/components/ErrorDisplay';

export const QUERY_ADMIN_ORGANIZATIONS = gql`
  fragment AdminOrganizationsItem on Organization {
    id
    needsInviteSent
    name
    type
    telephone
    address {
      address
      postcode
    }
    defaultPracticeIds
    neighbors {
      id
      name
      type
    }
    metadata
    kits
    odsCode
    defaultCarePathway {
      id
    }
    checkupConfig {
      temperatureDeviceType
      pulseOximeterDeviceType
      bloodPressureCuffDeviceType
    }
    createdAt
    deletedAt
    updatedAt
  }

  query AdminOrganizations($showDeleted: Boolean) {
    organizations(showDeleted: $showDeleted) {
      ...AdminOrganizationsItem
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  emphasised: {
    fontStyle: 'italic',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formSection: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  formGroup: {
    margin: theme.spacing(2, 0),
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formAddressWrapper: {
    display: 'flex',
    width: '100%',
  },
  formAddress: {
    flexGrow: 1,
    paddingRight: theme.spacing(1),
  },
  formPostcode: {
    width: '30%',
  },
  addressEditField: {
    margin: theme.spacing(1),
  },
  showDeletedSwitch: {
    margin: theme.spacing(0, 2),
  },
  featureInput: {
    width: theme.spacing(12),
    marginRight: theme.spacing(2),
  },
}));

export default function AdminOrganizations() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);

  const initialNewOrganization = {
    organization: {
      type: '',
      name: '',
      telephone: '',
      address: {
        address: '',
        postcode: '',
      },
    },
    user: {
      email: '',
    },
  };
  const [newOrganization, setNewOrganization] = useState(initialNewOrganization);
  const [newOrganizationErrors, setNewOrganizationErrors] = useState({});
  const [showDeleted, setShowDeleted] = useState(false);

  const {
    data,
    refetch,
    error: loadError,
  } = useAdminOrganizationsQueryInternal({
    variables: { showDeleted },
    onError: () => toast.error('An error occurred while loading organizations'),
  });

  const organizations = data?.organizations ?? [];

  const editableWhenEmpty = (field: string) => (rowData: AdminOrganizationsItemFragmentInternal) =>
    (get(rowData, field) as React.ReactNode) || '\u2003';

  const columns: Column<AdminOrganizationsItemFragmentInternal>[] = [
    {
      field: 'deletedAt',
      title: 'Deleted?',
      editable: 'never',
      hidden: !showDeleted,
    },
    {
      field: 'needsInviteSent',
      title: 'Needs invite?',
      editable: 'never',
      hidden: !organizations?.some((organization) => organization.needsInviteSent),
      render: (rowData) =>
        rowData.needsInviteSent ? (
          // FIXME: Old-school css..
          <Tooltip title="No users in this organization have been invited to Feebris yet">
            <WarningIcon color="secondary" style={{ margin: '0 auto', width: '100%' }} />
          </Tooltip>
        ) : null,
    },
    {
      field: 'id',
      title: 'ID',
      editable: 'never',
      defaultSort: 'desc',
      // eslint-disable-next-line react/display-name
      render: (rowData) => (
        <Link to={`/admin/${rowData.id}`} className="e2e__organizationlink" component={RouterLink}>
          {rowData.id}
        </Link>
      ),
    },
    { field: 'name', title: 'Name', render: editableWhenEmpty('name') },
    { field: 'type', title: 'Type', editable: 'never' },
    {
      field: 'metadata',
      title: 'Metadata',
      customFilterAndSearch: (filter, rowData) => {
        return _.some(
          rowData.metadata,
          (value, key) =>
            key.toLowerCase().includes(filter.toLowerCase()) ||
            value.toString().toLowerCase().includes(filter.toLowerCase()),
        );
      },
      render: (rowData) =>
        !_.isEmpty(rowData.metadata) ? (
          _.map(rowData.metadata || {}, (value, key) => (
            <Chip label={`${key}: ${value}`} key={key} className={classes.chip} />
          ))
        ) : (
          <Typography>No metadata</Typography>
        ),
    },
    {
      field: 'kits',
      title: 'Kits',
      render: () => (
        <pre>
          {'{'}JSON{'}'}
        </pre>
      ),
    },
    {
      field: 'odsCode',
      title: 'ODS Code',
      render: editableWhenEmpty('odsCode'),
    },
    {
      field: 'neighbors',
      title: 'Neighbours',
      editable: 'never',
      sorting: false,
      // eslint-disable-next-line react/display-name
      render: (rowData) => (
        <>
          {(rowData.neighbors || []).length > 0 ? (
            rowData.neighbors.map((neighbor) => (
              <Chip
                label={neighbor.name}
                key={neighbor.id}
                icon={neighbor.type === 'care_home' ? <HomeIcon /> : <LocalHospitalIcon />}
                component="a"
                href={`/admin/${neighbor.id}/users`}
                className={classes.chip}
                clickable
              />
            ))
          ) : (
            <Typography className={classes.emphasised}>
              Not linked to any other organisation
            </Typography>
          )}
        </>
      ),
    },
    {
      field: 'address',
      title: 'Address',
      // eslint-disable-next-line react/display-name
      render: (rowData) => (
        <React.Fragment>
          <p>{rowData.address.address}</p>
          <p>{rowData.address.postcode}</p>
        </React.Fragment>
      ),
    },
    { field: 'telephone', title: 'Telephone', render: editableWhenEmpty('telephone') },
    { field: 'defaultPracticeIds', title: 'Default Practice Ids', hidden: true, editable: 'never' },
    { field: 'createdAt', title: 'Created At', type: 'date', hidden: true, editable: 'never' },
    {
      field: 'defaultCarePathway.id',
      title: 'Default Pathway ID',
      render: editableWhenEmpty('defaultCarePathway.id'),
    },
    {
      field: 'updatedAt',
      title: 'Updated At',
      type: 'date',
      editable: 'never',
      render: ({ updatedAt }) => {
        return !updatedAt ? '' : t('DATE_SHORT', { val: new Date(updatedAt) });
      },
    },
  ];

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);
  let cellEditableError: string | null = null; // NOTE: This is ephemeral local state, it will vanish on re-render

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // Clear previous errors
    setNewOrganizationErrors({});
    const response = await api.createOrganizationAndFirstUser(newOrganization);

    if (response.errors) {
      setNewOrganizationErrors(response.errors[0].extensions.invalidArgs);
    } else {
      await api.sendWelcomeEmailInternal({
        userId: response.data.createOrganizationAndFirstUser.user.id,
        organizationId: response.data.createOrganizationAndFirstUser.organization.id,
      });
      // Close the dialog
      closeDialog();
      // Make the success message visible
      toast.success(`
        Successfully created new Organisation
        ${JSON.stringify(newOrganization.organization.name)}.
        An email has been sent to the first User ${JSON.stringify(newOrganization.user.email)}
        so they can set their password and log in.
      `);
      // Reset the form
      setNewOrganization(initialNewOrganization);
      await refetch();
    }
  };

  return (
    <div className={classes.root}>
      <PageTitle
        title="Feebroid Organisation Admin"
        subtitle="Create, update and manage our customer's organisations"
        titleMeta="Feebroid Admin"
      />
      <Dialog open={dialogOpen} onClose={closeDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Organisation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create a new organisation along with the first admin for that organisation.
          </DialogContentText>

          <form className={classes.form} autoComplete="off">
            <div className={classes.formSection}>
              <Typography marginTop={1} variant="h6" component="h4">
                Organisation Details
              </Typography>
              <FormControl
                className={clsx(classes.margin, 'e2e__organizationtype')}
                fullWidth
                required
                error={_.has(newOrganizationErrors, 'organization.type')}>
                <TextField
                  select
                  variant="filled"
                  label="Type"
                  value={newOrganization.organization.type}
                  helperText={_.get(newOrganizationErrors, 'organization.type')}
                  onChange={(event) =>
                    setNewOrganization({
                      ...newOrganization,
                      organization: { ...newOrganization.organization, type: event.target.value },
                    })
                  }>
                  <MenuItem value="care_home">Care Provider</MenuItem>
                  <MenuItem value="practice">{t('GP Practice')}</MenuItem>
                </TextField>
              </FormControl>
              <TextField
                required
                fullWidth
                name="organization.name"
                variant="filled"
                error={_.has(newOrganizationErrors, 'organization.name')}
                helperText={_.get(newOrganizationErrors, 'organization.name')}
                className={classes.margin}
                label="Name"
                value={newOrganization.organization.name}
                onChange={(event) =>
                  setNewOrganization({
                    ...newOrganization,
                    organization: { ...newOrganization.organization, name: event.target.value },
                  })
                }
              />
              <TextField
                required
                fullWidth
                name="organization.telephone"
                error={_.has(newOrganizationErrors, 'organization.telephone')}
                helperText={_.get(newOrganizationErrors, 'organization.telephone')}
                className={classes.margin}
                variant="filled"
                label="Telephone"
                value={newOrganization.organization.telephone}
                onChange={(event) =>
                  setNewOrganization({
                    ...newOrganization,
                    organization: {
                      ...newOrganization.organization,
                      telephone: event.target.value,
                    },
                  })
                }
              />
              <div className={classes.formAddressWrapper}>
                <TextField
                  required
                  name="organization.address.address"
                  error={_.has(newOrganizationErrors, 'organization.address.address')}
                  helperText={_.get(newOrganizationErrors, 'organization.address.address')}
                  variant="filled"
                  label="Address"
                  className={clsx(classes.formAddress, classes.margin)}
                  value={newOrganization.organization.address.address}
                  onChange={(event) =>
                    setNewOrganization({
                      ...newOrganization,
                      organization: {
                        ...newOrganization.organization,
                        address: {
                          ...newOrganization.organization.address,
                          address: event.target.value,
                        },
                      },
                    })
                  }
                />
                <TextField
                  required
                  label="Postcode"
                  variant="filled"
                  name="organization.address.postcode"
                  error={_.has(newOrganizationErrors, 'organization.address.postcode')}
                  helperText={_.get(newOrganizationErrors, 'organization.address.postcode')}
                  className={clsx(classes.formPostcode, classes.margin)}
                  value={newOrganization.organization.address.postcode}
                  onChange={(event) =>
                    setNewOrganization({
                      ...newOrganization,
                      organization: {
                        ...newOrganization.organization,
                        address: {
                          ...newOrganization.organization.address,
                          postcode: event.target.value,
                        },
                      },
                    })
                  }
                />
              </div>
            </div>

            <div className={classes.formSection}>
              <Typography component="h4" variant="h6">
                First User Details
              </Typography>
              <Alert severity="warning" sx={{ marginY: 1 }}>
                <Typography>
                  This user will be created as an admin for the organization. They will be sent an
                  email to set their password and log in.
                </Typography>
              </Alert>
              <TextField
                required
                name="user.email"
                error={_.has(newOrganizationErrors, 'user.email')}
                helperText={_.get(newOrganizationErrors, 'user.email')}
                fullWidth
                className={classes.margin}
                type="email"
                label="Email"
                variant="filled"
                value={newOrganization.user.email}
                onChange={(event) =>
                  setNewOrganization({
                    ...newOrganization,
                    user: { email: event.target.value },
                  })
                }
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            className="e2e__addorganizationsubmit">
            Create Organisation
          </Button>
        </DialogActions>
      </Dialog>

      <MaterialTableWithIcons
        columns={columns}
        data={organizations}
        actions={[
          {
            icon: 'CREATE',
            tooltip: t('Add Organisation'),
            isFreeAction: true,
            onClick: () => openDialog(),
          },
        ]}
        title={null}
        localization={{
          body: {
            emptyDataSourceMessage: loadError ? (
              <ErrorDisplay
                showIcon
                heading="Failed to load organisations"
                message="Click retry to try again"
                retry={() => refetch()}
              />
            ) : (
              'No organisations found'
            ),
          },
        }}
        options={{ pageSize: 10, showEmptyDataSourceMessage: organizations !== undefined }}
        isLoading={!organizations}
        cellEditable={{
          isCellEditable: (_rowData, columnDef) => columnDef.editable !== 'never',
          cellStyle: {},
          onCellEditApproved: async (newValue, _oldValue, rowData, columnDef) => {
            let response;

            // TODO: the typing of this is a bit of a mess
            const params: Partial<Record<string, any>> = { id: rowData.id };

            const field = columnDef.field;

            if (field === 'metadata' || field === 'kits') {
              newValue = _.isString(newValue) ? JSON.parse(newValue) : newValue;
              if (_.isEmpty(newValue)) {
                newValue = null;
              }
            }

            if (typeof field !== 'string') {
              throw new Error('Field must be a string');
            }

            params[field] = newValue;

            if (field === 'defaultCarePathway.id') {
              params.defaultCarePathwayId = params[field] || null;
              delete params[field];
            }
            try {
              response = await api.updateOrganization(params);
            } catch (err) {
              response = err instanceof Error ? err.message : 'An error occurred';
            }

            if (response.errors) {
              // This will display the error as helperText for the checkupConfig and metadata fields
              cellEditableError = response.errors[0].message;
              // Throwing some error is necessary to instruct material-table that the save failed
              throw new Error(response.errors[0].message);
            }
            // HACK: Surely mutating rowData isn't the right way to update the data without
            //       an AJAX call..
            (rowData as any)[field] = newValue;
          },
        }}
        components={{
          Action: (props: any) => {
            if (props.action.icon === 'CREATE') {
              return (
                <Button
                  sx={{ marginLeft: 2 }}
                  onClick={props.action.onClick}
                  color="primary"
                  variant="contained"
                  startIcon={<AddIcon />}
                  className="e2e__addorganizationbutton">
                  Add Organisation
                </Button>
              );
            }
            return (
              <MTableAction
                {...props}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  props.onClick();
                }}
              />
            );
          },
          // eslint-disable-next-line react/display-name
          OverlayLoading: () => {
            return <Loading showLoading />;
          },
          // eslint-disable-next-line react/display-name
          EditField: (props: any) => {
            if (props.columnDef.field === 'address') {
              return (
                <div style={{ width: 150 }}>
                  <TextField
                    required
                    label="Address"
                    value={props.value.address}
                    multiline
                    rows={4}
                    className={classes.addressEditField}
                    onChange={(e) => props.onChange({ ...props.value, address: e.target.value })}
                  />
                  <TextField
                    required
                    label="Postcode"
                    value={props.value.postcode}
                    className={classes.addressEditField}
                    onChange={(e) => props.onChange({ ...props.value, postcode: e.target.value })}
                  />
                </div>
              );
            } else if (
              props.columnDef.field === 'checkupConfig' ||
              props.columnDef.field === 'metadata' ||
              props.columnDef.field === 'kits'
            ) {
              return (
                <TextField
                  required
                  multiline
                  style={{ width: 350 }}
                  value={
                    _.isObject(props.value)
                      ? JSON.stringify(props.value, null, 2)
                      : props.value || '{}'
                  }
                  onChange={(e) => {
                    try {
                      JSON.parse(e.target.value);
                      cellEditableError = null;
                    } catch (err) {
                      cellEditableError =
                        err instanceof Error
                          ? err.message
                          : typeof err === 'string'
                          ? err
                          : 'Invalid JSON';
                    }
                    return props.onChange(e.target.value);
                  }}
                  error={cellEditableError !== null}
                  helperText={cellEditableError || 'Data must be in valid JSON format'}
                />
              );
            }
            return <MTableEditField {...props} />;
          },
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div className={classes.showDeletedSwitch}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showDeleted}
                      onChange={() => {
                        setShowDeleted(!showDeleted);
                      }}
                      color="secondary"
                    />
                  }
                  label="Show deleted?"
                />
              </div>
            </div>
          ),
        }}
      />
    </div>
  );
}
