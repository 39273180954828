import React, { useEffect } from 'react';

import { useWindowVisible } from '@/hooks/useWindowVisible';
import { toast } from 'sonner';
import { useInterval } from '@/hooks/useInterval';

const VERSION_CHECK_INTERVAL = 1000 * 60 * 1; // 1 minutes

/** git hash for the active Portal session */
const currentGitHash = process.env.REACT_APP_GIT_HASH;

/**
 * A banner that displays when a new version of the portal is available.
 *
 * We periodically check for a new version within the version.json in the root of the portal and compare it to the current version.
 * If a new version is available, we display some toast to the user with an action to refresh the page.
 */
export function VersionUpdateBanner() {
  const visible = useWindowVisible();
  const [latestHash, setLatestHash] = React.useState<string | null>();

  const checkForUpdate = async () => {
    const version = await fetchVersion();
    if (version) {
      setLatestHash(version.gitHash);
    }
  };

  // Check for updates whenever the window is visible
  useEffect(() => {
    if (visible) {
      checkForUpdate();
    }
  }, [visible]);

  // Poll for version updates whilst the window is visible
  useInterval(() => checkForUpdate(), visible ? VERSION_CHECK_INTERVAL : null);

  useEffect(() => {
    // If there is no latest version or the latest version is the same as the current version, do nothing
    // 'development' is the default version when running locally
    if (!latestHash || latestHash === currentGitHash || latestHash === 'development') {
      return;
    }

    toast.warning('A new version of the Feebris Portal is available', {
      description: 'Click Update to get the latest version (any pending changes may be lost)',
      id: 'new-version',
      duration: Infinity,
      dismissible: false,
      style: {
        width: '100vw',
        maxWidth: '600px',
        justifyContent: 'space-between',
      },
      actionButtonStyle: {
        color: 'inherit',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'currentColor',
        backgroundColor: 'transparent',
        marginLeft: '1rem',
      },
      action: {
        label: 'Update',
        // Hard refresh the page to get the latest version
        onClick: () => window.location.reload(),
      },
    });
  }, [latestHash]);

  return null;
}

/** Fetch the version.json _without_ caching (we want to make sure we always get the latest) */
async function fetchVersion(): Promise<{
  version: string;
  generatedAt: string;
  gitHash: string;
} | null> {
  try {
    const response = await fetch('/version.json', { cache: 'no-store' });
    return await response.json();
  } catch (error) {
    console.error('Failed to fetch version.json', error);
    return null;
  }
}
