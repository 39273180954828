import React from 'react';

import { useConfirm } from 'material-ui-confirm';
import { gql } from '@apollo/client';
import {
  IntegrationDisplayFragmentInternal,
  useDeleteEhrIntegrationMutationInternal,
} from '@/generated/graphql-internal';
import { Alert } from '@mui/material';

export const DELETE_EHR_INTEGRATION_MUTATION = gql`
  mutation DeleteEhrIntegration($ehrIntegrationId: ID!) {
    deleteEhrIntegration(ehrIntegrationId: $ehrIntegrationId)
  }
`;

interface UseDeleteIntegrationProps {
  onDeleted: () => void;
}

export function useDeleteIntegrationModal({ onDeleted }: UseDeleteIntegrationProps) {
  const [deleteEhrIntegration] = useDeleteEhrIntegrationMutationInternal({
    onError: () => undefined,
    onCompleted: () => {
      onDeleted();
    },
  });

  const confirm = useConfirm();

  return {
    showModal: async ({ id, name }: IntegrationDisplayFragmentInternal) => {
      try {
        await confirm({
          title: `Confirm Deletion of '${name}'`,
          content: (
            <Alert severity="warning">
              {`Deleting '${name}' will cause the integration to stop working. Are you sure?`}
            </Alert>
          ),
          confirmationButtonProps: {
            variant: 'contained',
            color: 'error',
          },
          confirmationText: 'Delete',
        });
        await deleteEhrIntegration({
          variables: {
            ehrIntegrationId: id,
          },
        });
      } catch {
        // material-ui-confirm when the user cancels the dialog
      }
    },
  };
}
