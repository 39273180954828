import React from 'react';

import { Autocomplete } from '@mui/material';
import { PatientPracticeAssignmentFragment } from '@/generated/graphql';
import _ from 'lodash';
import { Checkbox, Typography, TextField, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';
import AddOwnGPMenu from '../AddOwnGPMenu';
import { gql } from '@apollo/client';

export const PatientPracticeAssignmentFragmentDef = gql`
  fragment PatientPracticeAssignment on Organization {
    id
    name
    # FIXME: There is unexpected magic here. We are trying very hard to always fetch practice
    #        objects with the same shape (id, name, address) so that the <Autocomplete>
    #        component can diff and match them correctly
    address {
      address
      postcode
    }
  }
`;

export interface OrganizationSelectorProps {
  isLoadingOrganizations: boolean;
  organizations: PatientPracticeAssignmentFragment[];
  assignedPracticesValue: PatientPracticeAssignmentFragment[];
  assignedPractices: Record<string, PatientPracticeAssignmentFragment[]>;
  onAssignedPracticesChange: (
    newValue: Record<string, PatientPracticeAssignmentFragment[]>,
  ) => void;
  setAddPracticeOpen: (open: boolean) => void;
}

export function OrganizationSelector({
  isLoadingOrganizations,
  organizations,
  assignedPractices,
  assignedPracticesValue,
  onAssignedPracticesChange,
  setAddPracticeOpen,
}: OrganizationSelectorProps) {
  const classes = useStyles();

  return (
    <Autocomplete
      size="small"
      multiple
      disableCloseOnSelect
      className="e2e__gpassignautocomplete"
      loading={isLoadingOrganizations}
      loadingText="Loading..."
      options={organizations}
      value={assignedPracticesValue}
      isOptionEqualToValue={(option, value) => _.isEqual(option, value)}
      getOptionLabel={(option) => option.name}
      onChange={(_event, _newValue, reason, details) => {
        // FIXME: Refactor is getting desperate here. Need to DRY this out into a
        //        data manager.
        let newAssignedPractices;
        switch (reason) {
          case 'selectOption':
            if (!details) {
              return;
            }
            newAssignedPractices = _.fromPairs(
              Object.entries(assignedPractices).map(([patientId, practices]) => {
                const newPractices = _.uniqBy(practices.concat([details.option]), (p) => p.id);
                return [patientId, newPractices];
              }),
            );
            break;
          case 'removeOption':
            if (!details) {
              return;
            }
            newAssignedPractices = _.fromPairs(
              Object.entries(assignedPractices).map(([patientId, practices]) => {
                const newPractices = practices.filter((p) => p.id !== details.option.id);
                return [patientId, newPractices];
              }),
            );
            break;
          case 'clear':
            newAssignedPractices = _.fromPairs(
              Object.entries(assignedPractices).map(([patientId]) => {
                return [patientId, []];
              }),
            );
            break;
          default:
            throw new Error(`Unhandled autocomplete change reason ${reason}`);
        }
        onAssignedPracticesChange(newAssignedPractices);
      }}
      renderOption={(props, option, { selected }) => {
        const allRowsHaveOptionSelected = Object.values(assignedPractices).every((practices) =>
          // Grr, we actually want to use _.includes here but that doesn't play
          // nice with objects see: https://stackoverflow.com/a/25171174/806988
          _.some(practices, option),
        );
        const indeterminate = selected && !allRowsHaveOptionSelected;
        return (
          <li {...props}>
            <Checkbox
              size="small"
              style={{ marginRight: 8 }}
              checked={selected}
              indeterminate={indeterminate}
            />
            <div className={classes.assignPracticeOption}>
              <Typography>{option.name}</Typography>
              <Typography variant="body2">
                {option.address.address} {option.address.postcode}
              </Typography>
            </div>
          </li>
        );
      }}
      style={{ width: 340 }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ 'aria-label': `Assign ${t('GP Practice')}s`, ...params.inputProps }}
          variant="standard"
          placeholder={`Assign ${t('GP Practice')}s`}
        />
      )}
      noOptionsText={<AddOwnGPMenu setAddPracticeOpen={setAddPracticeOpen} />}
      ListboxComponent={(props) => {
        const { ...ulProps } = props;
        return (
          <div>
            <ul {...ulProps}>{ulProps.children}</ul>
            <Divider />
            <AddOwnGPMenu setAddPracticeOpen={setAddPracticeOpen} />
          </div>
        );
      }}
    />
  );
}

const useStyles = makeStyles(() => ({
  assignPracticeOption: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
