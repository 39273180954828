import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import {
  PatientPracticeAssignmentFragment,
  useGetPracticesForPatientAssignmentQuery,
} from '@/generated/graphql';

import auth from '@/controllers/Auth';
import { GraphQLOrganization } from '@/controllers/types';

import { OrganizationSelector } from './OrganizationSelector';
import AddPracticeDialog from './AddPracticeDialog';

export enum AssignmentType {
  Practice = 'Practice',
}

export const QUERY_PRACTICES = gql`
  query GetPracticesForPatientAssignment {
    practices {
      ...PatientPracticeAssignment
    }
  }
`;

interface PatientAssignmentToolbarProps {
  assignedPractices: Record<string, PatientPracticeAssignmentFragment[]>;
  setAssignedPractices: (
    assignedPractices: Record<string, PatientPracticeAssignmentFragment[]>,
  ) => void;
  setAssignPracticeAlertOpen: (open: boolean) => void;
  onCancel: () => void;
  assignmentType: AssignmentType | null;
  onAssignmentTypeChange: (assignmentType: AssignmentType | null) => void;
}

export function PatientAssignmentToolbar({
  assignedPractices,
  setAssignedPractices,
  setAssignPracticeAlertOpen,
  onCancel,
  assignmentType,
  onAssignmentTypeChange,
}: PatientAssignmentToolbarProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleAssignmentTypeChange = (assignmentType: AssignmentType) => {
    onAssignmentTypeChange(assignmentType);
  };

  const handleSave = () => {
    if (assignmentType === AssignmentType.Practice) {
      setAssignPracticeAlertOpen(true);
    }
  };

  const {
    data: allPracticesResponse,
    refetch: reloadAllPractices,
    loading: isLoadingOrganizations,
  } = useGetPracticesForPatientAssignmentQuery({
    onError: () => {
      toast.error('Failed to load practices', {
        action: {
          label: 'Retry',
          onClick: () => reloadAllPractices(),
        },
      });
    },
  });
  const allPractices = allPracticesResponse?.practices ?? [];

  const assignedPracticesValue = _.uniqBy(_.flatten(Object.values(assignedPractices)), (p) => p.id);

  const [addPracticeOpen, setAddPracticeOpen] = useState(false);

  const canSave = useMemo(() => {
    if (assignmentType === AssignmentType.Practice) {
      return assignedPracticesValue.length > 0;
    }
    return false;
  }, [assignmentType, assignedPracticesValue]);

  return (
    <>
      <div className={classes.actions}>
        {assignmentType === AssignmentType.Practice && (
          <OrganizationSelector
            isLoadingOrganizations={isLoadingOrganizations}
            assignedPractices={assignedPractices}
            onAssignedPracticesChange={setAssignedPractices}
            organizations={allPractices}
            setAddPracticeOpen={setAddPracticeOpen}
            assignedPracticesValue={assignedPracticesValue}
          />
        )}
        {!assignmentType && (
          <>
            {auth.me('permissions.assign_patients') && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleAssignmentTypeChange(AssignmentType.Practice)}>
                Assign {t('GP Practice')}
              </Button>
            )}
          </>
        )}
        <Button
          variant="contained"
          onClick={() => {
            onCancel();
          }}>
          Cancel
        </Button>
        {assignmentType && (
          <Button
            disabled={!canSave}
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
            className="e2e__gpassignsave">
            Save
          </Button>
        )}
      </div>
      <AddPracticeDialog
        open={addPracticeOpen}
        onClose={() => setAddPracticeOpen(false)}
        onCreatePractice={(newCreatedPractice: GraphQLOrganization) => {
          reloadAllPractices();
          setAddPracticeOpen(false);
          // FIXME: Refactor is getting desperate here. Need to DRY this out into a data manager.
          const newAssignedPractices = _.fromPairs(
            Object.entries(assignedPractices).map(([patientId, practices]) => {
              const newPractices = _.uniqBy(practices.concat([newCreatedPractice]), (p) => p.id);
              return [patientId, newPractices];
            }),
          );
          setAssignedPractices(newAssignedPractices);
        }}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  actions: {
    // eslint-disable-next-line quote-props
    display: 'flex',
    // eslint-disable-next-line quote-props
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  addPatientButton: {
    height: '100%',
    alignSelf: 'center',
  },
}));
