import React from 'react';
import _ from 'lodash';
import { PatientCheckupsTableItemFragment } from '@/generated/graphql';
import { Confidence, VitalsPopover, VitalValue } from '@/components/VitalsPopover';
import { Box } from '@mui/material';
import { VitalReadingStatusIndicator } from './VitalReadingStatusIndicator';

interface CheckupSummaryCellProps {
  checkup: PatientCheckupsTableItemFragment | null | undefined;
  confidence: Confidence;
  isManualOverride: boolean;
  hasEwsScore: boolean;
  vitalName: string;
  value: VitalValue;
  score: number | null;
  popoverFields: { label: string; value: string }[];
  hasValue: boolean;
}

export const CheckupSummaryCell: React.FC<CheckupSummaryCellProps> = ({
  checkup,
  confidence,
  isManualOverride,
  hasEwsScore,
  vitalName,
  value,
  score,
  popoverFields,
  hasValue,
}) => {
  return (
    <>
      {checkup && (
        <VitalsPopover
          vitalName={vitalName}
          value={value}
          score={score}
          popoverFields={popoverFields}
          confidence={confidence}
          isManualOverride={isManualOverride}
          hasValue={hasValue}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
              padding: '8px 12px',
            }}>
            {confidence && (
              <VitalReadingStatusIndicator
                confidence={confidence.value}
                hasEwsScore={hasEwsScore}
              />
            )}
            {isManualOverride && (
              <VitalReadingStatusIndicator
                isManualOverride={isManualOverride}
                hasEwsScore={hasEwsScore}
              />
            )}
            {checkup?.pulseOxiData?.averageSpO2}
          </Box>
        </VitalsPopover>
      )}
    </>
  );
};
