import React from 'react';
import { Tooltip, defaultStyles as defaultTooltipStyles } from '@visx/tooltip';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';

interface EcgTraceTooltipProps {
  tooltipOpen: boolean;
  tooltipLeft: number;
  tooltipTop: number;
  tooltipData: string | undefined;
}

const POSITION_INDICATOR_SIZE = 8;

export const EcgTraceTooltip = ({
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
}: EcgTraceTooltipProps) => {
  const classes = useStyles();
  const { palette } = useTheme();

  if (!tooltipOpen) {
    return null;
  }

  return (
    <>
      <div
        className={classes.positionIndicator}
        style={{
          left: tooltipLeft - POSITION_INDICATOR_SIZE / 2,
          top: tooltipTop - POSITION_INDICATOR_SIZE / 2,
        }}
      />
      <div
        className={classes.crosshairHorizontal}
        style={{ top: tooltipTop, left: 0, width: '100%' }}
      />
      <div
        className={classes.crosshairVertical}
        style={{ top: 0, left: tooltipLeft, height: '100%' }}
      />
      <Tooltip
        left={tooltipLeft}
        top={tooltipTop}
        style={{
          ...defaultTooltipStyles,
          width: 160,
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 8,
          backgroundColor: palette.primary.main,
          color: 'white',
          fontWeight: 'bold',
        }}>
        {tooltipData}
      </Tooltip>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  positionIndicator: {
    position: 'absolute',
    width: POSITION_INDICATOR_SIZE,
    height: POSITION_INDICATOR_SIZE,
    borderRadius: theme.shape.borderRadius * 2,
    background: theme.palette.primary.main,
  },
  crosshairHorizontal: {
    position: 'absolute',
    width: '100%',
    height: 1,
    borderTop: `1px dashed ${theme.palette.primary.main}`,
    pointerEvents: 'none', // avoid element intercepting tooltip positioning
  },
  crosshairVertical: {
    position: 'absolute',
    height: '100%',
    width: 1,
    borderLeft: `1px dashed ${theme.palette.primary.main}`,
    pointerEvents: 'none', // avoid element intercepting tooltip positioning
  },
}));
