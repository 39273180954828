import { useJourneyModal } from '@/components/JourneyModal';

import { AdmitPatientJourneyContext, AdmitPatientJourneyStep } from './types';
import { SearchPatientStep } from './steps/SearchPatientStep';
import { ConfirmOrCreatePatientStep } from './steps/ConfirmOrCreatePatientStep';
import { SetupLoginStep } from './steps/SetupSelfcareStep';
import { AdmissionDetailsStep } from './steps/AdmissionDetailsStep';
import { SuccessStep } from './steps/SuccessStep';
import { useMeActingOrganizationFeature } from '@/hooks/useAuth';
import { isDefined } from '@/helpers/isDefined';
import {
  formatNationalIdentifier,
  nationalIdentifierTypeToLabel,
} from '@/components/NationalIdentifierDisplay';
import { ConfirmAdmissionStep } from './steps/ConfirmAdmissionStep';
import { AddContactDetailsStep } from './steps/AddContactDetailsStep';

const initialJourneyState: AdmitPatientJourneyContext = {
  patient: null,
  patientSearchForm: null,
  contactUpdated: false,
  patientCreated: false,
  initializedWithPatient: false,
  selfCareLoginCreated: false,
  pendingWardAdmission: null,
};

const shouldPromptForContactDetails = ({
  pendingWardAdmission: wardAdmission,
  patientCreated,
  patient,
}: AdmitPatientJourneyContext) => {
  const patientCreatedWithContactDetails = patientCreated && patient?.address && patient?.telephone;

  return Boolean(wardAdmission?.isSelfCare && !patientCreatedWithContactDetails);
};

interface AdmitPatientJourneyProps {
  showPatientRecordLink: boolean;
  onClose?: () => void;
}

export function usePatientAdmitJourney({
  showPatientRecordLink,
  onClose,
}: AdmitPatientJourneyProps) {
  const hasSelfCareFeature = useMeActingOrganizationFeature('selfCare');
  const nationalIdentifierScheme = useMeActingOrganizationFeature('nationalIdentifierScheme');

  const nationalIdentifierDisplay = (patient: AdmitPatientJourneyContext['patient']) => {
    if (!nationalIdentifierScheme) {
      return '';
    }

    const formattedNationalIdentifier = patient?.nationalIdentifier
      ? formatNationalIdentifier(patient?.nationalIdentifier)
      : `No ${nationalIdentifierTypeToLabel(nationalIdentifierScheme)}`;

    return `(${formattedNationalIdentifier})`;
  };

  return useJourneyModal<AdmitPatientJourneyContext, AdmitPatientJourneyStep>({
    journeyName: 'AdmitPatient',
    title: ({ patient }) =>
      patient
        ? `Admit ${patient.firstName} ${patient.lastName} ${nationalIdentifierDisplay(patient)}`
        : 'Admit patient',
    cancelConfirmationProps: {
      title: 'Cancel patient admission?',
      description:
        "Are you sure you want to cancel this admission? Any changes you have made to the patient's details and login credentials are saved, but admission details will be lost. If you want to admit the patient, you will need to restart this process.",
    },
    steps: {
      search: {
        component: SearchPatientStep,
        stepLabel: 'Patient Search',
        stepHeading: 'Search for patient record',
        nextButtonLabel: 'Search',
        closeWithoutWarning: true,
        controlsForm: 'patientSearchForm',
        skip: ({ initializedWithPatient }) => initializedWithPatient,
        hidden: ({ initializedWithPatient }) => initializedWithPatient,
      },
      confirmOrCreatePatient: {
        component: ConfirmOrCreatePatientStep,
        canGoBack: ({ patientCreated }) => !patientCreated,
        stepLabel: 'Patient Details',
        subLabel: ({ patientCreated }) => (patientCreated ? 'Created' : undefined),
        nextButtonLabel: ({ patient }) =>
          isDefined(patient?.wardAdmission) ? 'Close' : 'Continue',
        backButtonLabel: 'Back to search',
        controlsForm: 'createPatientForm',
        closeWithoutWarning: true,
        skip: ({ initializedWithPatient }) => initializedWithPatient,
        hidden: ({ initializedWithPatient }) => initializedWithPatient,
      },
      admissionDetails: {
        component: AdmissionDetailsStep,
        canGoBack: (state) => !state.patientCreated,
        stepLabel: 'Admission',
        stepHeading: 'Ward and Care Pathway',
        controlsForm: 'wardAdmissionForm',
      },
      addContactDetails: {
        component: AddContactDetailsStep,
        canGoBack: true,
        skip: (state) => !hasSelfCareFeature || !shouldPromptForContactDetails(state),
        hidden: (state) => !hasSelfCareFeature || !shouldPromptForContactDetails(state),
        controlsForm: 'contactDetailsForm',
        stepLabel: 'Contact Info',
        subLabel: ({ contactUpdated }) => (contactUpdated ? 'Updated' : undefined),
        stepHeading: 'Update contact details',
      },
      setupSelfCare: {
        component: SetupLoginStep,
        canGoBack: true,
        skip: ({ pendingWardAdmission: wardAdmission }) =>
          !hasSelfCareFeature || !wardAdmission?.isSelfCare,
        hidden: ({ pendingWardAdmission: wardAdmission }) =>
          !hasSelfCareFeature || !wardAdmission?.isSelfCare,
        controlsForm: 'selfCareLoginForm',
        stepLabel: 'Login Credentials',
        subLabel: ({ selfCareLoginCreated }) => (selfCareLoginCreated ? 'Set up' : undefined),
        stepHeading: 'Setup login credentials',
      },
      confirm: {
        component: ConfirmAdmissionStep,
        canGoBack: true,
        stepLabel: 'Confirmation',
        stepHeading: 'Confirm patient admission',
        nextButtonLabel: 'Confirm',
      },
      success: {
        component: SuccessStep,
        componentProps: { showPatientRecordLink },
        stepLabel: 'Success',
        hidden: 'always',
        nextButtonLabel: 'Close',
        nextButtonProps: {
          variant: 'text',
        },
        closeWithoutWarning: true,
      },
    },
    defaultJourneyState: { ...initialJourneyState },
    onClose,
  });
}
