import React from 'react';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import LyingIcon from '@/components/svgs/Icon_Lying_Down.svg?react';
import SittingIcon from '@/components/svgs/Icon_Sitting.svg?react';
import StandingIcon from '@/components/svgs/Icon_Standing.svg?react';
import { CheckupDetailsFragment, EwsScores } from '@/generated/graphql';
import { feebrisFormatter } from '@/helpers/LocaleFormatting';
import '@/styles/components/blocks/block-readings.scss';

import DisabledRiskLevelIcon from './components/DisabledRiskLevelIcon';

interface BlockReadingsProps {
  checkup: CheckupDetailsFragment;
}

function BlockReadings({ checkup }: BlockReadingsProps) {
  const hasScorableObservations =
    Boolean(checkup.pulseRate?.value) ||
    Boolean(checkup.pulseOxiData?.averageSpO2) ||
    Boolean(checkup.respiratoryRate?.value) ||
    Boolean(checkup.consciousness) ||
    Boolean(checkup.bloodPressureData?.systolic) ||
    Boolean(checkup.temperature);

  // TODO: Cleanup the CSS block--readings.news needs to become the default
  return (
    <div className={`block block--readings news`}>
      <div className="block__inner">
        <RiskLevelIcon
          riskLevel={checkup.ewsScores?.riskLevel}
          totalScore={checkup.ewsScores?.totalScore}
        />
        {/* Hide the insight blocks for checkups without scorable observations */}
        {checkup.subtype !== 'soft-signs' && hasScorableObservations && (
          <div className="block--readings__cols">
            <div className="e2e__prtile">
              {checkup.pulseRate?.value ? (
                <TileWithReading
                  title="PR"
                  value={checkup.pulseRate?.value}
                  score={checkup.ewsScores?.HRScore}
                />
              ) : (
                <NotTakenTile title="PR" />
              )}
            </div>
            <div className="e2e__spo2tile">
              {checkup.pulseOxiData?.averageSpO2 ? (
                <TileWithReading
                  title="SPO2"
                  value={checkup.pulseOxiData?.averageSpO2}
                  score={checkup.ewsScores?.SpO2Score}
                />
              ) : (
                <NotTakenTile title="SPO2" />
              )}
            </div>
            <div className="e2e__rrtile">
              {checkup.respiratoryRate ? (
                <TileWithReading
                  title="RR"
                  value={checkup.respiratoryRate?.value}
                  score={checkup.ewsScores?.RRScore}
                />
              ) : (
                <NotTakenTile title="RR" />
              )}
            </div>
            <div className="e2e__constile">
              {checkup.consciousness ? (
                <TileWithReading
                  title="Consciousness"
                  value={checkup.consciousness}
                  score={checkup.ewsScores?.consciousnessScore}
                />
              ) : (
                <NotTakenTile title="Consciousness" />
              )}
            </div>
            <div className="e2e__bptile">
              {checkup.bloodPressureData?.systolic ? (
                <TileWithReading
                  title="BP"
                  value={`${checkup.bloodPressureData?.systolic}/${checkup.bloodPressureData?.diastolic}`}
                  score={checkup.ewsScores?.BPScore}
                  icon={<PositionIcon position={checkup.bloodPressureData?.position} />}
                />
              ) : (
                <NotTakenTile title="BP" />
              )}
            </div>
            <div className="e2e__temptile">
              {checkup.temperature ? (
                <TileWithReading
                  title="Temperature"
                  value={feebrisFormatter.formatTemperatureWithUnits(checkup.temperature)}
                  score={checkup.ewsScores?.tempScore}
                />
              ) : (
                <NotTakenTile title="Temperature" />
              )}
            </div>
            <div></div>
            <div className="e2e__onoxygentile">
              {checkup.ewsScores?.onOxygenScore !== undefined && (
                <TileWithReading
                  title="On Oxygen"
                  value={checkup.ewsScores?.onOxygenScore === 2 ? 'Yes' : 'No'}
                  score={checkup.ewsScores?.onOxygenScore}
                />
              )}
            </div>
            <div></div>
          </div>
        )}
      </div>
    </div>
  );
}

function RiskLevelIcon({
  riskLevel,
  totalScore,
}: {
  riskLevel: EwsScores['riskLevel'];
  totalScore: EwsScores['totalScore'];
}) {
  if (!riskLevel) {
    return (
      <div className={`block--readings__risk block--readings__risk`}>
        <div className="readingsOnlyContainer">
          <div className="noRiskLabel">NO RISK LEVEL</div>
          <div className="label">No Total Score</div>
        </div>
        <DisabledRiskLevelIcon />
      </div>
    );
  } else {
    return (
      <div className={`block--readings__risk block--readings__risk--risk${riskLevel}`}>
        {riskLevel === 5 && <div className="label">Emergency Response</div>}
        {riskLevel === 4 && <div className="label">Urgent Response</div>}
        {riskLevel === 3 && <div className="label">GP Attention</div>}
        {riskLevel === 2 && <div className="label">Increase Monitoring</div>}
        {riskLevel === 1 && <div className="label">Routine Care</div>}
        <div className="icon" data-testid="checkupSummaryTotalScore">
          {totalScore}
        </div>
      </div>
    );
  }
}

interface TileWithReadingProps {
  title: string;
  value: number | string;
  score: number | undefined | null;
  icon?: React.ReactNode;
}
function TileWithReading({ title, value, score, icon }: TileWithReadingProps) {
  return (
    <div>
      <p className="title">{title}</p>
      <div className="value__container">
        <>{icon}</>
        <p className="value">{value}</p>
      </div>

      <p className={`news news--score${score}`}>EWS {score}</p>
    </div>
  );
}

function NotTakenTile({ title }: { title: string }) {
  return (
    <div>
      <div className="notTaken">
        <div className="notTakenTitle">{title}</div>
        <div className="notTakenText">
          NOT
          <br />
          TAKEN
        </div>
      </div>
    </div>
  );
}

function PositionIcon({ position }: { position?: 'sitting' | 'standing' | 'lying' }) {
  const { t } = useTranslation();

  if (!position) {
    return null;
  }

  const iconMap = {
    sitting: SittingIcon,
    standing: StandingIcon,
    lying: LyingIcon,
  };

  const Icon = iconMap[position];

  const positionLabel = t(`BLOOD_PRESSURE_POSITION.${position}`);

  return (
    <Tooltip title={positionLabel} PopperProps={{ placement: 'top' }}>
      <Icon data-testid="bpPositionIcon" width={28} height={28} aria-label={positionLabel} />
    </Tooltip>
  );
}

export default BlockReadings;
