import i18n, { ResourceLanguage } from 'i18next';
import * as Cookies from 'js-cookie';
import { initReactI18next } from 'react-i18next';

import enGB from '@/assets/i18n/en-GB.json';
import enUS from '@/assets/i18n/en-US.json';

enum Locale {
  EN_GB = 'en-GB',
  EN_US = 'en-US',
}

const resources: Record<Locale, ResourceLanguage> = {
  [Locale.EN_GB]: enGB,
  [Locale.EN_US]: enUS,
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: Locale.EN_GB,
  lng: Cookies.get('language') ?? process.env.REACT_APP_LANGUAGE,
  interpolation: {
    escapeValue: false, // React already has a mechanism to escape values, to prevent XSS
  },
});

export default i18n;
