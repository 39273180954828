import React from 'react';

import { Box } from '@mui/material';

import Moment from 'moment';
import { useTranslation, TFunction } from 'react-i18next';

import { feebrisFormatter } from '@/helpers/LocaleFormatting';

import {
  VirtualWardPatientCheckupFragment,
  VirtualWardPatientContinuousMonitoringFragment,
  VirtualWardPatientContinuousMonitoringWithSessionFragment,
} from '@/generated/graphql';

import { isDefined } from '@/helpers/isDefined';
import { CheckupValue } from './CheckupValue';
import { Confidence } from '@/components/VitalsPopover';

interface VitalsValuesProps {
  checkup: VirtualWardPatientCheckupFragment | null | undefined;
  continuousMonitoringWithSession:
    | VirtualWardPatientContinuousMonitoringWithSessionFragment
    | null
    | undefined;
  isContinuousMonitoring: boolean;
}

export function VitalsValues({
  checkup,
  continuousMonitoringWithSession,
  isContinuousMonitoring,
}: VitalsValuesProps) {
  const { t } = useTranslation();

  const continuousMonitoring = continuousMonitoringWithSession?.continuousMonitoring;

  const spO2Confidence: Confidence = checkup?.pulseOxiData?.confidence && {
    value: checkup.pulseOxiData.confidence,
    heading: t(`PULSE_OXIMETER_CONFIDENCE_SHORT.${checkup.pulseOxiData.confidence}`),
    description: t(`PULSE_OXIMETER_CONFIDENCE_LONG.${checkup.pulseOxiData.confidence}`),
  };

  return (
    <Box display="flex" flexWrap="wrap" data-testid="vitals" marginY={1}>
      <CheckupValue
        shortLabel="BP"
        longLabel="Blood Pressure"
        width={75}
        value={
          isContinuousMonitoring
            ? null
            : checkup?.bloodPressureData &&
              `${checkup?.bloodPressureData.systolic}/${checkup?.bloodPressureData.diastolic}`
        }
        score={
          isContinuousMonitoring
            ? continuousMonitoring?.thresholdScores?.BPScore
            : checkup?.ewsScores?.BPScore
        }
        popoverFields={
          isContinuousMonitoring
            ? undefined
            : checkupPopoverFields(t, checkup, 'bloodPressureData', [
                checkup?.bloodPressureData
                  ? {
                      label: 'Position',
                      value: checkup?.bloodPressureData?.position
                        ? t(`BLOOD_PRESSURE_POSITION.${checkup?.bloodPressureData?.position}`)
                        : '-',
                    }
                  : undefined,
                checkup?.bloodPressureData?.isManual
                  ? {
                      label: 'Source',
                      value: 'Manual Entry',
                    }
                  : undefined,
              ])
        }
      />
      <CheckupValue
        shortLabel="SpO2"
        longLabel="Oxygen Saturation"
        value={
          isContinuousMonitoring
            ? continuousMonitoring?.spo2?.value
            : checkup?.pulseOxiData?.averageSpO2
        }
        score={
          isContinuousMonitoring
            ? continuousMonitoring?.thresholdScores?.SpO2Score
            : checkup?.ewsScores?.SpO2Score
        }
        popoverFields={
          isContinuousMonitoring
            ? continuousMonitoringPopoverFields(t, continuousMonitoring, 'spo2')
            : checkupPopoverFields(t, checkup, 'pulseOxiData', [
                checkup?.pulseOxiData?.device
                  ? {
                      label: 'Source',
                      value: `${t(`PULSE_OXIMETER_DEVICE.${checkup.pulseOxiData.device}`)}${
                        checkup.pulseOxiData.isManual ? ' (Manual Entry)' : ''
                      }`,
                    }
                  : checkup?.pulseOxiData?.isManual
                  ? { label: 'Source', value: 'Manual Entry' }
                  : undefined,
              ])
        }
        confidence={spO2Confidence}
        isManualOverride={
          checkup?.pulseOxiData?.isManual && isDefined(checkup.pulseOxiData.uneditedAverageSpO2)
        }
      />
      <CheckupValue
        shortLabel="RR"
        longLabel="Respiratory Rate"
        value={
          isContinuousMonitoring
            ? continuousMonitoring?.respiratoryRate?.value
            : checkup?.respiratoryRate?.value
        }
        score={
          isContinuousMonitoring
            ? continuousMonitoring?.thresholdScores?.RRScore
            : checkup?.ewsScores?.RRScore
        }
        popoverFields={
          isContinuousMonitoring
            ? continuousMonitoringPopoverFields(t, continuousMonitoring, 'respiratoryRate')
            : checkupPopoverFields(t, checkup, 'respiratoryRate', [
                checkup?.respiratoryRate?.source
                  ? {
                      label: 'Source',
                      value: t(`RESPIRATORY_RATE_SOURCE.${checkup?.respiratoryRate.source}`),
                    }
                  : undefined,
              ])
        }
      />
      <CheckupValue
        shortLabel="Pulse"
        longLabel="Pulse Rate"
        value={
          isContinuousMonitoring
            ? continuousMonitoring?.pulseRate?.value
            : checkup?.pulseRate?.value
        }
        score={
          isContinuousMonitoring
            ? continuousMonitoring?.thresholdScores?.HRScore
            : checkup?.ewsScores?.HRScore
        }
        popoverFields={
          isContinuousMonitoring
            ? continuousMonitoringPopoverFields(t, continuousMonitoring, 'pulseRate')
            : checkupPopoverFields(t, checkup, 'pulseRate', [
                checkup?.pulseRate?.source && {
                  label: 'Source',
                  value: `${t(`PULSE_RATE_SOURCE.${checkup?.pulseRate.source}`)}${
                    checkup?.pulseRate?.isManual ? ' (Manual Entry)' : ''
                  }`,
                },
              ])
        }
      />
      <CheckupValue
        shortLabel="Temp"
        longLabel="Temperature"
        width={70}
        value={feebrisFormatter.formatTemperature(
          isContinuousMonitoring ? continuousMonitoring?.temperature?.value : checkup?.temperature,
        )}
        score={
          isContinuousMonitoring
            ? continuousMonitoring?.thresholdScores?.tempScore
            : checkup?.ewsScores?.tempScore
        }
        popoverFields={
          isContinuousMonitoring
            ? continuousMonitoringPopoverFields(t, continuousMonitoring, 'temperature', [
                {
                  label: 'Units',
                  value: feebrisFormatter.temperatureUnits(),
                },
              ])
            : checkupPopoverFields(t, checkup, 'temperature', [
                {
                  label: 'Units',
                  value: feebrisFormatter.temperatureUnits(),
                },
              ])
        }
      />
      <CheckupValue
        shortLabel="LOC"
        longLabel="Level of Consciousness"
        value={isContinuousMonitoring ? null : checkup?.consciousness}
        longValue={
          isContinuousMonitoring
            ? null
            : checkup?.consciousness &&
              `${t(`CONSCIOUSNESS_SHORT.${checkup?.consciousness}`)} - ${t(
                `CONSCIOUSNESS_LONG.${checkup?.consciousness}`,
              )}`
        }
        score={
          isContinuousMonitoring
            ? continuousMonitoring?.thresholdScores?.consciousnessScore
            : checkup?.ewsScores?.consciousnessScore
        }
        popoverFields={
          isContinuousMonitoring ? undefined : checkupPopoverFields(t, checkup, 'consciousness')
        }
      />
      <CheckupValue
        shortLabel="Oxygen"
        longLabel="On Oxygen"
        value={
          checkup?.ewsScores?.onOxygenScore !== undefined
            ? checkup?.ewsScores?.onOxygenScore === 2
              ? 'Yes'
              : 'No'
            : null
        }
        score={checkup?.ewsScores?.onOxygenScore}
      />
    </Box>
  );
}

function continuousMonitoringPopoverFields(
  t: TFunction,
  continuousMonitoring: VirtualWardPatientContinuousMonitoringFragment | null | undefined,
  continuousMonitoringKey: 'spo2' | 'respiratoryRate' | 'heartRate' | 'pulseRate' | 'temperature',
  extraFields: ({ label: string; value: string } | undefined)[] = [],
): { label: string; value: string }[] {
  const label = {
    spo2: 'SPO2',
    respiratoryRate: 'RR',
    heartRate: 'HR',
    pulseRate: 'Pulse',
    temperature: 'Temp',
  }[continuousMonitoringKey];
  return continuousMonitoring?.[continuousMonitoringKey]
    ? [
        ...extraFields,
        {
          label: t(`Min ${label}`),
          value: `${continuousMonitoring?.[continuousMonitoringKey]?.min ?? ''}`,
        },
        {
          label: t(`Max ${label}`),
          value: `${continuousMonitoring?.[continuousMonitoringKey]?.max ?? ''}`,
        },
        {
          label: t(`Median ${label}`),
          value: `${continuousMonitoring?.[continuousMonitoringKey]?.median ?? ''}`,
        },
        {
          label: t('Captured on'),
          value: Moment(continuousMonitoring?.bucketEndAt).format('MMM Do YYYY, h:mm a'),
        },
      ].filter(isDefined)
    : [];
}

function checkupPopoverFields(
  t: TFunction,
  checkup: VirtualWardPatientCheckupFragment | null | undefined,
  checkupKey:
    | 'bloodPressureData'
    | 'pulseOxiData'
    | 'respiratoryRate'
    | 'pulseRate'
    | 'temperature'
    | 'consciousness',
  extraFields: ({ label: string; value: string } | undefined)[] = [],
): { label: string; value: string }[] {
  return [
    ...extraFields,
    checkup?.[checkupKey]
      ? {
          label: t('Captured on'),
          value: Moment(checkup?.endedAt).format('MMM Do YYYY, h:mm a'),
        }
      : undefined,
  ].filter(isDefined);
}
