import React, { useEffect } from 'react';

import { toast } from 'sonner';
import { Stack, Typography } from '@mui/material';
import { gql } from '@apollo/client';

import { PatientFlagType, useAdmitPatientMutation } from '@/generated/graphql';

import { useAdmitPatientJourneyContext } from '../types';
import { useMeActingOrganizationFeature } from '@/hooks/useAuth';
import {
  formatNationalIdentifier,
  nationalIdentifierTypeToLabel,
} from '@/components/NationalIdentifierDisplay';

export const MUTATION_ADMIT_PATIENT = gql`
  mutation AdmitPatient(
    $patientId: ID!
    $wardId: ID!
    $carePathwayId: ID!
    $patientFlags: [PatientFlagInput!] = []
  ) {
    admitPatientToWard(patientId: $patientId, wardId: $wardId, carePathwayId: $carePathwayId) {
      patient {
        id
        wardAdmission {
          ...PatientAdmissionOverview
        }
      }
      admittedAt
    }

    updatePatientFlags(patientFlagUpdate: { PatientId: $patientId, patientFlags: $patientFlags }) {
      id
      wardAdmission {
        ...PatientAdmissionOverview
      }
    }
  }
`;

export function ConfirmAdmissionStep() {
  const {
    currentJourneyState: { patient, pendingWardAdmission: wardAdmission },
    setIsSubmitting,
    setActiveStep,
    handleStep,
  } = useAdmitPatientJourneyContext();

  const [admitPatient, { loading: isAdmitting }] = useAdmitPatientMutation({
    variables: {
      patientId: patient?.id ?? '',
      wardId: wardAdmission?.wardId ?? '',
      carePathwayId: wardAdmission?.carePathwayId ?? '',
      patientFlags: wardAdmission?.patientFlags ?? [],
    },
    onCompleted: () => {
      setActiveStep('success');
    },
    onError: () => toast.error('An error occurred when admitting the patient to the ward'),
  });

  handleStep(async () => {
    await admitPatient();

    return false;
  });

  // Sync the mutation submitting state with the journey state
  useEffect(() => {
    setIsSubmitting(isAdmitting);
  }, [isAdmitting, setIsSubmitting]);

  const nationalIdentifierScheme = useMeActingOrganizationFeature('nationalIdentifierScheme', null);

  const nationalIdentifierText = nationalIdentifierScheme
    ? ` (${nationalIdentifierTypeToLabel(nationalIdentifierScheme)}: ${
        formatNationalIdentifier(patient?.nationalIdentifier) ?? 'Unknown'
      })`
    : '';

  return (
    <div>
      <Typography variant="body2" color="textSecondary">
        Please confirm the details below. Once the patient has been admitted, to change their ward
        or care pathway, they will need to be discharged and admitted again.
      </Typography>

      <Stack spacing={0.5} marginTop={2}>
        <Typography>
          <strong>Patient: </strong> {patient?.firstName} {patient?.lastName}
          {nationalIdentifierText}
        </Typography>
        <Typography>
          <strong>Ward: </strong> {wardAdmission?.wardName}
        </Typography>
        <Typography>
          <strong>Care Pathway: </strong> {wardAdmission?.carePathwayName}
        </Typography>
        <Typography>
          <strong>On Oxygen: </strong>{' '}
          {wardAdmission?.patientFlags?.some((flag) => flag.type === PatientFlagType.OnOxygen)
            ? 'Yes'
            : 'No'}
        </Typography>
        <Typography>
          <strong>Effective: </strong> Immediately
        </Typography>
      </Stack>
    </div>
  );
}
