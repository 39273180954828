import React, { useMemo, useState } from 'react';
import { PatientAcuityDisplayFragment } from '@/generated/graphql';
import { gql } from '@apollo/client';
import { Box, Popover, Stack, SxProps, Theme, Typography } from '@mui/material';
import { NEWS_SCORE_PALETTE } from '@/styles/NEWSColors';
import { isDefined } from '@/helpers/isDefined';
import { formatUserName } from '@/helpers/formatUserName';
import { useTranslation } from 'react-i18next';
import { useEvent } from 'react-use';
import { differenceInDays, formatDistance, isToday } from 'date-fns';

export const PATIENT_ACUITY_DISPLAY_FRAGMENT = gql`
  fragment PatientAcuityDisplay on PatientAcuityScore {
    score
    reason
    createdAt
    createdBy {
      ...FormattableUser
    }
  }
`;

interface PatientAcuityDisplayProps {
  acuityScore: Maybe<PatientAcuityDisplayFragment>;
  /** MUI system prop, which allows defining system overrides as well as additional CSS styles. */
  sx?: SxProps<Theme>;
  variant?: 'standard' | 'small';
}

export function PatientAcuityDisplay({
  acuityScore,
  sx: sxFromProps,
  variant = 'standard',
}: PatientAcuityDisplayProps) {
  const score = acuityScore?.score;

  const styles = useMemo(() => getPaletteForAcuityScore(score), [score]);

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // Keep track of whether the anchor was clicked to prevent the popover from closing when the mouse leaves the anchor
  const [anchorClicked, setAnchorClicked] = useState(false);

  // Close the popover when clicking outside of it
  useEvent('click', (e) => {
    if (anchorEl && !anchorEl.contains(e.target as Node)) {
      setAnchorEl(null);
      setAnchorClicked(false);
    }
  });

  const distanceText = useMemo(() => {
    if (!acuityScore) return undefined;
    if (isToday(new Date(acuityScore.createdAt))) return 'today';

    const wasSetInLast7Days = differenceInDays(new Date(), new Date(acuityScore.createdAt)) <= 7;

    if (wasSetInLast7Days) {
      return formatDistance(new Date(acuityScore.createdAt), new Date(), { addSuffix: true });
    }
    return undefined;
  }, [acuityScore]);

  return (
    <>
      <Stack
        tabIndex={0}
        border={1}
        onClick={() => setAnchorClicked(true)}
        onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
        onMouseLeave={() => !anchorClicked && setAnchorEl(null)}
        onFocus={(e) => setAnchorEl(e.currentTarget)}
        onBlur={() => setAnchorEl(null)}
        role="button"
        aria-label="Show acuity score details"
        aria-expanded={isDefined(anchorEl)}
        sx={{
          backgroundColor: 'grey.200',
          cursor: 'default',
          transition: 'opacity 0.2s',
          '&:hover': { opacity: 0.8 },
          ...styles,
          ...sxFromProps,
        }}
        alignSelf="flex-start"
        direction={variant === 'small' ? 'column' : 'row'}
        paddingX={1.5}
        paddingY={0.5}
        paddingRight={variant === 'small' ? 1.5 : 0.5}
        gap={variant === 'small' ? 0 : 1}
        alignItems="center"
        borderRadius={3}
        borderColor={(t) => t.palette.grey[400]}>
        <Typography variant="body2" fontWeight={500} color={styles?.color ?? 'grey.800'}>
          {variant === 'small' ? 'Acuity' : 'Acuity Score'}
        </Typography>
        <Box
          borderRadius={2}
          paddingX={variant === 'small' ? 0 : 1.5}
          paddingY={variant === 'small' ? 0 : 0.5}
          sx={{
            backgroundColor: variant === 'small' ? undefined : 'hsla(0, 0%, 100%, 0.85)',
          }}>
          <Typography fontWeight={500} color={styles?.color ?? 'grey.600'}>
            {acuityScore?.score != null ? acuityScore.score : '-'}
          </Typography>
        </Box>
      </Stack>
      <Popover
        open={isDefined(anchorEl)}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
        disableEnforceFocus
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          marginTop: 1,
          pointerEvents: 'none',
        }}
        slotProps={{ paper: { sx: { padding: 2 } } }}>
        {acuityScore ? (
          <Stack gap={1}>
            <Typography variant="body1" fontWeight={500}>
              Acuity Score
            </Typography>
            <div>
              <Typography variant="body2" fontWeight={500} textTransform="capitalize">
                Last Updated ({distanceText ? distanceText : 'Over 7 days ago'})
              </Typography>
              <Typography variant="body1">
                {t('DATETIME_SHORT', { val: new Date(acuityScore.createdAt) })}
              </Typography>
            </div>
            <div>
              <Typography variant="body2" fontWeight={500}>
                Set By
              </Typography>
              <Typography variant="body1">{formatUserName(acuityScore.createdBy)}</Typography>
            </div>
            <div>
              <Typography variant="body2" fontWeight={500}>
                Reason
              </Typography>
              {acuityScore.reason ? (
                <Box
                  sx={{ backgroundColor: 'grey.100', paddingX: 2, paddingY: 1, borderRadius: 2 }}>
                  <Typography variant="body1" whiteSpace="pre">
                    {acuityScore.reason}
                  </Typography>
                </Box>
              ) : (
                <Typography variant="body1" color="grey.600">
                  No reason provided
                </Typography>
              )}
            </div>
          </Stack>
        ) : (
          <Typography variant="body1" color="grey.800" textAlign="center">
            No acuity score recorded
          </Typography>
        )}

        <Typography variant="body2" color="grey.600" textAlign="center" marginX={2} marginTop={2}>
          Set acuity score in the patient actions menu
        </Typography>
      </Popover>
    </>
  );
}

function getPaletteForAcuityScore(score: Maybe<number>) {
  switch (score) {
    case 1:
      return {
        backgroundColor: 'rgb(234, 250, 243)',
        color: 'rgb(20, 84, 54)',
        borderColor: '#00c76c',
      };
    case 2:
      return NEWS_SCORE_PALETTE[1];
    case 3:
      return NEWS_SCORE_PALETTE[2];
    case 4:
      return NEWS_SCORE_PALETTE[3];
    default:
      return undefined;
  }
}
