import React from 'react';

import { gql } from '@apollo/client';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

import {
  PatientAdmissionOverviewFragment,
  useGetCarePathwayDetailsQuery,
} from '@/generated/graphql';

import { isDefined } from '@/helpers/isDefined';
import { ErrorDisplay } from '@/components/ErrorDisplay';
import { PathwayAlerts } from '@/components/CarePathway/PathwayAlerts';
import { PathwayCheckupTypes } from '@/components/CarePathway/PathwayCheckupTypes';
import { PageContainer } from '@/components/PageContainer';
import { PatientFlags } from '@/components/CarePathway/PatientFlags';

export interface CarePathwayTabProps {
  isLoading: boolean;
  wardAdmission: Maybe<PatientAdmissionOverviewFragment>;
  patientFirstName: string;
  patientLastName: string;
  refresh: () => void;
}

export const GET_PATHWAY_DETAILS = gql`
  fragment CarePathwayDetails on CarePathway {
    id
    name
    checkupTypes {
      ...CheckupTypeDisplay
    }
    alertRules {
      ...AlertRuleDisplay
    }
  }

  query GetCarePathwayDetails($carePathwayId: ID!) {
    carePathway(id: $carePathwayId) {
      ...CarePathwayDetails
    }
  }
`;

export default function CarePathwayTab({
  wardAdmission,
  patientFirstName,
  patientLastName,
  isLoading,
  refresh,
}: CarePathwayTabProps) {
  const carePathwayId = wardAdmission?.carePathway?.id;
  const carePathwayName = wardAdmission?.carePathway?.name;

  const { data, loading, error } = useGetCarePathwayDetailsQuery({
    variables: {
      carePathwayId: carePathwayId ?? '',
    },
    // Only run this query if the patient has a care pathway
    skip: !isDefined(carePathwayId),
  });

  if (error) {
    return <ErrorDisplay showIcon message="Failed to load patient's assigned care pathway" />;
  }

  if (
    !isDefined(carePathwayId) ||
    !isDefined(carePathwayName) ||
    !isDefined(patientFirstName) ||
    !isDefined(patientLastName)
  ) {
    return isLoading ? (
      <Box padding={3} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    ) : (
      <Box
        padding={3}
        display="flex"
        margin="0 auto"
        flexDirection="column"
        alignItems="center"
        maxWidth={800}>
        <Alert severity="info">
          <AlertTitle>Patient has no assigned care pathway</AlertTitle>
          <Typography gutterBottom>
            Care pathways determine the frequency of checkups, questions asked during checkups,
            personalised EWS thresholds and any alerts to be triggered upon submitted vitals.
          </Typography>
          <Typography>Pathways are assigned to patients during ward admission.</Typography>
        </Alert>
      </Box>
    );
  }

  /**
   * Add 'Pathway' to the end of the name if it's not already there, this allows for situations where
   * the pathway name is 'Respiratory Pathway' or 'Respiratory' to be handled the same
   */
  const pathwayTitle = `${carePathwayName}${
    carePathwayName.includes('pathway') ? null : ' Pathway'
  }`;

  return (
    <PageContainer>
      <Typography component="h3" variant="h6" gutterBottom>
        {pathwayTitle}
      </Typography>
      <Alert severity="info">
        The following Care Pathway is assigned to {patientFirstName} {patientLastName} and is
        currently active. This pathway determines the frequency of checkups, questions asked during
        checkups, personalised EWS thresholds and any alerts to be triggered upon submitted vitals.
      </Alert>
      {loading && !isDefined(data?.carePathway) && (
        <Box marginTop={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {data?.carePathway && (
        <Box marginTop={2}>
          {wardAdmission && (
            <PatientFlags
              patientId={wardAdmission.patientId}
              patientFlags={wardAdmission.patientFlags}
              refresh={refresh}
            />
          )}
          <PathwayAlerts alertRules={data.carePathway.alertRules} />
          <PathwayCheckupTypes checkupTypes={data.carePathway.checkupTypes} />
        </Box>
      )}
    </PageContainer>
  );
}
