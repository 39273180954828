import React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import Moment from 'moment';

import {
  VirtualWardPatientActivityMonitoringSessionFragment,
  VirtualWardPatientCheckupFragment,
  VirtualWardPatientContinuousMonitoringWithSessionFragment,
} from '@/generated/graphql';
import { useTranslation } from 'react-i18next';

import { isDefined } from '@/helpers/isDefined';
import { ActivityMonitoringSessionStatus } from './ActivityMonitoringSessionStatus';
import { formatCheckupTypeName } from '../formatters';
import { ContinuousMonitoringSessionStatus } from './ContinuousMonitoringSessionStatus';
import { TotalEwsScore } from './TotalEwsScore';

interface LatestCheckupInfoProps {
  checkup: Maybe<VirtualWardPatientCheckupFragment>;
  continuousMonitoringWithSession:
    | VirtualWardPatientContinuousMonitoringWithSessionFragment
    | null
    | undefined;
  activityMonitoringSession: Maybe<VirtualWardPatientActivityMonitoringSessionFragment>;
  isContinuousMonitoring: boolean;
}

export function LatestCheckupInfo({
  checkup,
  continuousMonitoringWithSession,
  activityMonitoringSession,
  isContinuousMonitoring,
}: LatestCheckupInfoProps) {
  const continuousMonitoring = continuousMonitoringWithSession?.continuousMonitoring;

  return isContinuousMonitoring ? (
    <Stack direction="row" margin={1}>
      <TotalEwsScore ewsScores={continuousMonitoring?.thresholdScores ?? {}} />
      <ContinuousMonitoringInfo
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        continuousMonitoringWithSession={continuousMonitoringWithSession!}
        activityMonitoringSession={activityMonitoringSession}
      />
    </Stack>
  ) : (
    <Stack direction="row" margin={1}>
      <TotalEwsScore ewsScores={checkup?.ewsScores ?? {}} />
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <CheckupInfo checkup={checkup!} activityMonitoringSession={activityMonitoringSession} />
    </Stack>
  );
}

function CheckupInfo({
  checkup,
  activityMonitoringSession,
}: {
  checkup: VirtualWardPatientCheckupFragment;
  activityMonitoringSession: Maybe<VirtualWardPatientActivityMonitoringSessionFragment>;
}) {
  const classes = useStyles();

  return (
    <Box width={175}>
      <Box display="flex" alignItems="center" gap={6}>
        <Typography className={classes.label}>{formatCheckupTypeName(checkup)}</Typography>
        {isDefined(activityMonitoringSession) && !isDefined(activityMonitoringSession.endedAt) && (
          <ActivityMonitoringSessionStatus activityMonitoringSession={activityMonitoringSession} />
        )}
      </Box>
      <Typography className={classes.checkupDate}>
        {Moment(checkup.endedAt).format('MMM Do YYYY, h:mm a')}
      </Typography>
    </Box>
  );
}

function ContinuousMonitoringInfo({
  continuousMonitoringWithSession,
  activityMonitoringSession,
}: {
  continuousMonitoringWithSession: VirtualWardPatientContinuousMonitoringWithSessionFragment;
  activityMonitoringSession: Maybe<VirtualWardPatientActivityMonitoringSessionFragment>;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { continuousMonitoringSession, continuousMonitoring } = continuousMonitoringWithSession;
  const isSessionEnded = isDefined(continuousMonitoringSession.endedAt);

  return (
    <Box width={175}>
      <Box display="flex" alignItems="center" gap={6}>
        <Typography className={classes.label}>{t('Continuous')}</Typography>
        {!isSessionEnded && (
          <ContinuousMonitoringSessionStatus
            continuousMonitoringWithSession={continuousMonitoringWithSession}
          />
        )}
        {isDefined(activityMonitoringSession) && !isDefined(activityMonitoringSession.endedAt) && (
          <ActivityMonitoringSessionStatus activityMonitoringSession={activityMonitoringSession} />
        )}
      </Box>
      <Typography className={classes.checkupDate}>
        {Moment(
          continuousMonitoring
            ? continuousMonitoring.bucketEndAt
            : continuousMonitoringSession.createdAt,
        ).format('MMM Do YYYY, h:mm a')}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  checkupDate: {
    fontSize: theme.typography.pxToRem(14),
  },
  label: {
    textTransform: 'capitalize',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },
}));
