import React, { useCallback, useMemo } from 'react';
import { Group } from '@visx/group';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { ScaleTime, ScaleLinear } from '@visx/vendor/d3-scale';
import { GridRows, GridColumns } from '@visx/grid';
import { LinePath } from '@visx/shape';
import { Text, TextProps } from '@visx/text';
import makeStyles from '@mui/styles/makeStyles';
import { addMilliseconds, differenceInMilliseconds, format } from 'date-fns';
import { EcgDataPoint } from './EcgTrace';
import { Browser, getCurrentBrowser } from '@/helpers/getCurrentBrowser';

interface EcgChartProps {
  yRange: number;
  xTickResolution: number;
  graphSize: { width: number; height: number };
  margin: { top: number; right: number; bottom: number; left: number };
  gridRatio: number;
  xAxis: { min: Date; max: Date };
  yAxis: { min: number; max: number };
  xScale: ScaleTime<number, number, never>;
  yScale: ScaleLinear<number, number, never>;
  segments: EcgDataPoint[][];
}

const Y_GRID_RESOLUTION = 0.5; // mV grid resolution
const X_GRID_RESOLUTION = 200; // ms grid resolution

// React.memo protects the chart against tooltip re-renders
export const EcgChart = React.memo(
  ({
    yRange,
    xTickResolution,
    segments,
    xAxis,
    yAxis,
    margin,
    gridRatio,
    graphSize,
    xScale,
    yScale,
  }: EcgChartProps) => {
    const classes = useStyles();

    // Force X axis tick instead of relying on automatic spacing
    const xScaleTickValues = useMemo(() => {
      const values: Date[] = [];
      for (
        let i = 0;
        i < differenceInMilliseconds(xAxis.max, xAxis.min) / (xTickResolution / gridRatio);
        i++
      ) {
        values.push(addMilliseconds(xAxis.min, (xTickResolution / gridRatio) * i));
      }
      return values;
    }, [xAxis, xTickResolution, gridRatio]);

    const renderedSegments = useMemo(() => {
      return segments.map((segment, index) => (
        <LinePath
          key={index}
          data={segment}
          x={(d) => xScale(d.x)}
          y={(d) => yScale(d.y)}
          stroke="#000"
          strokeWidth={1.5}
          strokeLinecap="round"
        />
      ));
    }, [segments, xScale, yScale]);

    const formatXAxisDate = useCallback(
      (date: Date, index: number) => {
        if (xTickResolution < 1000) {
          return index % gridRatio === 0 ? format(date, 'HH:mm:ss.S') : format(date, '.S');
        } else {
          return index % (gridRatio * 2) === 0 ? format(date, 'HH:mm:ss') : format(date, 'ss');
        }
      },
      [xTickResolution, gridRatio],
    );

    const styleXAxisDate = useCallback(
      (index: number): TextProps => {
        if (xTickResolution < 1000) {
          return index % gridRatio === 0
            ? { angle: 90, textAnchor: 'end', fontSize: 12, fontWeight: 'bold', dy: 55, dx: -4 }
            : { angle: 0, textAnchor: 'end', fontSize: 10, dy: 9, dx: 4 };
        } else {
          return index % (gridRatio * 2) === 0
            ? { angle: 90, textAnchor: 'end', fontSize: 12, fontWeight: 'bold', dy: 45, dx: -4 }
            : { angle: 0, textAnchor: 'end', fontSize: 10, dy: 8, dx: 6 };
        }
      },
      [xTickResolution, gridRatio],
    );

    const currentBrowser = getCurrentBrowser();
    const isWebkitBrowser = currentBrowser === Browser.Chrome || currentBrowser === Browser.Edge;

    return (
      <svg className={classes.svg} width={graphSize.width} height={graphSize.height}>
        <Group>
          {xTickResolution < 1000 && (
            <>
              <GridRows
                left={margin.left}
                scale={yScale}
                width={graphSize.width - margin.left - margin.right}
                numTicks={(yAxis.max - yAxis.min) / (Y_GRID_RESOLUTION / gridRatio)}
                stroke="red"
                strokeWidth={isWebkitBrowser ? 0.2 : 1}
                opacity={isWebkitBrowser ? 0.3 : 0.1}
                shapeRendering={'crispEdges'}
              />
              <GridColumns
                top={margin.top}
                scale={xScale}
                height={graphSize.height - margin.top - margin.bottom}
                tickValues={xScaleTickValues}
                stroke="red"
                strokeWidth={isWebkitBrowser ? 0.2 : 1}
                opacity={isWebkitBrowser ? 0.3 : 0.1}
                shapeRendering={'crispEdges'}
              />
            </>
          )}
          <GridRows
            left={margin.left}
            scale={yScale}
            width={graphSize.width - margin.left - margin.right}
            numTicks={(yAxis.max - yAxis.min) / Y_GRID_RESOLUTION}
            stroke="red"
            strokeWidth={isWebkitBrowser ? 0.2 : 1}
            opacity={isWebkitBrowser ? 1 : 0.2}
            shapeRendering={'crispEdges'}
          />
          <GridColumns
            top={margin.top}
            scale={xScale}
            height={graphSize.height - margin.top - margin.bottom}
            numTicks={differenceInMilliseconds(xAxis.max, xAxis.min) / X_GRID_RESOLUTION}
            stroke="red"
            strokeWidth={isWebkitBrowser ? 0.2 : 1}
            opacity={isWebkitBrowser ? 1 : 0.2}
            shapeRendering={'crispEdges'}
          />
          {renderedSegments}
        </Group>
        <Group>
          <AxisLeft
            left={margin.left}
            scale={yScale}
            numTicks={(yAxis.max - yAxis.min) / (yRange / 4)}
          />
          <AxisBottom
            top={graphSize.height - margin.bottom}
            scale={xScale}
            tickValues={xScaleTickValues.filter((_, i) => i % gridRatio === 0)}
            tickFormat={(date, index) => (date instanceof Date ? formatXAxisDate(date, index) : '')}
            tickLabelProps={(_, index) => styleXAxisDate(index)}
          />
          <Text
            x={margin.left + 25}
            y={margin.top + 42}
            angle={-90}
            fontSize={22}
            fontWeight={'bold'}>
            mV
          </Text>
          <Text
            x={graphSize.width - margin.right - 45}
            y={graphSize.height - margin.bottom - 10}
            fontSize={22}
            fontWeight={'bold'}>
            sec
          </Text>
        </Group>
      </svg>
    );
  },
);

const useStyles = makeStyles(() => ({
  svg: {
    backgroundColor: '#f3f3f3',
  },
}));

EcgChart.displayName = 'EcgChart'; // eslint asks for display name when the components is memoized
