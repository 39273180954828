import React, { useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Typography,
  Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gql } from '@apollo/client';
import { Alert, Skeleton } from '@mui/material';
import { toast } from 'sonner';

import { usePatientNhsNumberDetailsLazyQuery } from '@/generated/graphql';

import localeFormat from '@/helpers/LocaleFormatting';
import { GraphQLNhsNumberResponseDetails } from '@/controllers/types';

interface NhsNumberResponseDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  patientId: string;
  formattedNhsNumber: string | null | undefined;
  /**
   * If this is not set it will be fetched when opening the details dialog
   */
  nhsNumberResponseDetails?: GraphQLNhsNumberResponseDetails | null | undefined;
}

export const PatientNhsNumberDetailsDialogFragmentDef = gql`
  fragment PatientNhsNumberDetailsDialog on Patient {
    nhsNumberResponseDetails {
      nhsNumber
      latestRetrievalTime
      editable
      latestStatus {
        message
        status
      }
    }
  }
`;

export const QUERY_PATIENT_NHS_NUMBER_DETAILS = gql`
  query PatientNhsNumberDetails($id: ID!) {
    patient(id: $id) {
      ...PatientNhsNumberDetailsDialog
    }
  }
`;

export default function NhsNumberResponseDetailsDialog({
  open,
  onClose,
  formattedNhsNumber,
  nhsNumberResponseDetails: nhsNumberResponseDetailsFromProps,
  patientId,
}: NhsNumberResponseDetailsDialogProps) {
  const classes = useStyles();

  const [fetch, { data, loading, error }] = usePatientNhsNumberDetailsLazyQuery({
    variables: { id: patientId },
    onError: () =>
      toast.error('An error occurred when trying to fetch NHS number details for this patient'),
  });

  useEffect(() => {
    /**
     * There is a distinction between the nhsNumberResponseDetailsFromProps being undefined and null.
     * If it is undefined it means that it was not passed in and we should fetch it.
     * If it is null, then it means it has been provided from elsewhere but does not exist.
     *
     * Currently this is only provided eagerly from the patient details page.
     */
    if (open && nhsNumberResponseDetailsFromProps === undefined) {
      fetch();
    }
  }, [open, fetch, nhsNumberResponseDetailsFromProps]);

  const nhsNumberResponseDetails =
    data?.patient?.nhsNumberResponseDetails ?? nhsNumberResponseDetailsFromProps;

  const latestStatus = nhsNumberResponseDetails?.latestStatus;
  const latestRetrievalTime = nhsNumberResponseDetails?.latestRetrievalTime;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onClick={(event) => event.stopPropagation()} // Clicking anywhere on the screen propagates event to parent: https://stackoverflow.com/questions/60436516/prevent-event-propagation-on-row-click-and-dialog-in-material-ui
      maxWidth="sm"
      fullWidth>
      <DialogTitle>NHS Number Details</DialogTitle>
      <DialogContent>
        {error ? (
          <Alert color="error" className={classes.errorAlert}>
            Failed to find NHS number details
          </Alert>
        ) : null}
        <NhsNumberRow label="NHS Number" value={formattedNhsNumber} />
        <NhsNumberRow
          label="Latest Retrieval Time"
          loading={loading}
          skeletonWidth={200}
          value={
            latestRetrievalTime
              ? localeFormat.formatNhsNumberRetrievalTime(latestRetrievalTime)
              : undefined
          }
        />
        <NhsNumberRow
          label="Latest Status"
          value={latestStatus?.status}
          skeletonWidth={80}
          loading={loading}
        />
        <NhsNumberRow
          label="Details"
          value={latestStatus?.message}
          skeletonWidth={240}
          loading={loading}
        />
        {formattedNhsNumber ? (
          <Grid container className={classes.confidentialNote}>
            <Grid item>
              <Typography variant="caption">
                If the NHS number displayed has discrepancies with the number on your records please
                report this at <Link href="mailto:support@feebris.com">support@feebris.com</Link>.
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        {latestStatus?.status === 'Not Found' ? (
          <Grid container className={classes.confidentialNote}>
            <Grid item>
              <Typography variant="caption">
                If an NHS Number was not found for the patient please make sure their first name,
                last name, date of birth and sex are correct.
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

interface NhsNumberRowProps {
  label: string;
  value: string | null | undefined;
  loading?: boolean;
  skeletonWidth?: number;
}

const NhsNumberRow = ({ label, value, loading, skeletonWidth = 120 }: NhsNumberRowProps) => {
  const classes = useNumberRowStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} className={classes.label}>
        {label}
      </Grid>
      <Grid item xs={8}>
        {loading ? <Skeleton width={skeletonWidth} /> : value ? value : 'N/A'}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  confidentialNote: {
    paddingTop: theme.spacing(3),
  },
  errorAlert: {
    marginBottom: theme.spacing(2),
  },
}));

const useNumberRowStyles = makeStyles({
  label: {
    fontWeight: 500,
  },
});
