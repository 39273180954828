import React, { useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Divider,
  Paper,
  Skeleton,
  Stack,
  Switch,
  TextField,
  Typography,
  MenuItem,
} from '@mui/material';
import { toast } from 'sonner';
import _ from 'lodash';
import WarningIcon from '@mui/icons-material/Warning';

import {
  useAdminDefaultFeaturesQueryInternal,
  useUpdateOrganizationFeaturesMutationInternal,
} from '@/generated/graphql-internal';

import { PageContainer } from '@/components/PageContainer';
import { PageTitle } from '@/components/PageTitle';
import { OrganizationFeatureFlags } from '@/hooks/useAuth';
import { NationalIdentifierType } from '@/generated/graphql';

interface OrganizationConfigProps {
  organizationId: string;
  features: OrganizationFeatureFlags;
}

export const QUERY_DEFAULT_FEATURES = gql`
  query AdminDefaultFeatures {
    globalState(key: "default_features") {
      key
      value
    }
  }
`;

export const UPDATE_FEATURES = gql`
  mutation UpdateOrganizationFeatures($organizationId: ID!, $features: JSONObject!) {
    updateOrganization(organization: { id: $organizationId, features: $features }) {
      ...OrganizationAdminView
    }
  }
`;

export function OrganizationSettings({ features, organizationId }: OrganizationConfigProps) {
  const [currentFeatureState, setCurrentFeatureState] =
    useState<OrganizationFeatureFlags>(features);

  const { data: defaultFeaturesData, loading: loadingDefaultFeatures } =
    useAdminDefaultFeaturesQueryInternal();
  const defaultFeatures = defaultFeaturesData?.globalState?.[0]?.value;

  const [updateFeatures, { loading: updatingFeatureFlags }] =
    useUpdateOrganizationFeaturesMutationInternal({
      onCompleted: () => {
        toast.success('Organization settings updated');
      },
      onError: () => {
        toast.error('Failed to update organization settings');
      },
    });

  useEffect(() => {
    setCurrentFeatureState(features);
  }, [features]);

  const setFeatureFlag = (
    flag: keyof OrganizationFeatureFlags,
    value: boolean | number | string | null,
  ) => {
    setCurrentFeatureState((state) => ({ ...state, [flag]: value }));
  };

  const isFlagPendingChange = (flag: keyof OrganizationFeatureFlags) =>
    currentFeatureState[flag] !== features[flag];

  const hasPendingChanges = Object.keys(currentFeatureState).some((key) =>
    isFlagPendingChange(key as keyof OrganizationFeatureFlags),
  );

  const formatFeatureFlagDefaultValue = (flag: keyof OrganizationFeatureFlags) => {
    const meta = featureFlagMeta[flag];

    switch (meta.type) {
      case 'boolean':
        return defaultFeatures?.[flag] ? 'enabled' : 'disabled';
      case 'number':
        return (meta.scale ?? 1) * defaultFeatures?.[flag];
      case 'option':
        return meta.options[defaultFeatures?.[flag] as string]?.name ?? meta.emptyText;
    }
  };

  const deviatedFromDefault = (flag: keyof OrganizationFeatureFlags) =>
    features[flag] !== defaultFeatures?.[flag];

  const resetChanges = () => setCurrentFeatureState(features);

  const saveChanges = () => {
    updateFeatures({
      variables: {
        organizationId,
        features: currentFeatureState,
      },
    });
  };

  return (
    <PageContainer>
      <div>
        <PageTitle
          title="Organisation Settings"
          subtitle="Manage feature flags for an organisation"
        />

        <Paper sx={{ paddingY: 4, paddingX: 5, width: '100%' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}>
            <Typography variant="h6">Feature flags</Typography>
            {hasPendingChanges ? (
              <Chip label="unsaved changes" color="warning" size="small" />
            ) : null}
          </Stack>
          <Alert severity="info" sx={{ marginBottom: 2 }}>
            <AlertTitle>
              Changes to features will not take effect immediately for all users
            </AlertTitle>
            Portal users will need to do a full page refresh and mobile app users will need to
            refresh their patients list to receive feature switch changes.
          </Alert>
          <Stack gap={2}>
            {Object.entries(featureFlagCategories).map(([category, categoryHeading]) => {
              const categoryMetaItems = groupedMeta[category as FeatureFlagCategory];
              if (!categoryMetaItems || categoryMetaItems.length === 0) {
                return null;
              }

              return (
                <Stack key={category}>
                  <Typography
                    color="primary.dark"
                    fontWeight={500}
                    fontSize={(theme) => theme.typography.pxToRem(18)}>
                    {categoryHeading.title}
                  </Typography>
                  <Typography color="grey.700">{categoryHeading.description}</Typography>
                  {categoryMetaItems.map(([_flag, meta], i) => {
                    const flag = _flag as keyof OrganizationFeatureFlags;
                    const isLast = i === Object.keys(categoryMetaItems).length - 1;
                    return (
                      <Box
                        key={flag}
                        sx={{
                          borderRadius: 2,
                          '&:hover': {
                            backgroundColor: 'grey.100',
                          },
                        }}>
                        <Stack
                          paddingX={2}
                          paddingTop={2}
                          paddingBottom={isLast ? 2 : 0}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center">
                          <Stack>
                            <Stack direction="row" alignItems="flex-start" gap={1}>
                              <Typography
                                sx={(t) => ({
                                  fontSize: t.typography.pxToRem(18),
                                })}
                                color="primary.dark">
                                {meta.title}
                              </Typography>
                              {loadingDefaultFeatures ? (
                                <Skeleton width={50} sx={{ display: 'inline-block' }} />
                              ) : deviatedFromDefault(flag) ? (
                                <Chip
                                  sx={{ marginLeft: 1 }}
                                  size="small"
                                  variant="outlined"
                                  label={`Changed from default: ${formatFeatureFlagDefaultValue(
                                    flag,
                                  )}`}
                                />
                              ) : null}
                              {
                                // Show a warning if the feature is experimental
                                meta.status === 'experimental' && (
                                  <Chip
                                    label="experimental"
                                    color="error"
                                    icon={<WarningIcon />}
                                    size="small"
                                  />
                                )
                              }
                              {isFlagPendingChange(flag) && (
                                <Chip label="pending" color="warning" size="small" />
                              )}
                            </Stack>
                            <Typography variant="body2" color="grey.700">
                              {meta.description}
                            </Typography>
                          </Stack>
                          <div>
                            {meta.type === 'boolean' && (
                              <BooleanFeatureFlagSetting
                                type="boolean"
                                name={flag}
                                defaultValue={defaultFeatures?.[flag] as boolean}
                                value={currentFeatureState[flag] as boolean}
                                onChange={(value) => setFeatureFlag(flag, value)}
                              />
                            )}
                            {meta.type === 'number' && (
                              <NumericFeatureFlagSetting
                                type="number"
                                name={flag}
                                defaultValue={defaultFeatures?.[flag] as number}
                                value={currentFeatureState[flag] as number}
                                onChange={(value) => setFeatureFlag(flag, value)}
                                scale={meta.scale}
                                min={meta.min}
                                max={meta.max}
                                step={meta.step}
                              />
                            )}
                            {meta.type === 'option' && (
                              <OptionFeatureFlagSetting
                                type="option"
                                name={flag}
                                defaultValue={defaultFeatures?.[flag] as string}
                                value={currentFeatureState[flag] as string}
                                onChange={(value) => setFeatureFlag(flag, value)}
                                options={meta.options}
                                nullable={meta.nullable}
                                emptyText={meta.emptyText}
                                emptyDescription={meta.emptyDescription}
                              />
                            )}
                          </div>
                        </Stack>
                        {!isLast && <Divider sx={{ marginTop: 2, marginX: 2 }} />}
                      </Box>
                    );
                  })}
                </Stack>
              );
            })}
          </Stack>
          <Stack direction="row" justifyContent="flex-end" gap={2} marginTop={2}>
            <Button
              onClick={() => resetChanges()}
              disabled={!hasPendingChanges || updatingFeatureFlags}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => saveChanges()}
              disabled={!hasPendingChanges || updatingFeatureFlags}>
              Save changes
            </Button>
          </Stack>
        </Paper>
      </div>
    </PageContainer>
  );
}

interface BooleanFeatureFlagSettingProps {
  type: 'boolean';
  name: string;
  defaultValue: Maybe<boolean>;
  value: boolean;
  onChange: (value: boolean) => void;
}

interface NumericFeatureFlagSettingProps {
  type: 'number';
  name: string;
  defaultValue: Maybe<number>;
  value: number;
  format?: (value: number) => number;
  onChange: (value: number) => void;
  scale?: number;
  min?: number;
  max?: number;
  step?: number;
}

interface OptionFeatureFlagSettingProps<TValue extends string = string> {
  type: 'option';
  name: string;
  defaultValue: Maybe<TValue>;
  value: TValue;
  onChange: (value: string | null) => void;
  options: Record<TValue, OptionMeta>;
  nullable: boolean;
  emptyText?: string;
  emptyDescription?: string;
}

function OptionFeatureFlagSetting({
  name,
  value,
  onChange,
  options,
  nullable,
  emptyText = 'None',
  emptyDescription = 'Disable this feature',
}: OptionFeatureFlagSettingProps) {
  return (
    <TextField
      select
      variant="outlined"
      name={name}
      value={value || ''}
      SelectProps={{
        displayEmpty: true,
        renderValue: (value) => options[value as string]?.name || emptyText,
      }}
      onChange={(e) => onChange(e.target.value?.length ? e.target.value : null)}>
      {nullable && (
        <MenuItem value="">
          <Stack direction="column">
            <Typography>{emptyText}</Typography>
            <Typography variant="caption" color="grey.600">
              {emptyDescription}
            </Typography>
          </Stack>
        </MenuItem>
      )}
      {Object.entries(options).map(([value, label]) => (
        <MenuItem key={value} value={value}>
          <Stack direction="column">
            <Typography>{label.name}</Typography>
            <Typography variant="caption" color="grey.600">
              {label.description}
            </Typography>
          </Stack>
        </MenuItem>
      ))}
    </TextField>
  );
}

function BooleanFeatureFlagSetting({ name, value, onChange }: BooleanFeatureFlagSettingProps) {
  return <Switch checked={value} onChange={(e) => onChange(e.target.checked)} name={name} />;
}

function NumericFeatureFlagSetting({
  name,
  value,
  onChange,
  scale = 1,
  min,
  max,
  step,
}: NumericFeatureFlagSettingProps) {
  return (
    <TextField
      name={name}
      type="number"
      variant="outlined"
      sx={{ width: 80 }}
      value={value * scale}
      inputProps={{ min, max, step }}
      onChange={(e) => onChange(Number(e.target.value) / scale)}
    />
  );
}

type FeatureFlagCategory = 'commercial' | 'experience' | 'integrations' | 'development';
const featureFlagCategories: Record<
  FeatureFlagCategory,
  {
    title: string;
    description: string;
  }
> = {
  commercial: {
    title: 'Commercial',
    description: 'Feature flags that affect commercial aspects of the platform',
  },
  experience: {
    title: 'Experience',
    description: 'Feature flags that affect user experience',
  },
  integrations: {
    title: 'Integrations',
    description: 'Feature flags that affect integrations with other systems',
  },
  development: {
    title: 'Development',
    description: 'Feature flags that affect development and testing',
  },
};

type FeatureFlagStatus = 'experimental';

interface OptionMeta {
  name: string;
  description: string;
  status?: FeatureFlagStatus;
}

interface OptionFeatureFlagMeta<TValue extends string = string> {
  title: string;
  description: string;
  status?: FeatureFlagStatus;
  category: FeatureFlagCategory;
  options: Record<TValue, OptionMeta>;
  type: 'option';
  nullable: boolean;
  emptyText?: string;
  emptyDescription?: string;
}

interface NumericFeatureFlagMeta {
  title: string;
  description: string;
  status?: FeatureFlagStatus;
  category: FeatureFlagCategory;
  scale?: number;
  type: 'number';
  min?: number | undefined;
  max?: number | undefined;
  step?: number | undefined;
}

interface BooleanFeatureFlagMeta {
  title: string;
  description: string;
  status?: FeatureFlagStatus;
  category: FeatureFlagCategory;
  type: 'boolean';
}

type FeatureFlagMeta = NumericFeatureFlagMeta | BooleanFeatureFlagMeta | OptionFeatureFlagMeta;
const featureFlagMeta: Record<keyof OrganizationFeatureFlags, FeatureFlagMeta> = {
  nationalIdentifierScheme: {
    title: 'National Identifier Scheme',
    description:
      'The type of national identifier used for patients. This controls input and validation when searching for, or creating patients.',
    type: 'option',
    options: {
      NhsNumber: {
        name: 'NHS Number',
        description: 'Also enables automatic retrieval of NHS Number from the NHS Spine',
      },
      NorthernIrelandHcn: {
        name: 'Northern Ireland HCN',
        description:
          "Health and Care Number used in Northern Ireland's health and social care system",
      },
    },
    category: 'commercial',
    nullable: true,
    emptyText: 'None',
    emptyDescription: 'National identifiers cannot be set against patients',
  } satisfies OptionFeatureFlagMeta<NationalIdentifierType>,
  stethoscopeQualityEnabled: {
    title: 'Stethoscope Quality',
    description: 'Enable Stethoscope Quality for patients',
    type: 'boolean',
    category: 'experience',
  },
  skipConsent: {
    title: 'Skip Consent',
    description: 'Skip the consent step when submitting the first checkup for a new patient',
    type: 'boolean',
    category: 'experience',
  },
  selfCare: {
    title: 'Self Care Patients',
    description: 'Enable creation of self-care patients',
    type: 'boolean',
    category: 'commercial',
  },
  skipNextAction: {
    title: 'Skip Next Action',
    description: 'Skip selected action and patient stability screens when running a checkup',
    type: 'boolean',
    category: 'experience',
  },
  automaticLogoutAlert: {
    title: 'Automatic Logout Alert',
    description: 'Portal automatic logout time in minutes (0 to disable)',
    type: 'number',
    scale: 1 / (1_000 * 60),
    step: 1,
    min: 0,
    category: 'experience',
  },
  wards: {
    title: 'Wards',
    description: 'Enable creation and management of wards',
    type: 'boolean',
    category: 'commercial',
  },
  videoCall: {
    title: 'Video Call',
    description: 'Enable video calls for patients',
    type: 'boolean',
    category: 'commercial',
  },
  patientChat: {
    title: 'Patient Chat',
    description:
      'Enable patient chat. Note: This feature is experimental and for internal use only',
    type: 'boolean',
    category: 'commercial',
    status: 'experimental',
  },
  virtualWardAllPatientsLegacyBehavior: {
    title: 'Show all patients on virtual ward',
    description:
      'Show all patients on virtual ward, regardless of ward assignment (this is a legacy behavior and should be disabled for care homes)',
    type: 'boolean',
    category: 'commercial',
  },
  checkupSchedule: {
    title: 'Checkup Schedule',
    description: 'Enable Checkup Schedules against checkup types (with alerts when overdue)',
    type: 'boolean',
    category: 'commercial',
  },
  continuousMonitoring: {
    title: 'Continuous Monitoring',
    description: 'Enable Continuous Monitoring (Vivalink) for patients',
    type: 'boolean',
    category: 'commercial',
  },
  continuousMonitoringSpo2FromMax: {
    title: 'Continuous Monitoring SpO2 From Max',
    description: 'Source SpO2 from the maximum value in a 15 minute bucket, otherwise the median',
    type: 'boolean',
    category: 'experience',
  },
  activityMonitoring: {
    title: 'Activity Monitoring',
    description: 'Enable Activity Monitoring (Pacsana) for patients',
    type: 'boolean',
    category: 'commercial',
  },
  patientAcuityScore: {
    title: 'Patient Acuity Score',
    description: 'Enable manual acuity score tracking for patients',
    type: 'boolean',
    category: 'commercial',
  },
  ehrIntegrations: {
    title: 'EHR Integrations',
    description: 'Enable SystmOne and EMIS integrations on pathways',
    type: 'boolean',
    category: 'integrations',
  },
  integrationApi: {
    title: 'Integration API',
    description: 'Enable Integration API for patients',
    type: 'boolean',
    category: 'integrations',
  },
  cleoIntegrationApi: {
    title: 'Cleo Integration API',
    description: 'Enable Cleo Integration API for patients',
    type: 'boolean',
    category: 'integrations',
  },
  newPulseOxScreen: {
    title: 'New Pulse Oximeter Screen',
    description: 'Enables the new Pulse Oximeter recording screen',
    type: 'boolean',
    category: 'experience',
  },
};

// Group feature flags by category for display
const groupedMeta = _.groupBy(Object.entries(featureFlagMeta), ([_flag, meta]) => meta.category);
