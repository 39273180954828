import React from 'react';
import { CheckupDetailsFragment } from '@/generated/graphql';
import { useTranslation } from 'react-i18next';

interface Props {
  checkup: CheckupDetailsFragment;
}
export default function BlockPulseOx({ checkup }: Props) {
  const { t } = useTranslation();
  const hr = checkup?.pulseOxiData?.averageHr;
  const spO2 = checkup.pulseOxiData?.averageSpO2;
  const confidence = checkup.pulseOxiData?.confidence;

  return (
    <div className="block block--pulse-ox">
      <div className="block__title">Pulse Oximeter</div>
      <div className="block__inner">
        <div className="block__cols">
          <div className="e2e__pulseoxblock__hr">
            <p className="title">PR</p>
            <p className="value">{hr ?? '-'}</p>
          </div>
          <div className="e2e__pulseoxblock__spo2">
            <p className="title">SPO2</p>
            <p className="value">{spO2 ?? '-'}</p>
            {confidence && (
              <>
                <p className="confidence__short">
                  {t(`PULSE_OXIMETER_CONFIDENCE_SHORT.${confidence}`) as string}
                </p>
                <p className="confidence__long">
                  {t(`PULSE_OXIMETER_CONFIDENCE_LONG.${confidence}`) as string}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
