import React, { useState } from 'react';

import { Box, Popover, Typography, Stack, Link } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import clsx from 'clsx';

import { EwsScores } from '@/generated/graphql';

import { newsPaletteForRiskLevel } from '@/styles/NEWSColors';
import makeStyles from '@mui/styles/makeStyles';

interface TotalEwsScoreProps {
  ewsScores: EwsScores;
}

export function TotalEwsScore({ ewsScores }: TotalEwsScoreProps) {
  const classes = useStyles();

  const anchorElRef = React.useRef<HTMLDivElement | null>(null);
  const popoverRef = React.useRef<HTMLDivElement | null>(null);

  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleMouseEnter = () => {
    setPopoverVisible(true);
  };

  const handleMouseExit = () => {
    setPopoverVisible(false);
  };

  return (
    <>
      <Box
        tabIndex={0}
        ref={anchorElRef}
        className={clsx(classes.newsContainer, classes.focused)}
        style={newsPaletteForRiskLevel(ewsScores.riskLevel ?? 0)}
        flexShrink={0}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseExit}
        onFocus={() => setPopoverVisible(true)}
        onBlur={() => setPopoverVisible(false)}
        // We need to handle the escape key to close the popover
        // due to an issue with the various disable focus props on Popover
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            setPopoverVisible(false);
          }
        }}
        aria-label="EWS Score"
        aria-expanded={popoverVisible}>
        {ewsScores.totalScore ?? '?'}
      </Box>
      <Popover
        open={popoverVisible}
        aria-label="EWS Score Details"
        onClose={() => setPopoverVisible(false)}
        disableRestoreFocus
        disableEnforceFocus
        disableAutoFocus
        anchorEl={anchorElRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ pointerEvents: 'none', marginTop: 1 }}
        slotProps={{
          paper: {
            sx: { padding: 2, pointerEvents: 'auto' },
            ref: popoverRef,
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseExit,
          },
        }}>
        <Typography variant="body1" fontWeight={500} gutterBottom>
          Vital Sign Scores (total score of {ewsScores.totalScore})
        </Typography>
        {/** Lets breakdown all the individual scores which are set, explaining that they contribute to the total score */}
        <Stack gap={0.5} marginBottom={1}>
          {ewsScores.BPScore != null && (
            <Typography variant="body2">
              <strong>Blood Pressure:</strong> {ewsScores.BPScore}
            </Typography>
          )}
          {ewsScores.SpO2Score != null && (
            <Typography variant="body2">
              <strong>Oxygen Saturation:</strong> {ewsScores.SpO2Score}
            </Typography>
          )}
          {ewsScores.RRScore != null && (
            <Typography variant="body2">
              <strong>Respiratory Rate:</strong> {ewsScores.RRScore}
            </Typography>
          )}
          {ewsScores.tempScore != null && (
            <Typography variant="body2">
              <strong>Temperature:</strong> {ewsScores.tempScore}
            </Typography>
          )}
          {ewsScores.HRScore != null && (
            <Typography variant="body2">
              <strong>Pulse Rate:</strong> {ewsScores.HRScore}
            </Typography>
          )}
          {ewsScores.consciousnessScore != null && (
            <Typography variant="body2">
              <strong>Level of Consciousness:</strong> {ewsScores.consciousnessScore}
            </Typography>
          )}
          {ewsScores.onOxygenScore != null && (
            <Typography variant="body2">
              <strong>On Oxygen:</strong> {ewsScores.onOxygenScore}
            </Typography>
          )}
        </Stack>
        <Box paddingX={2} paddingY={1} sx={{ backgroundColor: 'grey.100', borderRadius: 2 }}>
          <Typography variant="body2" fontWeight={500} gutterBottom>
            Coloured by Risk Level of {ewsScores.riskLevel}
          </Typography>
          <Link
            href="https://www.rcp.ac.uk/media/mrllczrj/news2-chart-4_clinical-response-to-news-trigger-thresholds_0.pdf"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            variant="body2"
            underline="always">
            How is risk level calculated? <OpenInNewIcon fontSize="inherit" />
          </Link>
        </Box>
      </Popover>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  newsContainer: {
    display: 'flex',
    marginRight: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
    width: 42,
    height: 42,
    borderRadius: 21,
    border: '1px solid transparent',
    color: theme.palette.grey[600],
    fontWeight: 500,
    lineHeight: 1,
    fontSize: theme.typography.pxToRem(18),
  },
  focused: {
    '&:focus-visible': {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  },
}));
