import React, { useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  Popover,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import UpArrowIcon from '@mui/icons-material/KeyboardArrowUp';
import DownArrowIcon from '@mui/icons-material/KeyboardArrowDown';

import { SortDirection, VirtualWardPatientsSortField } from '@/generated/graphql';

import { useVirtualWardContext } from '../VirtualWardContext';
import clsx from 'clsx';
import { Alert, AlertTitle } from '@mui/material';

const facetLabels: Record<VirtualWardPatientsSortField, string> = {
  ewsScore: 'EWS',
  alertCount: 'Alerts',
  latestVitals: 'Last Reading',
  acuityScore: 'Acuity',
};

const translateFacetOrder: Record<
  VirtualWardPatientsSortField,
  (direction: SortDirection) => string
> = {
  ewsScore: (direction) => (direction === SortDirection.Desc ? 'High to Low' : 'Low to High'),
  alertCount: (direction) => (direction === SortDirection.Desc ? 'Most to Least' : 'Least to Most'),
  latestVitals: (direction) =>
    direction === SortDirection.Desc ? 'Newest to Oldest' : 'Oldest to Newest',
  acuityScore: (direction) => (direction === SortDirection.Desc ? 'High to Low' : 'Low to High'),
};

export function SortMenu() {
  const { sortConfig, toggleSortFacet, setFacetPriority, resetSort, toggleSortDirection } =
    useVirtualWardContext();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    // Toggle the anchor element, which in turn toggles the popover
    setAnchorEl((anchorEl) => (anchorEl ? null : event.currentTarget));
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        endIcon={<ImportExportIcon />}
        onClick={handleButtonClick}
        className={clsx(Boolean(anchorEl) && classes.activeFilterButton)}>
        Sort
      </Button>
      <Popover
        className={classes.popover}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Box display="flex" flexDirection="column" padding={2} minWidth={320} maxWidth={480}>
          <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={1}>
            <Typography className={classes.title}>Sort By</Typography>
            <Button
              variant="text"
              color="primary"
              size="small"
              className={classes.resetButton}
              onClick={() => resetSort()}>
              Reset Sort
            </Button>
          </Box>
          {sortConfig.map((sort, idx) => (
            <Box key={sort.facet} display="flex" alignItems="center" margin={0.25}>
              <Box flexGrow={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      readOnly={sort.readonly}
                      disabled={sort.readonly}
                      className={classes.checkbox}
                      checked={sort.enabled}
                      onChange={() => {
                        toggleSortFacet(sort.facet);
                      }}
                      name={sort.facet}
                      color="primary"
                    />
                  }
                  label={facetLabels[sort.facet]}
                />
              </Box>
              <Link
                component="button"
                color="primary"
                className={clsx(
                  classes.directionSelect,
                  sort.direction === SortDirection.Asc && classes.directionSelectChanged,
                )}
                aria-label={`Change ${facetLabels[sort.facet]} sort to ${
                  sort.direction === SortDirection.Asc ? 'Descending' : 'Ascending'
                }`}
                onClick={() => toggleSortDirection(sort.facet)}>
                {translateFacetOrder[sort.facet](sort.direction)}
              </Link>
              <IconButton
                size="small"
                aria-label={`Move ${facetLabels[sort.facet]} sort priority up`}
                className={classes.priorityButton}
                disabled={idx === 0}
                onClick={() => setFacetPriority(sort.facet, idx - 1)}>
                <UpArrowIcon />
              </IconButton>
              <IconButton
                size="small"
                aria-label={`Move ${facetLabels[sort.facet]} sort priority down`}
                className={classes.priorityButton}
                disabled={idx === sortConfig.length - 1}
                onClick={() => setFacetPriority(sort.facet, idx + 1)}>
                <DownArrowIcon />
              </IconButton>
            </Box>
          ))}
          <Box marginTop={1.5}>
            <Alert severity="info">
              <AlertTitle>Configure the sort parameters for your virtual ward</AlertTitle>
              The sort order is applied from top to bottom, so the first sort parameter is the most
              important, and the last sort parameter is the least important.
            </Alert>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  popover: {
    marginTop: theme.spacing(1),
  },
  checkbox: {
    padding: theme.spacing(0.75),
  },
  title: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  activeFilterButton: {
    backgroundColor: theme.palette.common.white,
  },
  filterRoot: {
    marginBottom: theme.spacing(1),
  },
  directionSelect: {
    color: theme.palette.primary.dark,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    textAlign: 'right',
    margin: theme.spacing(0, 1),
  },
  directionSelectChanged: {
    color: theme.palette.primary.main,
  },
  priorityButton: {
    padding: theme.spacing(0.15),
  },
  resetButton: {
    lineHeight: 1,
    padding: theme.spacing(0.5),
    textTransform: 'none',
  },
}));
