import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { gql } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  EhrIntegrationTriggerTypeInternal,
  EhrIntegrationTypeInternal,
  useCreateEhrIntegrationMutationInternal,
} from '@/generated/graphql-internal';
import { getMutationErrors } from '@/AuthorizedApolloClientProvider';
import { muiFormikGetFieldProps } from '@/helpers/formik';
import { useModal } from 'mui-modal-provider';
import {
  formatIntegrationTriggerType,
  formatIntegrationType,
} from '@/components/EhrIntegrations/format';

export const CREATE_EHR_INTEGRATION_MUTATION = gql`
  mutation CreateEhrIntegration(
    $carePathwayId: ID!
    $organizationId: ID!
    $ehrIntegrationInput: CreateEhrIntegrationInput!
  ) {
    createEhrIntegration(
      carePathwayId: $carePathwayId
      organizationId: $organizationId
      ehrIntegration: $ehrIntegrationInput
    ) {
      id
    }
  }
`;

const formSchema = Yup.object().shape({
  name: Yup.string().required('Integration name is required'),
  triggerType: Yup.string().required().oneOf(Object.values(EhrIntegrationTriggerTypeInternal)),
  integrationType: Yup.string().required().oneOf(Object.values(EhrIntegrationTypeInternal)),
  carePathwayId: Yup.string().required(),
});

interface CreateIntegrationModalProps extends DialogProps {
  carePathwayId: string;
  organizationId: string;
  onCancel: () => void;
  onCreated: () => void;
}

type FormValues = Yup.InferType<typeof formSchema>;

export function CreateIntegrationModal({
  carePathwayId,
  organizationId,
  open,
  onCancel,
  onCreated,
}: CreateIntegrationModalProps) {
  const [createEhrIntegration, { loading: isSubmitting, error: createIntegrationError }] =
    useCreateEhrIntegrationMutationInternal({
      onCompleted: () => {
        onCreated();
      },
      onError: () => undefined,
    });

  const formik = useFormik<FormValues>({
    initialValues: {
      name: 'Quicksilva Toolbar Data Push',
      carePathwayId: carePathwayId,
      integrationType: EhrIntegrationTypeInternal.QuicksilvaMessageInternal,
      triggerType: EhrIntegrationTriggerTypeInternal.ManualInternal,
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      await createEhrIntegration({
        variables: {
          carePathwayId: carePathwayId,
          organizationId: organizationId,
          ehrIntegrationInput: {
            integrationType: values.integrationType,
            name: values.name,
            triggerType: values.triggerType,
          },
        },
      });
    },
  });

  const { argErrors } = getMutationErrors(createIntegrationError);
  const getFieldProps = muiFormikGetFieldProps(formik, argErrors);

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          Add Integration
        </DialogTitle>
        <DialogContent>
          <Stack marginTop={2} spacing={3}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label="Integration Type"
              select
              {...getFieldProps('integrationType')}>
              {Object.values(EhrIntegrationTypeInternal).map((type) => (
                <MenuItem key={type} value={type} aria-label={type}>
                  <Stack>
                    <Typography textTransform="capitalize" variant="body1">
                      {formatIntegrationType(type)}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label="Trigger Type"
              // disabled for now - no UI for configuring integration config yet
              disabled={true}
              select
              {...getFieldProps('triggerType', {
                defaultHelperText:
                  'If a Data Point or Periodic Summary integration is required, please contact software',
              })}>
              {Object.values(EhrIntegrationTriggerTypeInternal).map((triggerType) => (
                <MenuItem key={triggerType} value={triggerType} aria-label={triggerType}>
                  <Stack>
                    <Typography textTransform="capitalize" variant="body1">
                      {formatIntegrationTriggerType(triggerType, undefined)}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label="Display Name"
              {...getFieldProps('name')}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>

          <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

interface UseCreateIntegrationModalProps {
  onCreated: () => void;
}

export function useCreateIntegrationModal({ onCreated }: UseCreateIntegrationModalProps) {
  const { showModal } = useModal();

  return {
    showModal: ({
      carePathwayId,
      organizationId,
    }: Pick<CreateIntegrationModalProps, 'carePathwayId' | 'organizationId'>) => {
      const modal = showModal(
        CreateIntegrationModal,
        {
          carePathwayId,
          organizationId,
          onCreated: () => {
            onCreated();
            modal.hide();
          },
          onCancel: () => {
            modal.hide();
          },
        },
        { destroyOnClose: true },
      );
    },
  };
}
