import React, { useState } from 'react';

import PatientBulkUploadModal from '@/components/PatientBulkUploadModal';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { toast } from 'sonner';

import {
  ManagePatientItemFragment,
  PatientAdmissionFilter,
  useGetPatientsQuery,
} from '@/generated/graphql';

import PatientList from './PatientList';
import { PageTitle } from '@/components/PageTitle';
import { RestrictedRouteTester } from '@/components/ProtectedRoute';
import { PageContainer } from '@/components/PageContainer';
import { useMeActingOrganizationFeature } from '@/hooks/useAuth';
import posthog from 'posthog-js';
import { AdmissionFilterTabs } from './components/AdmissionFilterTabs';

export const canAccessManagePatientsPage: RestrictedRouteTester = ({ permissions }) =>
  permissions['view_patient_archive'];

export const QUERY_PATIENTS = gql`
  fragment ManagePatientItem on Patient {
    id
    createdAt
    firstName
    lastName
    gender
    birthDate
    wards {
      id
      name
      createdAt
    }
    practices: organizations(type: "practice") {
      ...PatientPracticeAssignment
    }
    nationalIdentifier {
      ...NationalIdentifierDisplay
    }
  }

  query GetPatients($admissionFilter: PatientAdmissionFilter) {
    patients(isTestPatient: false, admissionFilter: $admissionFilter) {
      ...ManagePatientItem
    }
  }
`;

const ManagePatients = () => {
  const { push } = useHistory();

  const hasWardsFeature = useMeActingOrganizationFeature('wards');
  const [admissionFilter, setAdmissionFilter] = useState(
    hasWardsFeature ? PatientAdmissionFilter.ActiveAdmission : PatientAdmissionFilter.All,
  );
  const handleAdmissionFilterChange = (filter: PatientAdmissionFilter) => {
    setAdmissionFilter(filter);
    posthog.capture('patient_filter_changed', {
      previous_filter: admissionFilter,
      new_filter: filter,
    });
  };

  const {
    data,
    refetch: reloadPatients,
    loading,
    error,
  } = useGetPatientsQuery({
    onError: () => toast.error('An error occurred while loading patients'),
    variables: {
      admissionFilter,
    },
  });

  const patients = data?.patients;

  const [open, setOpen] = useState(false);

  return (
    <>
      <PageContainer>
        <PageTitle
          title="Manage Patients"
          subtitle="Add or update patients and manage clinical organisation assignments"
        />
        <AdmissionFilterTabs
          admissionFilter={admissionFilter}
          onFilterChange={handleAdmissionFilterChange}
        />
        <PatientList
          admissionFilter={admissionFilter}
          patients={patients ?? []}
          loadError={error}
          isLoading={loading && !patients?.length}
          reloadPatients={reloadPatients}
          openPatientBulkUploadModal={() => setOpen(true)}
          onRowClick={(rowData: ManagePatientItemFragment) => push(`/patient/${rowData.id}`)}
        />
      </PageContainer>
      <PatientBulkUploadModal
        open={open}
        onClose={() => setOpen(false)}
        reloadPatients={reloadPatients}
      />
    </>
  );
};

export default ManagePatients;
