import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  PatientChatMessageViewFragment,
  PatientChatParticipantViewFragment,
} from '@/generated/graphql';

import { MessageView } from './MessageView';

interface MessageListProps {
  messages: readonly PatientChatMessageViewFragment[];
  loading: boolean;
  patientUserId: string;
  userId: string;
  participants: PatientChatParticipantViewFragment[];
}

const BOTTOM_SCROLL_BUFFER = 100;

export function MessageList({
  messages,
  patientUserId,
  userId,
  participants,
  loading,
}: MessageListProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  // Scroll to the bottom when the component mounts
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, []);

  const [scrollOffset, setScrollOffset] = useState(0);

  const onScroll = useCallback(() => {
    if (containerRef.current) {
      setScrollOffset(containerRef.current.scrollTop);
    }
  }, []);

  // Track scroll position
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    container.addEventListener('scroll', onScroll);
    return () => container.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  const isAtBottom = useMemo(() => {
    if (!containerRef.current) return true;

    // If the container is not scrollable, consider  it to be at the bottom
    const isScrollable = containerRef.current.scrollHeight > containerRef.current.clientHeight;

    return (
      !isScrollable ||
      scrollOffset >=
        containerRef.current?.scrollHeight -
          containerRef.current?.clientHeight -
          BOTTOM_SCROLL_BUFFER
    );
  }, [scrollOffset]);

  // Scroll to the bottom when new messages are added (if the user is already at the bottom, with a small buffer)
  useLayoutEffect(() => {
    if (containerRef.current) {
      if (isAtBottom) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }
  }, [messages, isAtBottom]);

  return (
    <Box flex="1 1 0" display="flex" flexDirection="column" position="relative">
      <Stack
        role="log"
        aria-busy={loading}
        aria-relevant="additions text"
        aria-label="Chat messages"
        flex="1 1 0"
        sx={{ overflowY: 'auto' }}
        ref={containerRef}
        paddingX={2}
        paddingY={1}>
        {!loading && messages.length === 0 && (
          <>
            <Typography variant="h5" align="center" color="textSecondary">
              No messages
            </Typography>
            <Typography align="center" color="textSecondary">
              Start the conversation by typing a message below
            </Typography>
          </>
        )}
        {messages.map((message, i) => (
          <MessageView
            key={message.id}
            message={message}
            user={participants.find((p) => p.id === message.createdById)}
            patientUserId={patientUserId}
            currentUserId={userId}
            previousMessage={i > 0 ? messages[i - 1] : undefined}
            nextMessage={i < messages.length - 1 ? messages[i + 1] : undefined}
          />
        ))}
      </Stack>
      {!isAtBottom ? (
        <IconButton
          aria-label="Scroll to bottom"
          color="primary"
          onClick={() => {
            if (containerRef.current) {
              containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight - 1,
                behavior: 'smooth',
              });
            }
          }}
          sx={{
            position: 'absolute',
            bottom: 16,
            right: 32,
            zIndex: 1000,
            backgroundColor: 'white',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            '&:hover': {
              backgroundColor: 'white',
              opacity: 0.9,
            },
          }}>
          <ArrowDownwardIcon />
        </IconButton>
      ) : null}
    </Box>
  );
}
