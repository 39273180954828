import React, { useEffect } from 'react';
import { addSeconds, differenceInSeconds, format, subSeconds } from 'date-fns';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { makeStyles } from '@mui/styles';
import { formatNationalIdentifier } from '@/components/NationalIdentifierDisplay';
import { ErrorDisplay } from '@/components/ErrorDisplay';
import { gql } from '@apollo/client';
import { PatientDetailsFragment, useEcgTraceQuery } from '@/generated/graphql';
import posthog from '@/helpers/posthog';
import { useState } from 'react';
import { EcgTrace } from './EcgTrace';

export const QUERY_ECG_TRACE = gql`
  fragment PatientEcgTrace on EcgTrace {
    recordTime
    vitals {
      ecg
      magnification
    }
  }

  query EcgTrace($patientId: ID!, $startTime: Date!, $endTime: Date!) {
    ecgTrace(patientId: $patientId, startTime: $startTime, endTime: $endTime) {
      ...PatientEcgTrace
    }
  }
`;

interface EcgTraceModalProps extends DialogProps {
  patient: PatientDetailsFragment;
  startTime: Date;
  endTime: Date;
  onCancel: () => void;
}

enum ZoomSettings {
  ZoomIn,
  ZoomOut,
}

const SECONDS_IN_MODAL = 90;
const ZOOM_IN_X_RESOLUTION = 200; // in ms
const ZOOM_OUT_X_RESOLUTION = 1000; // in ms

export const EcgTraceModal = ({
  open,
  patient,
  startTime,
  endTime,
  onCancel,
}: EcgTraceModalProps) => {
  const classes = useStyles();
  const [currentStartTime, setCurrentStartTime] = useState(startTime);
  const [currentEndTime, setCurrentEndTime] = useState(addSeconds(startTime, SECONDS_IN_MODAL));
  const [zoomSetting, setZoomSetting] = useState<ZoomSettings>(ZoomSettings.ZoomIn);
  const chartContainerRef = React.useRef<HTMLDivElement>(null);

  // The ECG payload groups 128 data points per second from the initial recording time
  // Fetch one extra second of data to ensure the start of the time window has samples
  const { data, loading, error, refetch } = useEcgTraceQuery({
    variables: {
      patientId: patient.id,
      startTime: subSeconds(currentStartTime, 1).toISOString(),
      endTime: currentEndTime.toISOString(),
    },
  });

  useEffect(() => {
    if (open) {
      posthog.capture('ecg_trace_modal_opened', {
        patientId: patient.id,
      });
    }
  }, [open, patient.id]);

  useEffect(() => {
    posthog.capture('selected_ecg_zoom', {
      zoomSetting,
    });
  }, [zoomSetting]);

  useEffect(() => {
    if (chartContainerRef.current) {
      const zoomRatio =
        zoomSetting === ZoomSettings.ZoomIn
          ? ZOOM_OUT_X_RESOLUTION / ZOOM_IN_X_RESOLUTION
          : ZOOM_IN_X_RESOLUTION / ZOOM_OUT_X_RESOLUTION;

      chartContainerRef.current.scrollLeft *= zoomRatio;
    }
  }, [zoomSetting]);

  const handlePaginate = async (newStartTime: Date) => {
    const newEndTime = addSeconds(newStartTime, SECONDS_IN_MODAL);

    setCurrentStartTime(newStartTime);
    setCurrentEndTime(newEndTime);

    await refetch({
      patientId: patient.id,
      startTime: subSeconds(newStartTime, 1).toISOString(), // Fetch one extra second of data to ensure the start of the time window has samples
      endTime: newEndTime.toISOString(),
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="lg"
      fullWidth
      aria-labelledby="ecg_trace-dialog-title"
      aria-describedby="ecg_trace-dialog-description">
      <DialogTitle id="ecg_trace-dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {patient.firstName} {patient.lastName}
            {patient.nationalIdentifier &&
              ` (${formatNationalIdentifier(patient.nationalIdentifier)})`}
            &nbsp;&nbsp;|&nbsp;&nbsp;ECG Trace on {format(startTime, 'dd-MMM-yyyy')}&nbsp; (
            {format(currentStartTime, 'HH:mm:ss')} - {format(currentEndTime, 'HH:mm:ss')})
          </Typography>
          <IconButton onClick={onCancel} size="small" aria-label="Close ecg trace">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {loading && (
          <Box className={classes.loadingContainer}>
            <CircularProgress className={classes.loadingIndicator} />
          </Box>
        )}
        {!loading && data && (
          <>
            <Box className={classes.chartContainer} ref={chartContainerRef}>
              <EcgTrace
                startTime={currentStartTime}
                data={data.ecgTrace}
                xRange={differenceInSeconds(currentEndTime, currentStartTime) * 1000}
                yRange={zoomSetting === ZoomSettings.ZoomIn ? 2 : 10}
                xTickResolution={zoomSetting === ZoomSettings.ZoomIn ? 200 : 1000}
              />
            </Box>
            {data.ecgTrace.length === 0 && (
              <div className={classes.overlay}>
                <Typography variant="h5" color="primary.dark" fontWeight={'bold'}>
                  No ECG data in this section
                </Typography>
              </div>
            )}
          </>
        )}
        {error && <ErrorDisplay message="Failed to load ECG trace" retry={refetch} />}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={1} role="tablist" aria-label="ecg zoom level">
          <Tooltip title={`Previous ${SECONDS_IN_MODAL} seconds`} enterDelay={800}>
            <span>
              <IconButton
                className={classes.toolButton}
                onClick={() => handlePaginate(subSeconds(currentStartTime, SECONDS_IN_MODAL))}
                role="tab"
                disabled={currentStartTime.getTime() === startTime.getTime()}>
                <ChevronLeftIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Zoom In" enterDelay={800}>
            <IconButton
              className={`${classes.toolButton} ${
                zoomSetting === ZoomSettings.ZoomIn ? classes.active : ''
              }`}
              onClick={() => setZoomSetting(ZoomSettings.ZoomIn)}
              role="tab"
              aria-selected={zoomSetting === ZoomSettings.ZoomIn}>
              <ZoomInIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Zoom Out" enterDelay={800}>
            <IconButton
              className={`${classes.toolButton} ${
                zoomSetting === ZoomSettings.ZoomOut ? classes.active : ''
              }`}
              onClick={() => setZoomSetting(ZoomSettings.ZoomOut)}
              role="tab"
              aria-selected={zoomSetting === ZoomSettings.ZoomOut}>
              <ZoomOutIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Next ${SECONDS_IN_MODAL} seconds`} enterDelay={800}>
            <span>
              <IconButton
                className={classes.toolButton}
                onClick={() => handlePaginate(addSeconds(currentStartTime, SECONDS_IN_MODAL))}
                role="tab"
                disabled={currentEndTime.getTime() === endTime.getTime()}>
                <ChevronRightIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export const useEcgTraceModal = () => {
  const { showModal } = useModal();

  return {
    showEcgTraceModal: (props: Pick<EcgTraceModalProps, 'patient' | 'startTime' | 'endTime'>) => {
      const modal: ShowFnOutput<EcgTraceModalProps> = showModal(
        EcgTraceModal,
        {
          ...props,
          onCancel: () => modal.hide(),
        },
        { destroyOnClose: true },
      );

      return modal;
    },
  };
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 515,
  },
  loadingIndicator: {
    display: 'block',
    margin: '0 auto',
    marginTop: theme.spacing(2),
  },
  chartContainer: {
    overflowX: 'auto',
    overflowY: 'hidden',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    marginRight: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 5,
    backgroundColor: 'gray',
  },
  overlay: {
    display: 'flex',
    position: 'absolute',
    inset: 0, // fit the overlay in the chart container
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    opacity: 0.6,
    marginTop: theme.spacing(6),
    paddingBottom: theme.spacing(20),
  },
  toolButton: {
    padding: theme.spacing(1.5, 1.5),
    backgroundColor: 'transparent',
    color: theme.palette.primary.dark,
    transition: theme.transitions.create(['background', 'color']),
  },
  active: {
    color: theme.palette.background.paper,
    background: theme.palette.primary.dark,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
}));
