import React, { useState } from 'react';
import { Button, Paper, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';

import {
  PatientFlagOverviewFragment,
  PatientFlagType,
  useUpdatePatientFlagsMutation,
} from '@/generated/graphql';
import { gql } from '@apollo/client';
import { toast } from 'sonner';
import { PatientFlagItem } from './PatientFlagItem';

interface PatientFlagsProps {
  patientId: string;
  patientFlags: PatientFlagOverviewFragment[];
  refresh: () => void;
}

export const UPDATE_FLAGS = gql`
  mutation UpdatePatientFlags($patientId: ID!, $patientFlags: [PatientFlagInput!]!) {
    updatePatientFlags(patientFlagUpdate: { PatientId: $patientId, patientFlags: $patientFlags }) {
      id
      wardAdmission {
        ...PatientAdmissionOverview
      }
    }
  }
`;

export function PatientFlags({ patientId, patientFlags, refresh }: PatientFlagsProps) {
  const classes = useStyles();

  const [currentPatientFlagsState, setCurrentPatientFlagsState] =
    useState<PatientFlagOverviewFragment[]>(patientFlags);

  const [updateFlags, { loading: updatingPatientFlags }] = useUpdatePatientFlagsMutation({
    onCompleted: () => {
      toast.success('Patient flags updated');
      refresh();
    },
    onError: () => {
      toast.error('Failed to update patient flags');
    },
  });

  const resetChanges = () => setCurrentPatientFlagsState(patientFlags);

  const setFeatureFlag = (flagType: PatientFlagType, value: boolean) => {
    setCurrentPatientFlagsState(
      currentPatientFlagsState.map((patientFlag) =>
        patientFlag.type === flagType ? { ...patientFlag, active: value } : patientFlag,
      ),
    );
  };

  const isFlagPendingChange = (patientFlag: PatientFlagOverviewFragment) =>
    patientFlag.active !== patientFlags.find((f) => f.type === patientFlag.type)?.active;

  const hasPendingChanges = currentPatientFlagsState.some((patientFlag) =>
    isFlagPendingChange(patientFlag),
  );

  const saveChanges = () => {
    // Create an array of all patient flags that have been changed
    const changedPatientFlags = currentPatientFlagsState.filter(isFlagPendingChange);

    updateFlags({
      variables: {
        patientId,
        patientFlags: changedPatientFlags.map((flag) => ({
          type: flag.type,
          active: flag.active,
        })),
      },
    });
  };

  return (
    <Paper className={classes.paperRoot}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <Typography gutterBottom className={classes.subtitle}>
          <FlagOutlinedIcon />
          Patient flags
        </Typography>
      </Stack>
      <Stack gap={2}>
        {currentPatientFlagsState.map((patientFlag, i) => {
          const isLast = i === currentPatientFlagsState.length - 1;
          return (
            <PatientFlagItem
              key={patientFlag.type}
              patientFlag={patientFlag}
              isLast={isLast}
              onChange={(patientFlagType, value) => setFeatureFlag(patientFlagType, value)}
              isFlagPendingChange={isFlagPendingChange}
            />
          );
        })}
      </Stack>
      <Stack direction="row" justifyContent="flex-end" gap={2} marginTop={2}>
        <Button
          onClick={() => resetChanges()}
          disabled={!hasPendingChanges || updatingPatientFlags}>
          Cancel
        </Button>
        <Button
          onClick={() => saveChanges()}
          variant="contained"
          disabled={!hasPendingChanges || updatingPatientFlags}>
          Save changes
        </Button>
      </Stack>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 500,
  },
}));
