/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ComponentProps,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { useConfirm } from 'material-ui-confirm';
import { styled } from '@mui/material/styles';
import _ from 'lodash';

import { isDefined } from '@/helpers/isDefined';
import posthog from 'posthog-js';

export type StepHandler<TJourneyStep extends string> =
  | (() => Promise<TJourneyStep | boolean>)
  | (() => TJourneyStep | boolean)
  | null;

interface JourneyModalContextProps<
  TJourneyState extends Record<string, unknown>,
  TJourneyStep extends string = string,
> {
  /** The steps of the journey */
  steps: Record<TJourneyStep, StepProps<TJourneyState>>;
  /** The current active step */
  activeStep: TJourneyStep;
  /** Set the active step */
  setActiveStep: (step: TJourneyStep) => void;
  /**
   * Proceed to the next, non skipped, step.
   *
   * This is the same as clicking the continue button.
   * When state is provided, it will be merged into the current journey state.
   * This is useful for ensuring the state is updated before calculating which steps are
   * skipped.
   */
  gotoNextStep: (newState?: Partial<TJourneyState>) => void;
  /** The current state of the journey */
  currentJourneyState: TJourneyState;
  /** Shallow merge the given state into the current journey state */
  updateJourneyState: (newState: Partial<TJourneyState>) => void;
  /** Register a handler to be called when the user tries to proceed to the next step */
  handleStep: (handler: StepHandler<TJourneyStep>) => void;
  /* * The current submitting state of the journey */
  isSubmitting: boolean;
  /* Allows a step to set the submitting state of the journey (causing the continue button to be disabled) */
  setIsSubmitting: (isSubmitting: boolean) => void;
  /** Close the journey modal */
  close: () => void;
}

const defaultContextValue: JourneyModalContextProps<any, any> = {
  steps: {},
  activeStep: '',
  setActiveStep: () => undefined,
  gotoNextStep: () => undefined,
  currentJourneyState: {} as unknown,
  updateJourneyState: () => undefined,
  handleStep: () => undefined,
  isSubmitting: false,
  setIsSubmitting: () => undefined,
  close: () => undefined,
};

export const JourneyModalContext =
  createContext<JourneyModalContextProps<any, any>>(defaultContextValue);

/** Specific context accessor */
export function useJourneyModalContext<
  TJourneyState extends Record<string, any>,
  TJourneyStep extends string = string,
>(): JourneyModalContextProps<TJourneyState, TJourneyStep> {
  return useContext(JourneyModalContext) as JourneyModalContextProps<TJourneyState, TJourneyStep>;
}

/**
 * A journey step configuration value.
 *
 * This can either be a direct value (e.g. a string, boolean) or a function which accepts the current
 */
type JourneyConfigValue<
  TJourneyState extends Record<string, any>,
  TConfigValue extends boolean | string | undefined,
> = TConfigValue | ((state: TJourneyState) => TConfigValue);

interface StepProps<
  TJourneyState extends Record<string, any>,
  TComponent extends React.ComponentType<any> = React.FC<any>,
> {
  /** The component to render for this step */
  component: TComponent;
  componentProps?: React.ComponentProps<TComponent>;
  /** Configures whether the continue button is clickable */
  canProceed?: JourneyConfigValue<TJourneyState, boolean>;
  /** Configures visibility of the back button */
  canGoBack?: JourneyConfigValue<TJourneyState, boolean>;
  /** Label inside the journey progress display */
  stepLabel: JourneyConfigValue<TJourneyState, string>;
  /**
   * Sub label for the journey progress display.
   * This can be used to add additional context under each step, such as the current status of the step.
   * @example "Resource Created", "Updated"
   * */
  subLabel?: JourneyConfigValue<TJourneyState, string | undefined>;
  /** Heading within the render for this step. */
  stepHeading?: JourneyConfigValue<TJourneyState, string>;
  /** Label for the next/continue button */
  nextButtonLabel?: JourneyConfigValue<TJourneyState, string>;
  /** Props for the next/continue button */
  nextButtonProps?: ComponentProps<typeof Button>;
  /** Label for the back button (when visible) */
  backButtonLabel?: JourneyConfigValue<TJourneyState, string>;
  /** Configures whether the step is optional */
  optional?: JourneyConfigValue<TJourneyState, boolean>;
  /** Configures whether the step is hidden */
  hidden?: JourneyConfigValue<TJourneyState, boolean | 'always'>;
  /** If set to true, the step will be skipped when a preceding step navigates to the "next step" */
  skip?: JourneyConfigValue<TJourneyState, boolean>;
  /** If set, the button will be a submit button and will submit the form with the given id  */
  controlsForm?: string;
  /** If set to true, the journey will close without warning during this step  */
  closeWithoutWarning?: boolean;
}

interface JourneyModalProps<
  TJourneyState extends Record<string, any>,
  TJourneyStep extends string = string,
> {
  /** Name of this user journey (used for analytics) */
  journeyName: string;
  /** Title of the modal */
  title: JourneyConfigValue<TJourneyState, string>;
  /** Configured steps */
  steps: Record<TJourneyStep, StepProps<TJourneyState>>;
  /** Initial journey state */
  defaultJourneyState: TJourneyState;
  /** Whether the dialog is currently open */
  open: boolean;
  /** Callback when closing the modal is requested */
  onClose: () => void;
  /** Props to customise the journey cancellation dialog */
  cancelConfirmationProps?: {
    title?: JourneyConfigValue<TJourneyState, string>;
    description?: JourneyConfigValue<TJourneyState, string>;
  };
}

/**
 * A modal for guiding the user through a multi-step journey.
 */
export function JourneyModal<
  TJourneyState extends Record<string, any>,
  TJourneyStep extends string = string,
>({
  journeyName,
  steps,
  defaultJourneyState: initialJourneyState,
  onClose,
  title,
  cancelConfirmationProps,
}: JourneyModalProps<TJourneyState, TJourneyStep>) {
  const firstNonSkippedStep = useMemo(() => {
    const stepKeys = Object.keys(steps) as TJourneyStep[];
    const firstStep = stepKeys.find((step) => {
      const stepProps = steps[step];
      return !evaluateConfigValue(stepProps.skip, initialJourneyState);
    });

    if (!firstStep) {
      throw new Error('No steps are available');
    }

    return firstStep;
  }, [initialJourneyState, steps]);
  const initialStepIndex = Object.keys(steps).indexOf(firstNonSkippedStep);

  const [activeStep, setActiveStep] = useState(firstNonSkippedStep);
  const [currentJourneyState, setCurrentJourneyState] = useState(initialJourneyState);
  const [completedSteps, setCompletedSteps] = useState<TJourneyStep[]>([]);
  const [skippedSteps, setSkippedSteps] = useState<TJourneyStep[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const nextStepHandler = useRef<StepHandler<TJourneyStep>>(null);
  const confirm = useConfirm();

  const handleStep = useRef((handler: StepHandler<TJourneyStep>) => {
    nextStepHandler.current = handler;
  });

  useEffect(() => {
    posthog.capture('journey_started', {
      journey: journeyName,
    });
  }, [journeyName]);

  useEffect(() => {
    posthog.capture('journey_step_changed', {
      journey: journeyName,
      step: activeStep,
    });
  }, [activeStep, journeyName]);

  const setNextStep = useCallback(
    (step: TJourneyStep, skipped = false) => {
      // Clear any registered handlers
      nextStepHandler.current = null;

      setCompletedSteps((prevState) => [...prevState, activeStep]);
      // Make sure we remove the step from the skipped list if the user goes back to it and then proceeds
      setSkippedSteps((prevState) =>
        skipped ? [...prevState, activeStep] : prevState.filter((s) => s !== activeStep),
      );

      setActiveStep(step);
    },
    [activeStep],
  );

  const gotoPreviousStep = useCallback(() => {
    // The last step in the array is the current step, so we need to get the one before that
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [lastStep, ...previousSteps] = _.reverse(completedSteps);

    setActiveStep(lastStep);
    // We need to remove the last step from the visited steps array
    setCompletedSteps(_.reverse(previousSteps) ?? []);
  }, [completedSteps, setActiveStep, setCompletedSteps]);

  const gotoNextStep = useCallback(
    (skipped?: boolean, newState?: Partial<TJourneyState>) => {
      const stepKeys = Object.keys(steps) as TJourneyStep[];

      /**
       *  Calculate and set the new state. This is important when the current state is used to determine
       * whether the next step should be skipped.
       */
      const combinedState = {
        ...currentJourneyState,
        ...newState,
      };

      // Only update the state if there is a new state (to avoid wasteful re-renders)
      if (newState) {
        setCurrentJourneyState(combinedState);
      }

      // If there is no handler, just go to the next step in the list
      const currentStepIndex = stepKeys.findIndex((step) => step === activeStep);

      // get all steps after the current step
      const stepsAfterCurrent = stepKeys.slice(currentStepIndex + 1);

      // Test if the step should be skipped based on the current state
      const testSkipped = (step: TJourneyStep) => {
        const stepProps = steps[step];
        return isDefined(stepProps.skip) && typeof stepProps.skip === 'function'
          ? stepProps.skip(combinedState)
          : stepProps.skip;
      };

      // Get the next step that is not skipped
      const nextStep = stepsAfterCurrent.find((step) => !testSkipped(step));

      // If there is no next step, close the modal
      if (nextStep) {
        setNextStep(nextStep as TJourneyStep, skipped);
      } else {
        onClose();
      }
    },
    [activeStep, onClose, setNextStep, steps, currentJourneyState],
  );

  const skipCurrentStep = useCallback(() => {
    posthog.capture('journey_step_skipped', {
      journey: journeyName,
      step: activeStep,
    });
    gotoNextStep(true);
  }, [gotoNextStep, activeStep, journeyName]);

  const doNextStep = async () => {
    if (nextStepHandler.current) {
      const nextStep = await nextStepHandler.current();
      if (typeof nextStep === 'string') {
        setNextStep(nextStep);
      } else if (nextStep === true) {
        gotoNextStep();
      }
    } else {
      gotoNextStep();
    }
  };

  const handleCancel = useCallback(() => {
    posthog.capture('journey_cancelled', {
      journey: journeyName,
    });
    onClose();
  }, [onClose, journeyName]);

  /**
   * Memoize the context value to try avoid unnecessary re-renders
   */
  const memoizedContextValue = useMemo(() => {
    return {
      steps,
      activeStep,
      setActiveStep: setNextStep,
      currentJourneyState,
      updateJourneyState: (newState: Partial<TJourneyState>) => {
        setCurrentJourneyState((prevState) => ({ ...prevState, ...newState }));
      },
      handleStep: handleStep.current,
      isSubmitting,
      setIsSubmitting,
      gotoNextStep: (newState?: Partial<TJourneyState>) => {
        gotoNextStep(false, newState);
      },
      close: () => onClose(),
    };
  }, [activeStep, currentJourneyState, steps, isSubmitting, setNextStep, onClose, gotoNextStep]);

  const activeStepIndex = Object.keys(steps).indexOf(activeStep);
  const activeStepProps = steps[activeStep];
  const canProceedFromStep = isDefined(activeStepProps?.canProceed)
    ? evaluateConfigValue(activeStepProps.canProceed, currentJourneyState)
    : true;

  /**
   * Hide steps that are marked as hidden, unless they have been visited or are the active step
   */
  const shouldShowStep = (step: TJourneyStep) => {
    const stepProps = steps[step];

    const isHidden =
      isDefined(stepProps.hidden) && evaluateConfigValue(stepProps.hidden, currentJourneyState);

    if (!isDefined(isHidden) || isHidden === false) {
      return true;
    }

    if (isHidden === 'always') {
      return false;
    }

    if (completedSteps.includes(step)) {
      return true;
    }

    if (activeStep === step) {
      return true;
    }

    return false;
  };

  const numberOfVisibleSteps = Object.keys(steps).filter((step) =>
    shouldShowStep(step as TJourneyStep),
  ).length;

  /**
   * Whether the back button should be visible
   *
   * The back button should be visible if:
   * - The current step is not the first step
   * - The step has a custom canGoBack function that returns true (based on the current state)
   */
  const canGoBack = isDefined(activeStepProps.canGoBack)
    ? activeStepIndex > initialStepIndex &&
      evaluateConfigValue(activeStepProps.canGoBack, currentJourneyState)
    : false;

  const isStepOptional = (step: TJourneyStep) => {
    const stepProps = steps[step];

    if (!isDefined(stepProps.optional)) {
      return false;
    }

    return evaluateConfigValue(stepProps.optional, currentJourneyState);
  };

  const isStepSkipped = (step: TJourneyStep) => {
    const stepProps = steps[step];

    const hasStepBeenPassed = activeStepIndex > Object.keys(steps).indexOf(step);

    return (
      skippedSteps.includes(step) ||
      (hasStepBeenPassed && evaluateConfigValue(stepProps.skip, currentJourneyState) === true)
    );
  };

  const handleCloseClick = useCallback(async () => {
    posthog.capture('journey_cancel_requested', {
      journey: journeyName,
    });

    if (activeStepProps.closeWithoutWarning !== true) {
      try {
        await confirm({
          title: cancelConfirmationProps?.title
            ? evaluateConfigValue(cancelConfirmationProps.title, currentJourneyState)
            : 'Confirm Cancel',
          description: (
            <DialogContentText>
              {cancelConfirmationProps?.description
                ? evaluateConfigValue(cancelConfirmationProps.description, currentJourneyState)
                : 'Are you sure you want to cancel?'}
            </DialogContentText>
          ),
          confirmationButtonProps: {
            color: 'error',
            variant: 'contained',
          },
        });

        handleCancel();
      } catch {
        /* material-ui-confirm throws when cancelled :( */
        posthog.capture('journey_cancel_cancelled', {
          journey: journeyName,
        });
      }
    } else {
      handleCancel();
    }
  }, [
    activeStepProps.closeWithoutWarning,
    confirm,
    cancelConfirmationProps,
    currentJourneyState,
    handleCancel,
    journeyName,
  ]);

  /** Current active step render component */
  const ActiveStepComponent = activeStepProps.component;

  return (
    <JourneyModalContext.Provider value={memoizedContextValue}>
      <Dialog
        open={true}
        maxWidth="md"
        fullWidth
        componentsProps={{
          backdrop: {
            sx: {
              backdropFilter: 'blur(5px)',
            },
          },
        }}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {evaluateConfigValue(title, currentJourneyState)}
            <IconButton onClick={handleCloseClick} size="large" aria-label="Cancel Journey">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {numberOfVisibleSteps > 1 && (
            <Stepper
              activeStep={activeStepIndex}
              alternativeLabel
              role="group"
              aria-label="progress"
              sx={{
                paddingTop: 0,
                paddingBottom: 4,
              }}>
              {(
                Object.entries(steps) as Entries<Record<TJourneyStep, StepProps<TJourneyState>>>
              ).map(([step, stepProps], index) =>
                shouldShowStep(step as TJourneyStep) ? (
                  <Step
                    key={index}
                    completed={activeStepIndex > index}
                    role="listitem"
                    disabled={isStepSkipped(step as TJourneyStep) && activeStep !== step}
                    active={activeStepIndex === index}
                    aria-current={activeStepIndex === index ? 'step' : undefined}
                    aria-labelledby={`journey-step-${step}-label`}>
                    <JourneyStepLabel
                      label={evaluateConfigValue(stepProps.stepLabel, currentJourneyState)}
                      step={step}
                      isOptional={isStepOptional(step as TJourneyStep)}
                      isSkipped={isStepSkipped(step as TJourneyStep)}
                      subLabel={evaluateConfigValue(stepProps.subLabel, currentJourneyState)}
                    />
                  </Step>
                ) : null,
              )}
            </Stepper>
          )}
          <Box marginX={2} marginBottom={1}>
            {activeStepProps.stepHeading ? (
              <JourneyHeading component="h3" marginBottom={0.5}>
                {evaluateConfigValue(activeStepProps.stepHeading, currentJourneyState)}
              </JourneyHeading>
            ) : null}
            <ActiveStepComponent {...activeStepProps.componentProps} />
          </Box>
        </DialogContent>
        <DialogActions>
          {canGoBack ? (
            <Button variant="text" onClick={() => gotoPreviousStep()}>
              {activeStepProps.backButtonLabel
                ? evaluateConfigValue(activeStepProps.backButtonLabel, currentJourneyState)
                : 'Back'}
            </Button>
          ) : null}
          {isStepOptional(activeStep) ? (
            <Button variant="text" onClick={() => skipCurrentStep()}>
              Skip
            </Button>
          ) : null}
          <Button
            variant="contained"
            type={activeStepProps.controlsForm ? 'submit' : 'button'}
            form={activeStepProps.controlsForm}
            onClick={() => doNextStep()}
            {...activeStepProps.nextButtonProps}
            disabled={!canProceedFromStep || isSubmitting}>
            {activeStepProps.nextButtonLabel
              ? evaluateConfigValue(activeStepProps.nextButtonLabel, currentJourneyState)
              : 'Continue'}
          </Button>
        </DialogActions>
      </Dialog>
    </JourneyModalContext.Provider>
  );
}

interface JourneyStepLabelProps {
  label: string;
  step: string;
  subLabel?: string;
  isOptional?: boolean;
  isSkipped?: boolean;
}

/**
 * A label for a journey step in the progress display.
 *
 * This will show the step sub label if it's defined,
 * otherwise it will show "Optional" or "Skipped" if the step is optional or skipped.
 */
const JourneyStepLabel = ({
  step,
  isOptional,
  isSkipped,
  label,
  subLabel,
}: JourneyStepLabelProps): JSX.Element | null => {
  const classes = useLabelStyles();

  const subLabelText = useMemo(() => {
    // Always show the subLabel if it's defined
    if (subLabel) {
      return subLabel;
    }

    if (isOptional) {
      return isSkipped ? 'Skipped' : 'Optional';
    }

    return undefined;
  }, [isOptional, isSkipped, subLabel]);

  return (
    <StepLabel
      classes={{
        label: classes.label,
        labelContainer: classes.labelContainer,
        disabled: classes.disabledStep,
      }}
      optional={
        subLabelText ? (
          <Typography variant="caption" className={subLabel ? classes.subLabel : undefined}>
            {subLabelText}
          </Typography>
        ) : null
      }>
      <span id={`journey-step-${step}-label`}>{label}</span>
    </StepLabel>
  );
};

const useLabelStyles = makeStyles((theme) => ({
  label: {
    '&.MuiStepLabel-alternativeLabel': {
      marginTop: theme.spacing(1),
    },
  },
  labelContainer: {
    lineHeight: 1,
  },
  disabledStep: {
    opacity: 0.67,
    '& .MuiStepIcon-completed': {
      color: theme.palette.grey[400],
    },
  },
  subLabel: {
    color: theme.palette.primary.main,
  },
}));

type UseJourneyModalProps<
  TJourneyState extends Record<string, any>,
  TJourneyStep extends string,
> = Omit<JourneyModalProps<TJourneyState, TJourneyStep>, 'open' | 'onClose'> &
  Partial<Pick<JourneyModalProps<TJourneyState, TJourneyStep>, 'onClose'>>;

/**
 * A hook for starting a journey modal. This will show a modal with a multi-step journey.
 */
export function useJourneyModal<
  TJourneyState extends Record<string, any>,
  TJourneyStep extends string = string,
>(props: UseJourneyModalProps<TJourneyState, TJourneyStep>) {
  const { showModal } = useModal();

  return {
    startJourney: ({
      initialJourneyState,
    }: { initialJourneyState?: Partial<TJourneyState> } = {}) => {
      const modal = showModal(
        JourneyModal,
        {
          ...props,
          defaultJourneyState: { ...props.defaultJourneyState, ...initialJourneyState },
          onClose: () => {
            props.onClose?.();
            return modal.hide();
          },
        } as JourneyModalProps<any, any>,
        { destroyOnClose: true },
      ) as ShowFnOutput<JourneyModalProps<TJourneyState, TJourneyStep>>;

      return modal;
    },
  };
}

/**
 * Evaluate a config value, which can be either a value or a function
 * which accepts the current journey state and returns a value.
 *
 * This is useful for updating the state of a step or journey based on the current state.
 */
function evaluateConfigValue<
  TJourneyState extends Record<string, any>,
  TValue extends boolean | string | undefined,
>(configValue: JourneyConfigValue<TJourneyState, TValue>, state: TJourneyState) {
  return typeof configValue === 'function' ? configValue(state) : configValue;
}

export const JourneyHeading = styled(Typography, {
  name: 'JourneyHeading',
})(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.primary.dark,
  fontSize: theme.typography.h6.fontSize,
})) as typeof Typography;
