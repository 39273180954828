import React from 'react';

import { Typography } from '@mui/material';
import clsx from 'clsx';

import { isDefined } from '@/helpers/isDefined';
import { newsPaletteForScore } from '@/styles/NEWSColors';
import { Confidence, VitalsPopover, VitalValue } from '@/components/VitalsPopover';
import { makeStyles } from '@mui/styles';

interface CheckupValueProps {
  value: number | string | null | undefined;
  longValue?: string | null | undefined;
  shortLabel: string;
  longLabel?: string;
  score?: number | null;
  width?: number;
  smallValue?: boolean;
  popoverFields?: { label: string; value: string }[];
  alwaysOutline?: boolean;
  slimMargin?: boolean;
  confidence?: Confidence;
  isManualOverride?: boolean;
}

export function CheckupValue({
  value,
  longValue,
  shortLabel,
  longLabel,
  width = 65,
  smallValue,
  score,
  popoverFields,
  alwaysOutline,
  slimMargin,
  confidence,
  isManualOverride,
}: CheckupValueProps) {
  const classes = useStyles();
  const hasValue = isDefined(value);
  const vitalValue: VitalValue = hasValue
    ? { value: value, detailedValue: longValue ?? undefined }
    : { value: '-' };

  return (
    <VitalsPopover
      vitalName={longLabel ?? shortLabel}
      value={vitalValue}
      score={score}
      confidence={confidence}
      isManualOverride={isManualOverride}
      popoverFields={popoverFields}
      hasValue={hasValue}
      style={isDefined(score) && score > 0 ? newsPaletteForScore(score) : undefined}
      width={width}
      flexShrink={0}
      className={clsx(
        classes.root,
        slimMargin && classes.slim,
        alwaysOutline && classes.outlined,
        classes.focused,
      )}>
      <Typography className={classes.label}>{shortLabel}</Typography>
      <Typography
        className={clsx(
          classes.value,
          smallValue && classes.smallValue,
          isDefined(score) && score > 0 && classes.strongValue,
        )}
        data-testid={`${shortLabel}tile-value`}>
        {vitalValue.value}
      </Typography>
    </VitalsPopover>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: theme.spacing(0.75, 1),
    margin: theme.spacing(0.5, 0.75),
    borderRadius: theme.shape.borderRadius * 3,
    border: '1px solid transparent',
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(0.5, 1.5),
    },
  },
  slim: {
    margin: theme.spacing(0.5, 0.75),
  },
  outlined: {
    borderColor: theme.palette.grey[800],
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    lineHeight: 1,
  },
  smallValue: {
    marginTop: theme.spacing(0.25),
    whiteSpace: 'nowrap',
    fontSize: theme.typography.pxToRem(14),
  },
  value: {
    marginTop: theme.spacing(0.125),
    whiteSpace: 'nowrap',
    fontWeight: 'inherit',
  },
  strongValue: {
    fontWeight: 500,
  },
  valueDetailsPopover: {
    pointerEvents: 'none',
    marginTop: theme.spacing(1),
  },
  popoverTitle: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  popoverDetailLabel: {
    fontWeight: 500,
  },
  focused: {
    '&:focus-visible': {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  },
}));
