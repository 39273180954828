export enum Browser {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Safari = 'Safari',
  Edge = 'Edge',
  Other = 'Other',
}

export const getCurrentBrowser = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes('Chrome')) {
    return Browser.Chrome;
  } else if (userAgent.includes('Firefox')) {
    return Browser.Firefox;
  } else if (userAgent.includes('Safari')) {
    return Browser.Safari;
  } else if (userAgent.includes('Edge')) {
    return Browser.Edge;
  } else {
    return 'Other';
  }
};
