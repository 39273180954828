import React, { useState, useRef } from 'react';
import { Box, Typography, Popover, Alert, AlertTitle, AlertColor, BoxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isDefined } from '@/helpers/isDefined';

export enum ConfidenceType {
  LowUser = 'LOW_USER',
  LowClinical = 'LOW_CLINICAL',
  High = 'HIGH',
  Failed = 'FAILED',
}

export type Confidence = {
  value: ConfidenceType;
  heading: string;
  description: string;
};

interface PopoverField {
  label: string;
  value: string | number;
}

export type VitalValue = {
  value: string | number | null;
  detailedValue?: string;
};

interface VitalsPopoverOwnProps {
  vitalName: string;
  value?: VitalValue;
  score?: number | null;
  confidence?: Confidence | null;
  isManualOverride?: boolean;
  popoverFields?: PopoverField[];
  hasValue?: boolean;
  children: React.ReactNode;
}

type VitalsPopoverProps = VitalsPopoverOwnProps & Omit<BoxProps, keyof VitalsPopoverOwnProps>;

const getAlertSeverity = (confidenceValue: ConfidenceType): AlertColor => {
  switch (confidenceValue) {
    case ConfidenceType.LowUser:
    case ConfidenceType.LowClinical:
      return 'warning';
    case ConfidenceType.High:
      return 'success';
    case ConfidenceType.Failed:
      return 'error';
    default:
      throw new Error(`Invalid confidence value: ${confidenceValue}`);
  }
};

export const VitalsPopover: React.FC<VitalsPopoverProps> = ({
  vitalName,
  value,
  score,
  confidence,
  isManualOverride,
  popoverFields,
  hasValue,
  children,
  ...boxProps
}) => {
  const classes = useStyles();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const anchorElRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    setPopoverVisible(true);
  };

  const handleMouseLeave = () => {
    setPopoverVisible(false);
  };

  return (
    <>
      <Box
        ref={anchorElRef}
        tabIndex={value ? 0 : undefined}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={() => setPopoverVisible(true)}
        onBlur={() => setPopoverVisible(false)}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            setPopoverVisible(false);
          }
        }}
        aria-label={vitalName}
        aria-expanded={popoverVisible}
        {...boxProps}>
        {children}
      </Box>
      <Popover
        open={popoverVisible}
        aria-label={`${vitalName} Details`}
        onClose={() => setPopoverVisible(false)}
        disableRestoreFocus
        disableEnforceFocus
        disableAutoFocus
        disableScrollLock
        anchorEl={anchorElRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          pointerEvents: 'none',
          marginTop: 1,
          '& .MuiPopover-paper': {
            maxWidth: '500px',
          },
        }}>
        <Box margin={2}>
          <Typography className={classes.popoverTitle}>{vitalName}</Typography>

          {!hasValue && <Typography gutterBottom>No measurement taken</Typography>}

          {value?.detailedValue && <Typography gutterBottom>{value?.detailedValue}</Typography>}

          {isDefined(score) && (
            <Typography gutterBottom>
              <span className={classes.popoverDetailLabel}>EWS</span> {score}
            </Typography>
          )}

          {hasValue &&
            popoverFields?.map(({ label, value }) => (
              <Typography key={label} gutterBottom>
                <span className={classes.popoverDetailLabel}>{label}</span> {value}
              </Typography>
            ))}
        </Box>

        {confidence && (
          <Box margin={2}>
            <Alert severity={getAlertSeverity(confidence.value)}>
              <AlertTitle>{confidence.heading}</AlertTitle>
              <Typography>{confidence.description}</Typography>
            </Alert>
          </Box>
        )}

        {isManualOverride && (
          <Box margin={2}>
            <Alert
              severity="warning"
              sx={{
                backgroundColor: 'grey.100',
                border: '1px solid',
                borderColor: 'grey.500',
                '& .MuiAlert-icon': {
                  color: 'grey.500',
                },
                '& .MuiAlert-message': {
                  color: 'grey.800',
                },
              }}>
              <AlertTitle>Manual Override</AlertTitle>
              <Typography>
                Device readings were not used - this value was entered manually by the user.
              </Typography>
            </Alert>
          </Box>
        )}
      </Popover>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  popoverTitle: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  popoverDetailLabel: {
    fontWeight: 500,
  },
}));
