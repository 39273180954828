import React from 'react';

import { Stack, Typography, Box, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { isSameDay, format, isYesterday, isThisYear } from 'date-fns';

import {
  PatientChatMessageViewFragment,
  PatientChatParticipantViewFragment,
} from '@/generated/graphql';
import { formatUserName } from '@/helpers/formatUserName';
import makeStyles from '@mui/styles/makeStyles';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';

interface MessageViewProps {
  message: PatientChatMessageViewFragment;
  patientUserId: string;
  currentUserId: string;
  user: Maybe<PatientChatParticipantViewFragment>;
  previousMessage: PatientChatMessageViewFragment | undefined;
  nextMessage: PatientChatMessageViewFragment | undefined;
}

export function MessageView({
  message,
  currentUserId,
  patientUserId,
  previousMessage,
  user,
  nextMessage,
}: MessageViewProps) {
  const classes = useMessageViewStyles();
  const { copyToClipboard } = useCopyToClipboard();

  const isCurrentUser = message.createdById === currentUserId;
  const isPatient = message.createdById === patientUserId;

  const isCurrentUserThePatient = currentUserId === patientUserId;

  const isSameDayAsPreviousMessage = isSameDay(
    new Date(message.createdAt),
    new Date(previousMessage?.createdAt ?? 0),
  );
  const isSameDayAsNextMessage = isSameDay(
    new Date(message.createdAt),
    new Date(nextMessage?.createdAt ?? 0),
  );

  const isSameAuthorAsPreviousMessage =
    isSameDayAsPreviousMessage && message.createdById === previousMessage?.createdById;
  const isSameAuthorAsNextMessage =
    isSameDayAsNextMessage && message.createdById === nextMessage?.createdById;

  /**
   * Check if the message fits on one line (under a certain length and no newlines)
   * to determine the layout of the message. If the message fits on one line, the message
   * and the sent date will be displayed on the same line. If the message is longer than
   * the threshold, the sent date will be displayed on the line below the message.
   *
   * Note: This is a best guess, as the actual width of the message can vary based on the font and container width.
   */
  const messageFitsOnOneLine = message.message.length < 50 && !message.message.includes('\n');

  const handleMessageKeydown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    // Copy message to clipboard when pressing Ctrl + C (or Cmd + C on Mac) and no text is selected
    if ((e.ctrlKey || e.metaKey) && e.key === 'c' && window.getSelection()?.toString() === '') {
      e.preventDefault();

      // Create a summary of the message to copy to the clipboard
      const messageText = `${formatUserName(user)} on ${format(
        new Date(message.createdAt),
        'MMM do yyyy, HH:mm a',
      )}: ${message.message}`;

      copyToClipboard(messageText, 'Chat message');
    }
  };

  return (
    <Stack>
      {!isSameDayAsPreviousMessage && (
        <Typography className={classes.dateDivider}>
          {formatDividerDate(new Date(message.createdAt))}
        </Typography>
      )}
      <Box
        role="article"
        key={message.id}
        tabIndex={0}
        onKeyDown={handleMessageKeydown}
        className={clsx(
          classes.chatMessageContainer,
          !isCurrentUserThePatient &&
            !isCurrentUser &&
            !isPatient &&
            classes.otherStaffMemberMessage,
          isSameDayAsPreviousMessage &&
            isSameAuthorAsPreviousMessage &&
            classes.sameAuthorAsPreviousMessage,
          isSameDayAsNextMessage && isSameAuthorAsNextMessage && classes.sameAuthorAsNextMessage,
          getMessageAlignment(isCurrentUserThePatient, isPatient, isCurrentUser) === 'left'
            ? classes.leftAligned
            : classes.rightAligned,
        )}>
        <Box
          aria-label={`Message from ${formatUserName(user)}`}
          className={clsx(
            isSameAuthorAsPreviousMessage && classes.hiddenAuthorName,
            classes.authorName,
            isCurrentUser && classes.currentUserName,
            isPatient && classes.patientName,
            !isCurrentUserThePatient && !isPatient && !isCurrentUser && classes.staffName,
          )}>
          {formatUserName(user)}
        </Box>
        <Stack
          direction={messageFitsOnOneLine ? 'row' : 'column'}
          justifyContent="space-between"
          alignItems={messageFitsOnOneLine ? 'center' : 'flex-start'}
          gap={1}>
          <Typography whiteSpace="preserve-breaks">{message.message}</Typography>
          <Tooltip
            title={format(new Date(message.createdAt), 'MMM do yyyy, HH:mm a')}
            placement="bottom"
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 5],
                  },
                },
              ],
            }}>
            <Typography
              className={classes.sentDate}
              component="time"
              dateTime={message.createdAt}
              aria-label={`Sent at ${format(new Date(message.createdAt), 'MMM do yyyy, HH:mm a')}`}>
              {format(new Date(message.createdAt), 'HH:mm')}
            </Typography>
          </Tooltip>
        </Stack>
      </Box>
    </Stack>
  );
}

/**
 * Formats the date to be displayed as a divider in the chat.
 * - If the date is today, it will display "Today".
 * - If the date is yesterday, it will display "Yesterday".
 * - If the date is within the same year, it will display the full date without the year.
 * - If the date is in a different year, it will display the full date with the year.
 */
function formatDividerDate(date: Date) {
  if (isSameDay(date, new Date())) {
    return 'Today';
  }

  if (isYesterday(date)) {
    return 'Yesterday';
  }

  if (isThisYear(date)) {
    return format(date, 'EEEE, do MMMM');
  }

  return format(date, 'do MMMM yyyy');
}

/**
 * Returns alignment of the message based on who sent it.
 * - When the current user is the patient, their messages are right-aligned and all other messages are left-aligned.
 * - When the current user is not the patient, the patient's messages are left-aligned all staff messages are right-aligned.
 */
function getMessageAlignment(
  isCurrentUserThePatient: boolean,
  isPatient: boolean,
  isCurrentUser: boolean,
) {
  if (isCurrentUserThePatient) {
    return isCurrentUser ? 'right' : 'left';
  }

  return isPatient ? 'left' : 'right';
}

const useMessageViewStyles = makeStyles((theme) => ({
  dateDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[600],
    fontWeight: 500,
  },
  chatMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '10%',
    maxWidth: '50%',
    margin: theme.spacing(1),
    padding: theme.spacing(1.5, 2, 1.5, 2),
    borderRadius: theme.spacing(2),

    background: theme.palette.common.white,
  },
  leftAligned: {
    alignSelf: 'flex-start',
    borderBottomLeftRadius: '2px',
    boxShadow: 'rgba(50, 50, 93, 0.15) -3px 3px 5px -1px, rgba(0, 0, 0, 0.2) 0px 1px 3px -1px;',
    background: 'white',
  },
  rightAligned: {
    alignSelf: 'flex-end',
    borderBottomRightRadius: '2px',
    boxShadow: 'rgba(50, 50, 93, 0.15) 3px 3px 5px -1px, rgba(0, 0, 0, 0.2) 0px 1px 3px -1px;',
    background: 'linear-gradient(165deg, hsl(205, 100%, 90%) 30%, hsl(190, 100%, 92%) 90%)',
  },
  hiddenAuthorName: {
    visibility: 'hidden',
    height: 0,
  },
  sameAuthorAsPreviousMessage: {
    marginTop: theme.spacing(0),
  },
  sameAuthorAsNextMessage: {
    borderRadius: theme.spacing(2),
  },
  otherStaffMemberMessage: {
    background: 'linear-gradient(165deg, hsl(88, 95%, 90%) 30%, hsl(80, 90%, 92%) 90%)',
  },
  patientName: {
    color: theme.palette.primary.main,
  },
  sameAuthor: {
    marginTop: theme.spacing(0),
  },
  sentDate: {
    display: 'flex',
    alignSelf: 'flex-end',
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(12),
  },
  authorName: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
  },
  currentUserName: {
    color: theme.palette.primary.dark,
  },
  staffName: {
    color: 'hsl(88, 95%, 17%)',
  },
}));
