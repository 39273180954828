import React from 'react';
import { Realtime, ErrorInfo } from 'ably';
import { AblyMessageCallback, ChannelProvider, useChannel } from 'ably/react';
import Api from './controllers/Api';

export const AblyClient = new Realtime({
  authCallback: async (_, callback) => {
    let tokenRequest;

    try {
      tokenRequest = await Api.get('/ably/auth');
    } catch (err) {
      callback(err as unknown as ErrorInfo | string | null, null);
      return;
    }

    callback(null, tokenRequest);
  },
});

export const useAblyChannel = (channelName: string, callbackOnMessage: AblyMessageCallback) => {
  return useChannel(buildFullChannelName(channelName), callbackOnMessage);
};

export const AblyChannelProvider = (props: { channelName: string; children: React.ReactNode }) => {
  return (
    <ChannelProvider channelName={buildFullChannelName(props.channelName)}>
      {props.children}
    </ChannelProvider>
  );
};

// In order to uniquely identify locally running instances, we're making use of the host name which should hopefully differ from dev to dev.
// NOTE: This matches up with ablyUtils in the api. Please reflect changes made there.
function buildFullChannelName(channelName: string) {
  const parts = [
    process.env.REACT_APP_FEEBRIS_ENVIRONMENT,
    process.env.REACT_APP_FEEBRIS_ENVIRONMENT === 'local'
      ? process.env.REACT_APP_HOST_NAME
      : undefined,
    channelName,
  ].filter((x) => x !== undefined);

  return parts.join(':');
}
