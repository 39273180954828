import fetch from 'cross-fetch';
import auth from './Auth';

// FIXME: What is the point of this timeout, what is trying to be achieved?
const DEFAULT_TIMEOUT = 60000;

class Api {
  constructor() {
    this.url = process.env.REACT_APP_API;

    this.options = {
      timeout: DEFAULT_TIMEOUT,
      headers: {
        'Feebris-Agent': `Feebris/${process.env.REACT_APP_VERSION}(portal)`,
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
  }

  async getAuthHeaders() {
    const headers = {};

    const authHeader = await auth.getAuthorizationHeader();

    if (authHeader) {
      headers['Authorization'] = authHeader;
    }

    headers['Feebris-Acting-Organization-Id'] = auth.getActingOrganizationId();

    return headers;
  }

  setHeader(name, value) {
    this.options.headers[name] = value;
  }

  buildUrl(url, parameters) {
    let qs = '';

    for (const key in parameters) {
      // eslint-disable-next-line no-prototype-builtins
      if (parameters.hasOwnProperty(key)) {
        const value = parameters[key];
        qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
      }
    }

    if (qs.length > 0) {
      qs = qs.substring(0, qs.length - 1); // chop off last "&"
      url = url + '?' + qs;
    }

    return url;
  }

  async get(endpoint, params) {
    const url = this.buildUrl(`${this.url}${endpoint}`, params);

    const query = Object.assign({}, this.options, {
      method: 'GET',
      body: undefined,
    });

    query.headers = Object.assign(query.headers, await this.getAuthHeaders());

    const res = await this.request(url, query);

    if (res.status >= 400) {
      if (res.status === 401) {
        // Will perform a hard refresh bouncing the user to the /login or /shareTokenExpired page
        await auth.signoutUnauthorisedUser();
      } else {
        throw new ResponseError(res);
      }
    }

    return res;
  }

  async innerPost(method, endpoint, params) {
    const url = this.buildUrl(`${this.url}${endpoint}`);

    const query = Object.assign({}, this.options, {
      method: method,
      body: params ? JSON.stringify(params) : null,
    });

    query.headers = Object.assign(query.headers, await this.getAuthHeaders());

    const res = await this.request(url, query);

    if (res.status >= 400) {
      if (res.status === 401) {
        // Will perform a hard refresh bouncing the user to the /login or /shareTokenExpired page
        await auth.signoutUnauthorisedUser();
      } else {
        throw new ResponseError(res);
      }
    }

    return res;
  }

  post(endpoint, params) {
    return this.innerPost('POST', endpoint, params);
  }

  put(endpoint, params) {
    return this.innerPost('PUT', endpoint, params);
  }

  delete(endpoint, params) {
    return this.innerPost('DELETE', endpoint, params);
  }

  request(url, options) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const timeoutTimer = setTimeout(() => {
        reject(new Error('Request timed out'));
      }, options.timeout);

      try {
        const response = await fetch(url, options);
        const responseJson = await response.json();

        clearTimeout(timeoutTimer);

        responseJson.status = response.status;

        resolve(responseJson);
      } catch (err) {
        reject(err);
      }
    });
  }

  // NOTE: There is already a function named getOrganizations so we use getActingOrganizations
  async getActingOrganizations() {
    return await this.get('/organizations');
  }

  async didLogin() {
    return await this.post('/graphql', { query: `mutation { didLogin }` });
  }

  async didStayLoggedIn() {
    return await this.post('/graphql', { query: `mutation { didStayLoggedIn }` });
  }

  async getMe() {
    return await this.post('/graphql', {
      query: `{
        me {
          id
          firstName
          lastName
          email
          isFeebroid
          roles
          jobRole
          permissions
          createdAt
          actingOrganization {
            id
            name
            type
            acceptedTerms
            features
            roles {
              name
              description
              isSystemRole
            }
            address {
              address
              postcode
            }
            defaultCarePathway {
              name
              ewsThresholds {
                thresholds
              }
            }
          }
          organizations {
            id
            name
            type
          }
          intercomUserHash
        }
      }`,
    });
  }

  async getUninvitedPracticesCount() {
    return await this.post('/graphql-internal', { query: '{ uninvitedPracticesCount }' });
  }

  async getPatients() {
    return await this.post('/graphql', {
      query: `{
        patients(isTestPatient:false) {
          id
          createdAt
          firstName
          lastName
          gender
          birthDate
          telephone
          address {
            address
            postcode
          }
          wards {
            id
            name
            createdAt
          }
          practices: organizations(type: "practice") {
            id
            name
            # FIXME: There is unexpected magic here. We are trying very hard to always fetch practice
            #        objects with the same shape (id, name, address) so that the <Autocomplete>
            #        component can diff and match them correctly
            address {
              address
              postcode
            }
          }
          preExistingConditions
          nhsNumber
          selfCare {
            id
            email
          }
        },
      }`,
    });
  }

  async createOrganizationAndFirstUser(params) {
    return await this.post('/graphql-internal', {
      query: `mutation {
        createOrganizationAndFirstUser(
          organization: {
            type: ${JSON.stringify(params.organization.type)}
            name: ${JSON.stringify(params.organization.name)}
            telephone: ${JSON.stringify(params.organization.telephone)}
            address: {
              address: ${JSON.stringify(params.organization.address.address)}
              postcode: ${JSON.stringify(params.organization.address.postcode)}
            }
          },
          user: {
            email: ${JSON.stringify(params.user.email)}
          }
        ) {
          organization {
            id
          }
          user {
            id
          }
        }
      }`,
    });
  }

  async updateOrganization(organization) {
    return await this.post('/graphql-internal', {
      variables: { organization },
      query: `mutation updateOrganization($organization: OrganizationUpdateInput!){
        updateOrganization(
          organization: $organization
        ) { id }
      }`,
    });
  }

  async createPractice(params) {
    return await this.post('/graphql', {
      query: `mutation {
        createPractice(
          organization: {
            name: ${JSON.stringify(params.organization.name)}
            telephone: ${JSON.stringify(params.organization.telephone)}
            address: {
              address: ${JSON.stringify(params.organization.address.address)}
              postcode: ${JSON.stringify(params.organization.address.postcode)}
            }
          },
          user: {
            email: ${JSON.stringify(params.user.email)}
            firstName: ${JSON.stringify(params.user.firstName)}
            lastName: ${JSON.stringify(params.user.lastName)}
          }
        ) {
          id
          name
          # FIXME: There is unexpected magic here. We are trying very hard to always fetch practice
          #        objects with the same shape (id, name, address) so that the <Autocomplete>
          #        component can diff and match them correctly
          address {
            address
            postcode
          }
        }
      }`,
    });
  }

  async deletePatient(patientId) {
    return await this.post('/graphql', {
      query: `mutation {
        deletePatient(
          patientId: "${patientId}"
        )
      }`,
    });
  }

  async acceptTerms() {
    return await this.post('/graphql', {
      query: `mutation {
        acceptTerms
      }`,
    });
  }

  async getPatient(params) {
    return await this.post('/graphql', {
      query: `{
        patient(id: ${JSON.stringify(params.patientId)}) {
          id
          firstName
          lastName
          numSimilarNames
          createdAt
          birthDate
          gender
          address {
            address
            postcode
          }
          selfCare {
            email
          }
          telephone
          organizations(type: "care_home") {
            address {
              address
              postcode
            }
          }
          nhsNumberResponseDetails {
            nhsNumber
          }
          latestCheckup {
            endedAt
          }
          wardAdmission {
            admittedAt
            carePathway {
              name
            }
            ward {
              id
              name
            }
          }
        }
      }`,
    });
  }

  async getCheckup(params) {
    return await this.post('/getCheckup', params);
  }

  async getUserOrganizationsInternal({ organizationId, showDeleted } = {}) {
    return await this.post('/graphql-internal', {
      query: `query userOrganizations($organizationId:ID!, $showDeleted:Boolean!) {
        userOrganizations(
          organizationId: $organizationId
          showDeleted: $showDeleted
        ) {
          user {
            id
            firebaseUid
            email
            firstName
            lastName
            createdAt
            updatedAt
            deletedAt
            isFeebroid
          }
          roles
          lastLoggedInAt
        }
      }`,
      variables: { organizationId, showDeleted: Boolean(showDeleted) },
    });
  }

  async updateUserInternal(organizationId, user) {
    return await this.post('/graphql-internal', {
      query: `mutation {
        updateUser(
          organizationId: ${JSON.stringify(organizationId)}
          user: {
            id: ${JSON.stringify(user.id)}
            ${user.firstName ? `firstName: ${JSON.stringify(user.firstName)}` : ''}
            ${user.lastName ? `lastName: ${JSON.stringify(user.lastName)}` : ''}
          }
        ) { id }
      }`,
    });
  }

  async deleteUser(params) {
    return await this.post('/graphql', {
      query: `mutation {
        deleteUser(
          userId: ${JSON.stringify(params.userId)}
        )
      }`,
    });
  }

  async passwordResetUrl({ userId }) {
    return await this.post('/graphql', {
      query: `mutation {
        passwordResetUrl(
          userId: ${JSON.stringify(userId)}
          portalUrl: ${JSON.stringify(window.location.origin)}
        )
      }`,
    });
  }

  async passwordResetUrlInternal({ organizationId, userId }) {
    return await this.post('/graphql-internal', {
      query: `mutation {
        passwordResetUrl(
          organizationId: ${JSON.stringify(organizationId)}
          userId: ${JSON.stringify(userId)}
          portalUrl: ${JSON.stringify(window.location.origin)}
        )
      }`,
    });
  }

  async updateUserOrganizationInternal({ userId, organizationId, roles }) {
    return await this.post('/graphql-internal', {
      query: `mutation {
        updateUserOrganization(
          userId: ${JSON.stringify(userId)}
          organizationId: ${JSON.stringify(organizationId)}
          roles: ${JSON.stringify(roles)}
        ) { user { id } }
      }`,
    });
  }

  async createFirebaseUser({ userId }) {
    return await this.post('/graphql-internal', {
      query: `mutation {
        createFirebaseUser(
          userId: ${JSON.stringify(userId)}
        ) {
          id
          email
        }
      }`,
    });
  }

  async assignPatientToPractice({ patientId, practiceId }) {
    return await this.post('/graphql', {
      query: `mutation {
        assignPatientToPractice(
          patientId: "${patientId}"
          practiceId: "${practiceId}"
        ) {
          # FIXME: This isn't true, we just need to remove the '!' on the mutation return in schema
          # We don't actually need the return value, but graphql mutations must have a return
          organization { id }
        }
      }`,
    });
  }

  async unassignPatientFromPractice({ patientId, practiceId }) {
    return await this.post('/graphql', {
      query: `mutation {
        unassignPatientFromPractice(
          patientId: "${patientId}"
          practiceId: "${practiceId}"
        )
      }`,
    });
  }

  async createEWSThresholds({ patientId, thresholds }) {
    return await this.post('/graphql', {
      variables: { thresholds },
      query: `mutation createEWSThresholds($thresholds: ThresholdsInput) {
        createEWSThresholds(
          patientId: "${patientId}"
          thresholds: $thresholds
        ) { id }
      }`,
    });
  }

  async sendWelcomeEmailInternal({ organizationId, userId }) {
    return await this.post('/graphql-internal', {
      query: `mutation {
        sendWelcomeEmail(
          organizationId: ${JSON.stringify(organizationId)}
          userId: ${JSON.stringify(userId)}
          portalUrl: ${JSON.stringify(window.location.origin)}
        )
      }`,
    });
  }

  async loginAs({ userId }) {
    return await this.post('/graphql-internal', {
      query: `mutation {
        loginAs(userId: ${JSON.stringify(userId)}) {
          loginAsToken
          exitLoginAsToken
        }
      }`,
    });
  }

  async batchSetAlertStatus(batchParams) {
    const batchOfQueries = batchParams.map(({ id, status }) => ({
      query: `mutation {
        setAlertStatus(id: ${JSON.stringify(id)}, status: ${JSON.stringify(status)})
      }`,
    }));
    return await this.post('/graphql', batchOfQueries);
  }
}

class ResponseError extends Error {
  constructor(res) {
    let message = 'Unknown error';

    if (res.errors && res.errors.length > 0) {
      message = res.errors[0].message;
    } else if (res.body && res.body.message) {
      message = res.body.message;
    } else if (res.message) {
      message = res.message;
    }

    super(message);

    this.status = res.status;
    this.response = res;
  }
}

const api = new Api();
export default api;
