import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  MenuItem,
  Link,
  ListItemIcon,
  List,
  ListItem,
  TextField,
  useMediaQuery,
  Theme,
  AppBar,
  Badge,
  Box,
  Button,
  IconButton,
  ListItemText,
  Popover,
  Toolbar,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { canAccessManagePatientsPage } from '@/pages/ManagePatients';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import * as Cookies from 'js-cookie'; // FIXME: Move this Cookie handling into helpers/i18n
import { useTranslation } from 'react-i18next';

import { usePatientAdmitJourney } from '@/components/Journeys/AdmitPatientJourney';
import auth from '@/controllers/Auth';
import api from '@/controllers/Api';
import { useAuthMe, useMeActingOrganizationFeatures, useMePermissions } from '@/hooks/useAuth';
import SelectActingOrganization from './SelectActingOrganization';

import logo from '@/images/logo.png';
import { canAccessManageWardRoutes } from '@/pages/ManageWards';
import { canAccessIntegrationEventsPage } from '@/pages/Integrations/Integrations';
import { canAccessManageUsersPage } from '@/pages/Settings/Users';
import { useVersionInformationModal } from './VersionInformation';

const useStyles = makeStyles((theme) => ({
  logoImg: {
    width: 25, // TODO: Figure out how to make this dynamically calculated based on default IconButton sizes
    margin: '0 8.5px',
  },
  navLink: {
    margin: theme.spacing(2),
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.8,
    },
    display: 'inline-flex',
  },
  admitButton: {
    verticalAlign: 'baseline',
    fontSize: theme.typography.body1.fontSize,
  },
  spaceExpander: {
    flexGrow: 1,
  },
  careHomeName: {
    margin: theme.spacing(1),
    textAlign: 'center',
  },
  userInfoEmail: {
    padding: theme.spacing(2, 2, 0.5, 2),
    textAlign: 'center',
  },
  userRole: {
    padding: theme.spacing(0, 2, 1, 2),
    textAlign: 'center',
  },
  languageSelector: {
    padding: theme.spacing(0.5, 2, 0.5, 2),
  },
  userInfoSecurity: {
    padding: theme.spacing(2),
    width: '100%',
  },
  menuBar: {
    backgroundImage: `linear-gradient(
      145deg,
      hsl(194deg 100% 38%) 0%,
      hsl(194deg 100% 38%) 13%,
      hsl(194deg 100% 39%) 25%,
      hsl(194deg 100% 39%) 37%,
      hsl(194deg 100% 39%) 47%,
      hsl(194deg 100% 39%) 58%,
      hsl(194deg 100% 39%) 67%,
      hsl(194deg 100% 38%) 76%,
      hsl(194deg 100% 37%) 85%,
      hsl(194deg 100% 36%) 93%,
      hsl(194deg 100% 35%) 100%
    );`,
  },
  menuBarLoginAs: {
    backgroundImage: `linear-gradient(
      145deg,
      hsl(37deg 100% 45%) 0%,
      hsl(36deg 100% 46%) 14%,
      hsl(34deg 100% 47%) 26%,
      hsl(33deg 100% 48%) 38%,
      hsl(32deg 100% 49%) 49%,
      hsl(30deg 100% 50%) 59%,
      hsl(30deg 100% 49%) 69%,
      hsl(29deg 100% 48%) 78%,
      hsl(28deg 100% 47%) 86%,
      hsl(28deg 100% 46%) 94%,
      hsl(27deg 100% 46%) 100%
    );`,
  },
}));

function Header() {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const userInfoButtonRef = useRef<HTMLButtonElement | null>(null);
  const [uninvitedPracticesCount, setUninvitedPracticesCount] = useState(null);
  const { showVersionInformationModal } = useVersionInformationModal();

  // Is the screen size tablet/desktop size or above?
  const isTabletOrAbove = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  const { startJourney: startAdmitJourney } = usePatientAdmitJourney({
    showPatientRecordLink: true,
  });
  const features = useMeActingOrganizationFeatures();
  const permissions = useMePermissions();

  const accessTesterOptions = { permissions, featureFlags: features };

  useEffect(() => {
    (async function () {
      if (auth.me('isFeebroid')) {
        const response = await api.getUninvitedPracticesCount();
        setUninvitedPracticesCount(response.data?.uninvitedPracticesCount);
      }
    })();
  }, []);

  const roles = useAuthMe<string[]>('roles', []);
  const isSelfCare = roles.includes('selfCare');

  const showAppBar =
    !auth.isShareTokenUser() || (auth.isShareTokenUser() && !auth.shouldHideAppBar());

  return showAppBar ? (
    <AppBar
      position={isTabletOrAbove ? 'sticky' : 'relative'}
      className={auth.isLoginAs() ? classes.menuBarLoginAs : classes.menuBar}
      data-login-as={auth.isLoginAs()}>
      <Toolbar>
        <IconButton color="inherit" to="/" component={RouterLink} size="large">
          <img src={logo} className={classes.logoImg} alt="Logo" />
        </IconButton>
        <Box component="span" displayPrint="none">
          {permissions.admit_ward_patients && permissions.create_patients && (
            <Box marginX={2} display="inline-block">
              <Button
                size="small"
                variant="contained"
                onClick={() => startAdmitJourney()}
                className={classes.admitButton}>
                Admit a Patient
              </Button>
            </Box>
          )}
          {!isSelfCare ? (
            <Link
              to="/virtual-ward"
              variant="body1"
              className={classes.navLink}
              component={RouterLink}>
              Virtual Ward
            </Link>
          ) : (
            <Typography variant="h6">Feebris</Typography>
          )}
          {canAccessManagePatientsPage(accessTesterOptions) && (
            <Link to="/patients" variant="body1" className={classes.navLink} component={RouterLink}>
              Manage Patients
            </Link>
          )}
          {canAccessManageWardRoutes(accessTesterOptions) && (
            <>
              <Link to="/wards" variant="body1" className={classes.navLink} component={RouterLink}>
                Manage Wards
              </Link>
              <Link
                to="/pathways"
                variant="body1"
                className={classes.navLink}
                component={RouterLink}>
                Care Pathways
              </Link>
            </>
          )}
          {canAccessIntegrationEventsPage(accessTesterOptions) && (
            <Link
              to="/integrations"
              variant="body1"
              className={classes.navLink}
              component={RouterLink}>
              Integration Queue
            </Link>
          )}
          {auth.me('isFeebroid') && (
            <StyledBadge
              badgeContent={uninvitedPracticesCount}
              color={auth.isLoginAs() ? 'primary' : 'secondary'}
              className={classes.navLink}
              overlap="rectangular">
              <Link
                to="/admin"
                variant="body1"
                className={classes.navLink}
                style={{ margin: 0 }}
                component={RouterLink}>
                Feebroid Admin
              </Link>
            </StyledBadge>
          )}
        </Box>

        <div className={classes.spaceExpander} />

        {<SelectActingOrganization />}

        {canAccessManageUsersPage(accessTesterOptions) && (
          <IconButton
            component={RouterLink}
            color="inherit"
            to="/settings"
            aria-label="Organisation settings"
            size="large">
            <SettingsIcon />
          </IconButton>
        )}
        <IconButton
          ref={userInfoButtonRef}
          color="inherit"
          aria-controls="user-menu"
          aria-haspopup="menu"
          aria-label="Open user menu"
          onClick={() => setOpen(true)}
          size="large">
          <AccountCircleIcon />
        </IconButton>
        <Popover
          anchorEl={userInfoButtonRef.current}
          open={open}
          onClose={() => setOpen(false)}
          id="user-menu">
          <Grid container direction="column" minWidth={200}>
            <Grid item xs={12} className={classes.userInfoEmail}>
              <Typography>{auth.me('email')}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.userRole}>
              <Typography variant="body2">{auth.me('roles', []).join(', ')}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.languageSelector}>
              <TextField
                fullWidth
                select
                label="Language"
                variant="standard"
                name="language"
                aria-label="language"
                value={i18n.language}
                onChange={(e) => {
                  Cookies.set('language', e.target.value);
                  i18n.changeLanguage(e.target.value);
                }}>
                <MenuItem value="en-GB">English (UK)</MenuItem>
                <MenuItem value="en-US">English (US)</MenuItem>
              </TextField>
            </Grid>
            <List>
              {auth.isLoginAs() === false && (
                <ListItem
                  button
                  role="menuitem"
                  component={RouterLink}
                  to="/account"
                  onClick={() => setOpen(false)}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText>Account</ListItemText>
                </ListItem>
              )}
              <ListItem
                button
                role="menuitem"
                onClick={() => {
                  showVersionInformationModal();
                  setOpen(false);
                }}>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText>Version Info</ListItemText>
              </ListItem>
              {!auth.isShareTokenUser() ? (
                <ListItem
                  button
                  role="menuitem"
                  onClick={() => {
                    auth.isLoginAs() ? auth.exitLoginAs() : auth.signout();
                    setOpen(false);
                  }}>
                  <ListItemIcon>
                    <MeetingRoomIcon />
                  </ListItemIcon>
                  <ListItemText>{auth.isLoginAs() ? 'Exit login-as' : 'Log out'}</ListItemText>
                </ListItem>
              ) : null}
            </List>
          </Grid>
        </Popover>
      </Toolbar>
    </AppBar>
  ) : null;
}

const StyledBadge = withStyles((theme) => ({
  badge: {
    color: theme.palette.primary.contrastText,
  },
}))(Badge);

export default Header;
