import React from 'react';

import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'sonner';
import { IconButton, Paper, Stack, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import { useSendPatientChatMessageMutation } from '@/generated/graphql';

export const SEND_PATIENT_CHAT_MESSAGE = gql`
  mutation SendPatientChatMessage($PatientId: ID!, $message: String!) {
    sendPatientChatMessage(PatientId: $PatientId, message: $message) {
      id
      message
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;

interface NewMessageProps {
  patientId: string;
  onMessageSent: () => void;
}

const schema = Yup.object().shape({
  message: Yup.string().required('Required').trim().min(2, 'Message must be at least 2 characters'),
});

export function NewMessage({ patientId, onMessageSent }: NewMessageProps) {
  const [sendMessageMutation, { loading: isSubmitting }] = useSendPatientChatMessageMutation({
    onCompleted: () => {
      formik.resetForm();
      formik.validateForm();
      onMessageSent();
    },
    onError: () => {
      toast.error('Failed to send message');
    },
  });

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: schema,
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      // TODO: optimistically update the UI with the new message
      sendMessageMutation({
        variables: {
          PatientId: patientId,
          message: values.message,
        },
      });
    },
  });

  return (
    <Stack direction="row" alignItems="center" gap={2} flex="0 0 auto">
      <Paper
        elevation={0}
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{ padding: 2, width: '100%' }}>
        <TextField
          fullWidth
          multiline
          name="message"
          placeholder="Type a message"
          variant="standard"
          required
          value={formik.values.message}
          onChange={formik.handleChange}
          disabled={isSubmitting}
          inputProps={{
            'aria-label': 'Type your message here',
          }}
          InputProps={{
            disableUnderline: true,
            'aria-label': 'Type your message here',
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
            }
          }}
          onKeyUp={(e) => {
            if (e.key === 'Escape') {
              formik.resetForm();
              (e.target as HTMLInputElement).blur();
            }
            if (e.key === 'Enter' && !e.shiftKey) {
              formik.handleSubmit();
              e.preventDefault();
            }
          }}
        />
      </Paper>
      <IconButton
        color="default"
        type="submit"
        size="large"
        aria-label="Send message"
        onClick={() => formik.handleSubmit()}
        disabled={isSubmitting || !formik.dirty || !formik.isValid}
        sx={{
          backgroundColor: 'primary.main',
          color: 'white',
          height: '3rem',
          width: '3rem',
          '&:disabled': {
            backgroundColor: 'grey.300',
          },
          '&:hover': {
            backgroundColor: 'primary.main',
          },
        }}>
        <SendIcon color="inherit" fontSize="medium" />
      </IconButton>
    </Stack>
  );
}
