import _ from 'lodash';
import i18next from '@/helpers/i18n';
import * as FeebrisNewsCalculator from 'feebris-news-calculator';

const calculateEWSScores = (thresholds, checkup, modifiers) => {
  if (!thresholds) {
    return null;
  }

  // "patientRRRate" and "patientTemperature" are the namings of the old stand-alone Lambda endpoints: getCheckupSummary, getAllPatients that use the serializers
  // "respiratoryRate" and "temperature" are the namings used in the new GraphQL endpoint
  const measurements = {
    respiratoryRate:
      _.get(checkup, 'patientRRRate', null) || _.get(checkup, 'respiratoryRate.value', null),
    spO2: _.get(checkup, 'pulseOxiData.averageSpO2', null),
    bloodPressure: _.get(checkup, 'bloodPressureData.systolic', null),
    pulse: _.get(checkup, 'pulseRate.value', null),
    temperature: _.get(checkup, 'patientTemperature', null) || _.get(checkup, 'temperature', null),
    consciousness: _.get(checkup, 'consciousness', null),
  };

  return FeebrisNewsCalculator.NEWSCalculator(thresholds, measurements, modifiers);
};

export { calculateEWSScores };
