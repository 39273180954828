import React, { useEffect } from 'react';

import * as Sentry from '@sentry/browser';
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  ApolloError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import _ from 'lodash';

import generatedIntrospection from '@/generated/graphql-introspection.json';

import auth from '@/controllers/Auth';
import { useAuthMe } from '@/hooks/useAuth';
import posthog from './helpers/posthog';

const performanceLink = new ApolloLink((operation, forward) => {
  const startTime = Date.now();

  return forward(operation).map((result) => {
    const endTime = Date.now();
    const duration = endTime - startTime;

    const queryPerformanceData = {
      queryName: operation.operationName,
      variables: operation.variables,
      duration,
    };

    // We don't want to break the app if logging fails
    try {
      // Log to PostHog
      posthog.capture('graphql_query_duration', queryPerformanceData);

      // Add a breadcrumb to Sentry
      Sentry.addBreadcrumb({
        category: 'graphql_query_duration',
        message: queryPerformanceData.queryName,
        data: queryPerformanceData,
        level: 'info',
      });
    } catch (e) {
      console.error('Error logging query performance data', e);
    }

    return result;
  });
});

const publicApiHttpLink = ApolloLink.split(
  // Use a batch link for operations with batch true in the context
  (operation) => operation.getContext().batch === true,
  new BatchHttpLink({
    uri: `${process.env.REACT_APP_API}/graphql`,
    batchMax: 10,
    batchInterval: 20,
  }),
  createHttpLink({
    uri: `${process.env.REACT_APP_API}/graphql`,
  }),
);

const internalApiHttpLink = ApolloLink.split(
  // Use a batch link for operations with batch true in the context
  (operation) => operation.getContext().batch === true,
  new BatchHttpLink({
    uri: `${process.env.REACT_APP_API}/graphql-internal`,
    batchMax: 10,
    batchInterval: 20,
    // Since this is internal, we can debounce and suffer a slightly slower page for better server load
    batchDebounce: true,
  }),
  createHttpLink({
    uri: `${process.env.REACT_APP_API}/graphql-internal`,
  }),
);

const httpLink = ApolloLink.split(
  // Use the internal api for operations with clientName internal
  (operation) => operation.getContext().clientName === 'internal',
  internalApiHttpLink,
  publicApiHttpLink,
);

const authLink = setContext(async (_, prevContext) => {
  const authorization = await auth.getAuthorizationHeader();

  return {
    ...prevContext,
    headers: {
      ...prevContext.headers,
      authorization,
      'Feebris-Agent': `Feebris/${process.env.REACT_APP_VERSION}(portal)`,
      'Feebris-Acting-Organization-Id': auth.getActingOrganizationId(),
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

      if (message === 'Unauthorized') {
        auth.signoutUnauthorisedUser();
      }
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }

  if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
    auth.signoutUnauthorisedUser();
  }

  return forward(operation);
});

/**
 * This link removes the __typename from the variables of the query / mutation.
 *
 * We need this in situations where we have fetched a model from the server
 * and then want to update it with a mutation. The mutation will fail if the
 * __typename is included in the variables.
 */
const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitTypenameDeep(operation.variables);
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
  }),
  link: ApolloLink.from([performanceLink, cleanTypeName, errorLink, authLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default function AuthorizedApolloClientProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // The actingOrganization id changes under the following conditions:
  //   (1) login, once the me data loads, the actingOrganization.id will change from undefined
  //       to some value
  //   (2) org switcher or login-as being used, once the new me data loads the actingOrganization.id
  //       will change to a new value
  const actingOrgId = useAuthMe('actingOrganization.id');

  // If the actingOrgId changes, we need to reset the apollo cache
  useEffect(() => {
    // However, if we are accessing the site via ShareToken then there will never be an
    // acting organization (that's set within the ShareToken and controlled server-side). So
    // do a general store reset and trust the ShareToken to do its thing.
    if (auth.isShareTokenUser()) {
      apolloClient.resetStore();
      return;
    }

    // If the actingOrgId is undefined, we need to clear the cache without triggering a refetch on all active queries
    // This is because the user has most likely logged out
    if (actingOrgId === undefined) {
      apolloClient.clearStore();
      // If the actingOrgId is defined, most likely the user has logged in or switched orgs
    } else {
      apolloClient.resetStore();
    }
  }, [actingOrgId]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

export const getMutationErrors = (error: ApolloError | undefined) => {
  if (!error) {
    return { argErrors: undefined, message: undefined };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const invalidArguments = error.graphQLErrors?.[0]?.extensions?.invalidArgs as any | undefined;

  // When the server returns an invalid schema error for the thresholds input,
  // the errors are squirreled away in the networkError. So lets try dig them out.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const networkErrors = (error as any)?.networkError?.result?.errors as
    | { message: string }[]
    | undefined;

  return { argErrors: invalidArguments, message: error.message, networkErrors };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type WithoutTypeNames<T> = T extends Record<string, any>
  ? {
      [K in keyof T as Exclude<K, '__typename'>]: WithoutTypeNames<T[K]>;
    }
  : T;

/**
 * Recursively removes __typename fields from objects.
 *
 * We need this in situations where we have fetched a model from the server
 * and then want to update it with a mutation. The mutation will fail if the
 * __typename is included in the variables.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function omitTypenameDeep<T extends Record<string, any>>(obj: T): WithoutTypeNames<T> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename, ...rest } = obj;

  return Object.entries(rest).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: !_.isObject(value) || _.isArray(value) ? value : omitTypenameDeep(value),
    };
  }, {}) as WithoutTypeNames<T>;
}

// Extend apollo's default context with a batch flag
declare module '@apollo/client' {
  export interface DefaultContext extends Record<string, unknown> {
    /**
     * Batches the operation with other operations that have the same batch flag.
     *
     * Operations within 20ms of each other will be batched together, up to a maximum of 10 operations.
     */
    batch?: boolean;
  }
}
