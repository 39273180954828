import React, { useEffect } from 'react';

import * as Yup from 'yup';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { toast } from 'sonner';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { muiFormikGetFieldProps } from '@/helpers/formik';
import { useCreatePatientMutation } from '@/generated/graphql';
import { JourneyHeading } from '@/components/JourneyModal';
import { getMutationErrors } from '@/AuthorizedApolloClientProvider';

import { isDefined } from '@/helpers/isDefined';
import { stripSpaces } from '@/helpers/stripSpaces';
import { useAdmitPatientJourneyContext } from '../types';
import {
  formatNationalIdentifier,
  nationalIdentifierTypeToLabel,
} from '@/components/NationalIdentifierDisplay';
import { useMeActingOrganizationFeature } from '@/hooks/useAuth';

export const CREATE_PATIENT = gql`
  mutation CreatePatient($patient: CreatePatientInput!) {
    patient: createPatient(patient: $patient) {
      ...PatientDetails
    }
  }
`;

const patientCreationFormSchema = Yup.object().shape({
  nationalIdentifierValue: Yup.string().optional(),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  birthDate: Yup.date().required('Required'),
  gender: Yup.string().required('Required'),
  address: Yup.object()
    .shape({
      address: Yup.string(),
      postcode: Yup.string(),
    })
    .optional(),
  telephone: Yup.string().optional(),
});

type PatientCreationFormValues = Yup.InferType<typeof patientCreationFormSchema>;

export function ConfirmOrCreatePatientStep() {
  const { t } = useTranslation();

  const nationalIdentifierScheme = useMeActingOrganizationFeature('nationalIdentifierScheme');

  const {
    setIsSubmitting,
    currentJourneyState: { patient, patientSearchForm },
    updateJourneyState,
    gotoNextStep,
    close,
  } = useAdmitPatientJourneyContext();

  const patientAlreadyCreated = isDefined(patient?.id);
  const patientAlreadyAdmitted = isDefined(patient?.wardAdmission);

  const [createPatient, { error, loading }] = useCreatePatientMutation({
    onCompleted: (data) => {
      updateJourneyState({
        patient: data.patient,
        patientCreated: true,
      });

      gotoNextStep();
    },
    onError: () => {
      toast.error('Failed to create patient');
    },
  });

  const formik = useFormik<PatientCreationFormValues>({
    validationSchema: patientCreationFormSchema,
    initialValues: patientAlreadyCreated
      ? {
          nationalIdentifierValue: formatNationalIdentifier(patient?.nationalIdentifier) ?? '',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          birthDate: patient?.birthDate || ('' as any),
          firstName: patient?.firstName || '',
          lastName: patient?.lastName || '',
          gender: patient?.gender || '',
          address: {
            address: patient?.address?.address || '',
            postcode: patient?.address?.postcode || '',
          },
          telephone: patient?.telephone || '',
        }
      : {
          nationalIdentifierValue: patientSearchForm?.nationalIdentifierValue ?? '',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          birthDate: patientSearchForm?.birthDate ?? ('' as any),
          firstName: patientSearchForm?.firstName || '',
          lastName: patientSearchForm?.lastName || '',
          gender: '',
          address: {
            address: '',
            postcode: '',
          },
          telephone: '',
        },
    onSubmit: async (values) => {
      if (patientAlreadyAdmitted) {
        return close();
      }

      // We need to send either both address fields or neither
      const { postcode, address } = values.address ?? {};
      const addressInput = postcode?.trim() && address?.trim() ? { postcode, address } : null;

      // Even if the patient is already created, we need to update their details and ensure that
      // they are linked to the acting organization (this is a side-effect of the createPatient mutation)
      await createPatient({
        variables: {
          patient: {
            ...values,
            nationalIdentifierValue: values.nationalIdentifierValue
              ? stripSpaces(values.nationalIdentifierValue)
              : null,
            birthDate: new Date(values.birthDate).toISOString(),
            type: 'elderly',
            address: addressInput,
          },
        },
      });
    },
  });

  useEffect(() => {
    setIsSubmitting(loading);
  }, [loading, setIsSubmitting]);

  const { argErrors } = getMutationErrors(error);
  const getFieldProps = muiFormikGetFieldProps(
    formik,
    argErrors?.patient,
    patientCreationFormSchema,
  );

  return (
    <div>
      {!patientAlreadyCreated && (
        <Alert severity="info" sx={{ marginBottom: 2 }}>
          <AlertTitle>Patient not found</AlertTitle>
          To add the patient, please enter their details below, or click{' '}
          <strong>&quot;Back to search&quot;</strong> to search again.
        </Alert>
      )}
      {patientAlreadyAdmitted && (
        <Box marginBottom={3}>
          <JourneyHeading component="h3" marginBottom={2}>
            Active Ward Admission
          </JourneyHeading>
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Ward"
                variant="outlined"
                fullWidth
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                value={patient?.wardAdmission?.ward?.name ?? ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Care Pathway"
                variant="outlined"
                fullWidth
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                value={patient?.wardAdmission?.carePathway?.name ?? ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Alert
                severity="warning"
                action={
                  <Button
                    component={RouterLink}
                    size="small"
                    color="inherit"
                    endIcon={<ArrowForwardIcon />}
                    onClick={() => close()}
                    to={`/patient/${patient?.id}`}>
                    Open patient record
                  </Button>
                }>
                <AlertTitle>
                  Patient already admitted (since{' '}
                  {t('DATE_SHORT', patient?.wardAdmission?.admittedAt)})
                </AlertTitle>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}>
                  <Typography variant="body2">
                    To transfer them to a different ward, please discharge them first via the
                    patient record.
                  </Typography>
                </Stack>
              </Alert>
            </Grid>
          </Grid>
        </Box>
      )}
      <form onSubmit={formik.handleSubmit} id="createPatientForm" noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} marginBottom={1}>
            {patientAlreadyCreated ? (
              <>
                <JourneyHeading component="h3">Matched patient record</JourneyHeading>
                <Typography variant="body2" color="textSecondary">
                  Confirm that this is the correct patient before clicking &quot;Continue&quot;. If
                  not, click &quot;Back to search&quot; to start over.
                </Typography>
              </>
            ) : (
              <>
                <JourneyHeading component="h3" gutterBottom>
                  New patient form
                </JourneyHeading>
                <Typography variant="body2" color="textSecondary">
                  Provide details for the new patient record.
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={nationalIdentifierTypeToLabel(nationalIdentifierScheme)}
              InputProps={{
                readOnly: patientAlreadyCreated,
              }}
              placeholder={patientAlreadyCreated ? 'Not provided' : ''}
              InputLabelProps={{ shrink: true }}
              {...getFieldProps('nationalIdentifierValue')}
              value={formik.values.nationalIdentifierValue}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              InputProps={{
                readOnly: patientAlreadyCreated,
              }}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              {...getFieldProps('firstName')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              InputProps={{ readOnly: patientAlreadyCreated }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              {...getFieldProps('lastName')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Date of Birth"
              variant="outlined"
              type="date"
              InputProps={{ readOnly: patientAlreadyCreated }}
              InputLabelProps={{ shrink: true }}
              {...getFieldProps('birthDate')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select={!patientAlreadyCreated}
              fullWidth
              label="Sex"
              InputProps={{ readOnly: patientAlreadyCreated }}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              {...getFieldProps('gender')}>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Box marginTop={2}>
              <JourneyHeading component="h4" marginBottom={1}>
                Contact Details
              </JourneyHeading>
              {!patientAlreadyCreated && (
                <Typography variant="body2" color="textSecondary">
                  Provide contact details for the patient. This is optional but recommended.
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Address"
              variant="outlined"
              placeholder={patientAlreadyCreated ? 'Not provided' : 'e.g. 123a Fake Street, London'}
              InputProps={{
                readOnly: patientAlreadyCreated,
              }}
              InputLabelProps={{ shrink: true }}
              // fix typings for dot access...
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              {...getFieldProps('address.address' as any)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Postcode"
              variant="outlined"
              placeholder={patientAlreadyCreated ? 'Not provided' : 'e.g. SW1A 1AA'}
              InputProps={{ readOnly: patientAlreadyCreated }}
              InputLabelProps={{ shrink: true }}
              // fix typings for dot access...
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              {...getFieldProps('address.postcode' as any)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Telephone"
              variant="outlined"
              placeholder={patientAlreadyCreated ? 'Not provided' : 'e.g. 07123456789'}
              InputProps={{ readOnly: patientAlreadyCreated }}
              InputLabelProps={{ shrink: true }}
              {...getFieldProps('telephone')}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
