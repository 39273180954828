import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Box, BoxProps } from '@mui/material';

interface PageContainerProps extends BoxProps {
  children: React.ReactNode;
}

/**
 * A container for page content. Adds padding and centers the content.
 */
export function PageContainer({ children, ...rest }: PageContainerProps) {
  const classes = useStyles();
  return (
    <Box
      className={clsx(classes.pageContainer, rest.className)}
      maxWidth={1440}
      padding={3}
      {...rest}>
      {children}
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  pageContainer: {
    margin: '0 auto',
    width: '100%',
  },
}));
